import React, { useContext, useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';

import { Popup } from '../../../lib/reusable/Popup';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

let id = 0;

function NotificationsPopup({
  config = { tension: 125, friction: 20, precision: 0.1 },
  timeout = 3000,
  children,
}) {
  const {
    data: {
      place: { features },
    },
  } = useContext(PlaceContext);

  const hasSponsor = Boolean(features?.sponsor?.active);
  const hasTheming = Boolean(features?.theming?.active);

  const classes = {
    success: 'bg-success',
    info: 'bg-info',
    warning: 'bg-warning',
    danger: 'bg-danger',
  };

  const [refMap] = useState(() => new WeakMap());
  const [cancelMap] = useState(() => new WeakMap());
  const [items, setItems] = useState([]);
  const transitions = useTransition(items, (item) => item.key, {
    from: { opacity: 0, height: 0, life: '100%' },
    enter: (item) => async (next) =>
      await next({ opacity: 1, height: refMap.get(item).offsetHeight }),
    leave: (item) => async (next, cancel) => {
      cancelMap.set(item, cancel);
      next({ life: '0%' });
      await next({ opacity: 0 });
      await next({ height: 0 });
    },
    onRest: (item) => setItems((state) => state.filter((i) => i.key !== item.key)),
    config: (item, state) => (state === 'leave' ? [{ duration: timeout }, config, config] : config),
  });

  useEffect(
    () =>
      children((data) => {
        if (data && data.message && data.message.length > 0) {
          setItems((state) => [
            ...state,
            {
              key: id++,
              msg: data.message,
              type: data.type || 'info',
              orderId: data.orderId,
            },
          ]);
        }
      }),
    [children],
  );

  return (
    <Popup style={{ bottom: '128px', left: '32px', right: '32px' }}>
      {transitions.map(({ key, item, props: { life } }) => {
        const notificationClasses = `p-rel oflowh br-4 mt-4 mb-4 ml-16 mr-16 box-bb ${
          classes[item.type] || classes.info
        }`;
        const renderSponsor = ['bg-success', 'bg-info', 'bg-warning'].includes(classes[item.type]);
        return (
          <div
            className={notificationClasses}
            key={key}
            ref={(ref) => ref && refMap.set(item, ref)}
          >
            {(hasSponsor || hasTheming) && renderSponsor ? (
              <div className="f-c f-aic bg-app-sponsor W100 pt-8 pr-4 pb-8 pl-8">
                <div
                  className="p-rel bg-logo p-4"
                  style={{ width: 300, height: 16, paddingLeft: -16 }}
                />
              </div>
            ) : (
              <div className="f-c f-aic bg-app-sponsor W100 pt-8 pr-4 pb-8 pl-8">
                <p className="f2b f08 txt-notifications">INFO</p>
              </div>
            )}
            <div className="f-c f-jcsb f-aic p-8 br-4 oflowh" style={{ opacity: 0.9 }}>
              <animated.div
                className="p-abs p-bottom-left"
                style={{
                  right: life,
                  height: 5,
                  backgroundImage: 'linear-gradient(130deg, #00b4e6, #00f0e0)',
                }}
              />
              <div className="f-c pb-8 WH100">
                <h3 className="f2b f09 f-1 no-select fds-1 mr-8 txt-notifications f-lsc-2">
                  {item.msg}
                </h3>
              </div>
            </div>
          </div>
        );
      })}
    </Popup>
  );
}

export { NotificationsPopup };
