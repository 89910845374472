import React from 'react';
import { Grid } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectCoverflow } from 'swiper';

import Imagez from '../../../lib/reusable/Imagez';

SwiperCore.use([Autoplay, EffectCoverflow]);

const EventsGallery = ({ events, clickHandler }) => {
  if (!events?.length) {
    return null;
  }

  const margin = top ? '0px' : '16px 4px';
  const width = top ? '100%' : 'calc(100% - 16px)';

  return (
    <div className="f-c f-aic" style={{ margin, width }}>
      <Grid container spacing={0}>
        <Swiper
          effect="coverflow"
          id="swiper-events"
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={() => null}
          onSwiper={(swiper) => null}
          autoplay={{
            delay: 4000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          }}
        >
          {events.map((event) => {
            let type = null;
            if (Boolean(event?.link?.url)) type = 'url';
            if (Boolean(event?.link?.service)) type = 'service';
            if (Boolean(event?.link?.category)) type = 'category';
            if (Boolean(event?.link?.product)) type = 'product';

            const onImageClick = clickHandler(type, event);

            return (
              <SwiperSlide key={event.id}>
                <div className="WH100" onClick={onImageClick}>
                  <Imagez image={event.src} dropShadow />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Grid>
    </div>
  );
};

export default EventsGallery;
