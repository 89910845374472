/* eslint-disable react/no-children-prop */
import React, { useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { NotificationsPopup } from '../../notifications/components/NotificationsPopup';
import { ROUTES } from '../../../navigation/routes';

import { RoundButtonSimple } from '../../../lib/reusable/RoundButtonSimple';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { NotificationStoreContext } from '../../notifications/contexts/NotificationStoreContext';

const getActiveRoute = (pathname) => {
  return '/' + pathname.split('/')[1];
};

const NotificationsOverlay = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const placeContext = useContext(PlaceContext);
  const [notificationState] = useContext(NotificationStoreContext);

  const {
    data: {
      place: { pointId, features },
    },
  } = placeContext;

  const hasUsablePointId = pointId !== 'default' && pointId !== 'delivery';
  const hasCalls = Boolean(features?.calls?.active) && hasUsablePointId;
  const hasOrders = Boolean(features?.orders?.active) && pointId !== 'default';

  const activeRoute = getActiveRoute(pathname);
  const orderPage = activeRoute === ROUTES.ORDER;
  const callPage = activeRoute === ROUTES.CALL;
  const notificationPage = activeRoute === ROUTES.NOTIFICATIONS;

  const notificationsRef = useRef(null);

  const spawnNotification = (e) => {
    notificationsRef.current && notificationsRef.current(e.detail);
  };

  useEffect(() => {
    window.addEventListener('Notification', spawnNotification, false);
    return () => {
      window.removeEventListener('Notification', spawnNotification, false);
    };
  });

  const goToNotifications = () => history.push(`/notifications`);

  const iconPosition = { bottom: '80px', left: '14px' };
  const iconSize = 40;

  const notificationsActiveLength = Object.keys(notificationState.notifications).length;
  const hasAnyNotifications = notificationsActiveLength > 0;

  if (!hasCalls && !hasOrders) {
    return null;
  }

  return (
    <>
      <NotificationsPopup children={(add) => (notificationsRef.current = add)} />
      {!callPage && !orderPage && !notificationPage && hasAnyNotifications && (
        <div className="p-fix l3" style={iconPosition}>
          <RoundButtonSimple action={goToNotifications} size={iconSize}>
            <NotificationsActiveIcon size={20} className="txt-black" />
            {hasAnyNotifications && (
              <div
                className="p-abs br-f f-c f-jcc f-aic fds-1 bg-danger"
                style={{ top: -5, right: 30, width: 24, height: 24 }}
              >
                <h3 className="f2 f09 txt-white p-16">{notificationsActiveLength}</h3>
              </div>
            )}
          </RoundButtonSimple>
        </div>
      )}
    </>
  );
};

export { NotificationsOverlay };
