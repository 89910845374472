import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../../core/functions/utilities';

import Service from '../../features/menu/components/Service';

const getServiceIdFromPathname = (pathname) => {
  try {
    const params = pathname.split('/');
    return params[params.length - 1];
  } catch (error) {
    return null;
  }
};

const ServicePage = () => {
  const { pathname } = useLocation();
  const [serviceId, setServiceId] = useState(getServiceIdFromPathname(pathname));

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    setServiceId(getServiceIdFromPathname(pathname));
  }, [pathname]);

  return <Service serviceId={serviceId} />;
};
export default ServicePage;
