import React from 'react';
import Tag from './components/Tag';

const ProductTags = ({ product }) => {
  return (
    <div className="f-c f-w">
      {product.tags.sort().map((tag) => (
        <Tag key={tag} tag={tag} />
      ))}
    </div>
  );
};

export default ProductTags;
