import axios from 'axios';
import { URI } from '../../../core/config';

export const callRequestCompleted = async (data, reducers) => {
  const { placeId, pointId, userId } = data;
  const { callDispatch } = reducers;
  const url = `${URI.sync}?placeId=${placeId}&userId=${userId}&pointId=${pointId}&type=complete`;
  await axios.get(url);

  await callDispatch({
    type: 'CALL_COMPLETED',
    payload: {
      placeId,
      pointId,
      userId,
    },
  });
};
