import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import Imagez from '../../lib/reusable/Imagez';
import EventGallery from './components/EventsGallery';
import Moviez from '../../lib/reusable/Moviez';
import { RAFContext } from '../../core/contexts/RAFContext';

import { PlaceContext } from '../../core/contexts/PlaceContext';
import useEventsClick from './hooks/useEventClick';

const EventsPlacer = ({ menuPosition, enforce, productData }) => {
  const {
    data: {
      cache: { events },
    },
  } = useContext(PlaceContext);
  const { isMobile } = useContext(RAFContext);

  const { clickHandler } = useEventsClick();

  if (!events || events.length === 0 || !menuPosition) {
    return null;
  }

  const isProductEvent = productData && menuPosition === 'products' && enforce === 'product';
  const incomingProductEvents =
    isProductEvent && productData
      ? events?.products?.[productData.categoryId]?.[productData.productId]?.events
      : [];

  const productEvents = isProductEvent
    ? incomingProductEvents.filter((event) => Boolean(event))
    : [];

  const eventsToFilter = isProductEvent ? productEvents : events?.[menuPosition] ?? [];
  const eventsList =
    eventsToFilter.filter((event) => {
      if (enforce) {
        return enforce === event?.spread?.position || isProductEvent;
      }

      return true;
    }) || [];
  const eventsGallery = eventsList.filter((event) => event?.spread?.gallery === true);
  const eventsStatic = eventsList.filter((event) => event?.spread?.gallery === false);

  // console.log('Events Placer', 'LOCATION ->', menuPosition, '| OBJ:', eventsList);

  return (
    <>
      {/* <div className="f-c f-aic mt-16 mb-16">
        <p className="f2 f08 txt-white">EVENTS</p>
        <div className="f-1 ml-16 bg-app-separator" style={{ height: 1 }} />
      </div> */}
      {eventsGallery.length > 0 && (
        <EventGallery events={eventsGallery} clickHandler={clickHandler} />
      )}
      <div className="p-rel f-c f-aic mt-8 mb-16">
        <Grid container spacing={2}>
          {eventsStatic.map((event) => {
            let type = null;
            if (Boolean(event?.link?.url)) type = 'url';
            if (Boolean(event?.link?.service)) type = 'service';
            if (Boolean(event?.link?.category)) type = 'category';
            if (Boolean(event?.link?.product)) type = 'product';

            const onImageClick = clickHandler(type, event);

            if (event.type === 'mp4') {
              return (
                <Grid item xs={12} sm={6} key={event.id}>
                  <div className="WH100" onClick={onImageClick}>
                    {type === 'url' && (
                      <div
                        className="p-abs p-top-right pt-4 mt-2 pr-8 l2"
                        style={{ opacity: 0.75 }}
                      >
                        <LinkIcon className="txt-bread-crumb-trail s-24-i fds-2" />
                      </div>
                    )}
                    <Moviez
                      movie={event.src}
                      rounded={false}
                      dropShadow
                      limitHeight={!isMobile}
                      limitWidth={event?.spread?.configuration?.maxWidth}
                    />
                  </div>
                </Grid>
              );
            }

            return (
              <Grid item xs={12} sm={6} key={event.id}>
                <div className="WH100" onClick={onImageClick}>
                  {type === 'url' && (
                    <div className="p-abs p-top-right pt-4 mt-2 pr-8 l2" style={{ opacity: 0.75 }}>
                      <LinkIcon className="txt-bread-crumb-trail s-24-i fds-2" />
                    </div>
                  )}
                  <Imagez image={event.src} dropShadow />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default EventsPlacer;
