import React, { useContext } from 'react';
import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { iframeExternalLink } from '../../../core/functions/utilities';
import InstagramIcon from '@mui/icons-material/Instagram';

const Instagram = () => {
  const {
    data: {
      place: { instagram, inIframe },
    },
  } = useContext(PlaceContext);

  const goToInstagram = () => {
    iframeExternalLink(instagram, inIframe);
  };

  if (!instagram) return null;
  return (
    <div onClick={goToInstagram} className="ml-8 mr-4">
      <InstagramIcon />
    </div>
  );
};

export default Instagram;
