import React from 'react';
import BarLoader from 'react-spinners/BarLoader';

const PlaceHolder = ({ isLoading, rounded, overlay = true }) => {
  if (isLoading === false) return null;

  const preloader = (
    <div className="f-c f-1 f-jcc f-aic">
      <BarLoader height={2} width={60} loading={true} color="#ffffff" />
    </div>
  );

  if (!overlay) {
    return (
      <div className="p-abs p-full f-c f-1 bg-placeholder" style={{ minHeight: 160 }}>
        {preloader}
      </div>
    );
  }
  return (
    <div
      className={`f-c f-1 W100 bg-placeholder ${rounded ? 'br-2' : ''}`}
      style={{ minHeight: 160 }}
    >
      {preloader}
    </div>
  );
};

export default PlaceHolder;
