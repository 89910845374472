import React from 'react';

function MaxCharsComponent({ text, maxChars, shouldBeOutlined }) {
  const UnderlineComponent = () => (
    <span
      style={{
        position: 'absolute',
        left: '16px',
        right: '16px',
        bottom: '10px',
        height: '2px',
        background: 'black',
        borderRadius: '1.5px',
      }}
    ></span>
  );

  return (
    <div>
      {shouldBeOutlined && <UnderlineComponent />}
      <p>{text.length <= maxChars ? text : text.substring(0, maxChars) + '...'}</p>
    </div>
  );
}

export default MaxCharsComponent;
