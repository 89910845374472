import React from 'react';

const InactivePlace = ({ active }) => {
  const disabled = active === false;
  const nonExistant = active === undefined;
  const serverError = active === null;

  const disabledComponent = (
    <div className="f-c f-dirc f-jcc f-aic minWH100v ta-c">
      <p className="f2 f1">THIS LOCATION IS NOT AVAILABLE FOR THE MOMENT</p>
    </div>
  );

  const nonExistantComponent = (
    <div className="f-c f-dirc f-jcc f-aic minWH100v ta-c">
      <p className="f2 f1">NO LOCATION FOUND</p>
    </div>
  );

  const serverErrorComponent = (
    <div className="f-c f-dirc f-jcc f-aic minWH100v ta-c">
      <p className="f2 f1">THERE WAS A PROBLEM PLEASE TRY AGAIN IN A FEW MINUTES</p>
    </div>
  );

  if (disabled) {
    return disabledComponent;
  } else if (nonExistant) {
    return nonExistantComponent;
  } else if (serverError) {
    return serverErrorComponent;
  }

  return serverErrorComponent;
};

export default InactivePlace;
