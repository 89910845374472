import React, { useState, useContext } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Imagez from '../../../lib/reusable/Imagez';
import Moviez from '../../../lib/reusable/Moviez';
import GradientHolder from '../../../lib/reusable/GradientHolder';

import { RAFContext } from '../../../core/contexts/RAFContext';
import { PlaceContext } from '../../../core/contexts/PlaceContext';

const CategoryCard = (props) => {
  const { data, action, top, description, intdescription, cardType } = props;
  const { isMobile } = useContext(RAFContext);
  const {
    data: {
      cache: {
        categories: { categoryId__products },
      },
    },
  } = useContext(PlaceContext);

  let hasList = true;
  const isCategory = cardType !== 'service';
  const cardList = categoryId__products?.[data.id] || [];

  if (isCategory && cardList.length === 1) {
    if (cardList[0].delimiter) {
      hasList = false;
    }
  } else if (isCategory && cardList.length === 0) {
    hasList = false;
  }

  const [errorImage, setErrorImage] = useState(false);

  if (!data) return null;

  const movie =
    data.moviesXL && Array.isArray(data.moviesXL) && data.moviesXL.length > 0
      ? data.moviesXL[0]
      : null;

  const image =
    data.imagesXL && Array.isArray(data.imagesXL) && data.imagesXL.length > 0
      ? data.imagesXL[0]
      : null;

  const handleNoImage = () => {
    setErrorImage(true);
  };

  const handleClick = () =>
    hasList ? action({ categoryId: data.id, categoryName: data.name, top, cardType }) : null;

  const text = data.name ? data.name.toUpperCase() : null;
  const subText = data.intname ? data.intname.toUpperCase() : null;

  const shownDescription =
    data?.description && data?.description.length > 0
      ? data.description
      : description
      ? description
      : null;

  return (
    <div className="p-rel mp0 W100" key={data.id} onClick={handleClick}>
      <div className="p-rel f-c H100 bds-1">
        {hasList && (
          <div className="p-abs p-bottom-right pb-8 pr-8 l2">
            <ArrowForwardIosIcon className="txt-bread-crumb-trail s-12-i fds-2" />
          </div>
        )}
        {image && !errorImage ? (
          <Imagez
            image={image}
            rounded={!top}
            dropShadow
            text={text}
            subText={subText}
            handleNoImage={handleNoImage}
            top={top}
            limitHeight={!isMobile}
            maxvh="60vh"
          />
        ) : movie ? (
          <Moviez
            movie={movie}
            rounded={!top}
            dropShadow
            text={text}
            subText={subText}
            top={top}
            limitHeight={!isMobile}
            maxvh="60vh"
          />
        ) : (
          <GradientHolder top={top}>
            <div className="p-abs p-full f-c f-1 pl-16 f-aife">
              <h3 className="f1b f10 ib-c fds-1 mb-8 t-UC">{data.name}</h3>
            </div>
          </GradientHolder>
        )}
      </div>
      {shownDescription && (
        <div className="pt-8 pb-8 pl-16 pr-8 bg-overlay-3 fds-2">
          <div className="f1 f09 txt-app-dim">{shownDescription}</div>
          {intdescription && <div className="f1 f09 txt-app-dim pt-8">{intdescription}</div>}
        </div>
      )}
    </div>
  );
};

export default CategoryCard;
