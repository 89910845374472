import React, { useState } from 'react';

export const MenuButton = ({ action, name, active, fw, b, left }) => {
  const [state, toggle] = useState(true);
  const animatedAction = () => {
    toggle(!state);
    action();
  };

  const hasActiveInactive = active !== undefined;
  const inactive = active === false;

  let bgClasses = 'p-rel br-4 m0 pc-lr pt-8 pb-8 no-border fds-3 no-select cursor-default';

  hasActiveInactive && active && (bgClasses += ' bg-buttons');
  hasActiveInactive && inactive && (bgClasses += 'bg-transparent');
  hasActiveInactive && !active && !inactive && (bgClasses += ' bg-buttons');
  !hasActiveInactive && (bgClasses += '  bg-buttons');
  fw && (bgClasses += ' W100');

  let txtClasses = 'f2 f08 t-UC';
  hasActiveInactive && !inactive && (txtClasses += ' txt-buttons');
  hasActiveInactive && inactive && (txtClasses += ' txt-white');
  !hasActiveInactive && (txtClasses += ' txt-buttons');
  b && (txtClasses += ' f2b');
  left && (txtClasses += ' ta-l');

  return (
    <button className={bgClasses} onClick={animatedAction} style={{ height: 38 }}>
      <h3 className={txtClasses} style={{ lineHeight: '38px', marginTop: -8 }}>
        {name}
      </h3>
    </button>
  );
};

export const MenuButtonYes = ({ action, name, fw, b }) => {
  const [state, toggle] = useState(true);
  const animatedAction = () => {
    toggle(!state);
    action();
  };

  let bgClasses =
    'p-rel br-4 m0 pc-lr pt-8 pb-8 no-border fds-3 bg-calls-cancel-yes no-select cursor-default';
  fw && (bgClasses += ' W100');

  let txtClasses = 'f2 f10 t-UC txt-calls-cancel-yes';
  b && (txtClasses += ' f2b');

  return (
    <button className={bgClasses} onClick={animatedAction} style={{ height: 38 }}>
      <h3 className={txtClasses} style={{ lineHeight: '38px', marginTop: -8 }}>
        {name}
      </h3>
    </button>
  );
};

export const MenuButtonOrderNow = ({ action, name, size }) => {
  let bgClasses = 'p-rel p-8 br-4 mp0 no-border fds-1 bg-info-light no-select cursor-default HW100';
  let txtClasses = 'f1b f12 t-UC txt-notifications';

  return (
    <button className={bgClasses} onClick={action} style={{ height: size }}>
      <p className={txtClasses}>{name}</p>
    </button>
  );
};

export const MenuButtonOk = ({ action, name, fw, b }) => {
  const [state, toggle] = useState(true);
  const animatedAction = () => {
    toggle(!state);
    action();
  };

  let bgClasses =
    'p-rel br-4 m0 pc-lr pt-8 pb-8 no-border fds-3 bg-info-light no-select cursor-default';
  fw && (bgClasses += ' W100');

  let txtClasses = 'f2 f10 t-UC txt-notifications';
  b && (txtClasses += ' f2b');

  return (
    <button className={bgClasses} onClick={animatedAction} style={{ height: 38 }}>
      <h3 className={txtClasses} style={{ lineHeight: '38px', marginTop: -8 }}>
        {name}
      </h3>
    </button>
  );
};

export const MenuButtonNo = ({ action, name, fw, b }) => {
  const [state, toggle] = useState(true);
  const animatedAction = () => {
    toggle(!state);
    action();
  };

  let bgClasses =
    'p-rel br-4 m0 pc-lr pt-8 pb-8 no-border fds-3 bg-calls-cancel-no no-select cursor-default';
  fw && (bgClasses += ' W100');

  let txtClasses = 'f2 f10 t-UC txt-calls-cancel-no';
  b && (txtClasses += ' f2b');

  return (
    <button className={bgClasses} onClick={animatedAction} style={{ height: 38 }}>
      <h3 className={txtClasses} style={{ lineHeight: '38px', marginTop: -8 }}>
        {name}
      </h3>
    </button>
  );
};
