import i18next from 'i18next';

export const callRequestReceivedByPlace = async (payload, reducers, data) => {
  const { placeId, pointId, userId } = data;
  const { callDispatch, notificationDispatch } = reducers;

  await callDispatch({
    type: 'CALL_RECEIVED',
    payload: {
      placeId,
      pointId,
      userId,
    },
  });

  const date = new Date().getTime();
  const message = i18next.t('calls.NOTIFICATIONS.success');
  const newNotification = new CustomEvent('Notification', {
    detail: { message, type: 'info' },
  });
  window.dispatchEvent(newNotification);
  window.__TIMEOUTS__.calls.initiated[userId] !== undefined &&
    clearTimeout(window.__TIMEOUTS__.calls.initiated[userId]);
  notificationDispatch({
    type: 'ADD_NOTIFICATION',
    payload: {
      notificationId: 'call',
      message,
      placeId,
      pointId,
      userId,
      type: 'info',
      date,
    },
  });
};
