import i18next from 'i18next';

export const cancelPendingOrderReceivedByPlace = async (payload, reducers, data) => {
  const { placeId, pointId, userId } = data;
  const { orderDispatch, notificationDispatch } = reducers;
  const { orderId } = payload;

  await orderDispatch({
    type: 'ORDER_CANCEL',
    payload: {
      placeId,
      orderId,
      pointId,
      userId,
    },
  });
  const message = i18next.t('orders.NOTIFICATIONS.success_on_cancel');
  const date = new Date().getTime();
  const newNotification = new CustomEvent('Notification', {
    detail: { message, type: 'success', orderId, date },
  });
  window.dispatchEvent(newNotification);
  notificationDispatch({
    type: 'UPDATE_NOTIFICATION',
    payload: {
      notificationId: orderId,
      message,
      placeId,
      pointId,
      type: 'success',
      date,
    },
  });
};
