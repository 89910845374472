import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Moviez from '../../../lib/reusable/Moviez';
import KeyboardDoubleArrowDown from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUp from '@mui/icons-material/KeyboardDoubleArrowUp';
import ProductContent from './ProductContent';
import ProductOrdering from './ProductOrdering';
import { Badges } from './Badges';
import LinkIcon from '@mui/icons-material/Link';
import Imagez from '../../../lib/reusable/Imagez';

import Toppings from '../../toppings/components/Toppings';
import ToppingsAvailable from '../../toppings/components/ToppingsAvailable';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { CurrenciesContext } from '../../../core/contexts/CurrenciesContext';
import { WSContext } from '../../../core/websockets/WSContext';
import { RAFContext } from '../../../core/contexts/RAFContext';
import { UIMemoryContext, PRODUCT_STATES } from '../../../core/contexts/UIMemoryContext';

export default function ProductCard({ product, calledFromCategoryId }) {
  // const [state] = useContext(OrderStoreContext);
  const [errorImage, setErrorImage] = useState(false);
  const {
    data: {
      place: { pointId, ordering, features },
      cache: { categories },
    },
  } = useContext(PlaceContext);
  const [productsUI, changeProductState] = useContext(UIMemoryContext);
  const [currencies] = useContext(CurrenciesContext);

  const informativeCurrencyAvailable = features?.place?.otherCurrencies?.informativeCurrency
    ? features?.place?.otherCurrencies?.informativeCurrency
    : null;

  const informativeCurrency = currencies.informative[informativeCurrencyAvailable];

  const presentationProduct = features?.products?.presentationOnly;

  const hasOrders =
    (Boolean(features?.orders?.active) && pointId !== 'default' && pointId !== 'delivery') ||
    (pointId === 'delivery' && ordering);
  const hasToppings = Boolean(features?.toppings?.active);

  const hasContents = Array.isArray(product?.contents) && product.contents.length > 0;
  const hasDetails = product?.details?.length > 0;
  const hasConsumers = product?.consumers?.length > 0;
  const hasTags = Array.isArray(product?.tags) && product?.tags.length > 0;

  const hasExpand = hasContents || hasDetails || hasConsumers || hasTags;
  const isFromAnotherCategoryButShouldBeCompact =
    calledFromCategoryId &&
    calledFromCategoryId !== product.category_id &&
    Boolean(categories?.categoryId__category?.[calledFromCategoryId]?.compact);
  const hasCompact =
    Boolean(categories?.categoryId__category?.[product.category_id]?.compact) ||
    isFromAnotherCategoryButShouldBeCompact;

  // console.log(product.name, hasExpand, hasCompact, calledFromCategoryId);

  const uiState = productsUI.products[product.id]
    ? productsUI.products[product.id]
    : {
        previous: null,
        active: hasCompact ? PRODUCT_STATES.COMPACT : PRODUCT_STATES.DEFAULT,
      };
  const [productRenderMode, setProductRenderMode] = useState(uiState);

  const { WSConnection } = useContext(WSContext);
  const { isMobile } = useContext(RAFContext);

  const [toppingsModalIsOpen, setToppingsModalIsOpen] = useState(false);

  const history = useHistory();

  if (!product) return null;

  if (product.delimiter) {
    if (product.main) {
      return <div className="p-rel W100 pb-2 mt-16 f16 txt-app t-UC">{product.name}</div>;
    }
    return (
      <div className="p-rel W100 pb-2 mt-32 bb-app t-UC">
        <div className="f2b f13" style={{ textAlign: 'center' }}>
          {product.name}
        </div>
        {product.intname && <div>{product.intname}</div>}
      </div>
    );
  }

  const handleNoImage = () => {
    setErrorImage(true);
  };

  const setProductUIState = (newProductState) => {
    setProductRenderMode(newProductState);
    changeProductState(product.id, newProductState);
  };

  const toggleProductRenderMode = () => {
    if (hasCompact) {
      if (productRenderMode.active === PRODUCT_STATES.COMPACT) {
        setProductUIState({
          previous: PRODUCT_STATES.COMPACT,
          active: PRODUCT_STATES.EXPANDED,
        });

        return;
      }

      // if (productRenderMode.active === PRODUCT_STATES.DEFAULT) {
      //   if (productRenderMode.previous === PRODUCT_STATES.COMPACT) {
      //     setProductUIState({
      //       previous: PRODUCT_STATES.DEFAULT,
      //       active: hasExpand ? PRODUCT_STATES.EXPANDED : PRODUCT_STATES.COMPACT,
      //     });

      //     return;
      //   }

      //   if (hasExpand && productRenderMode.previous === PRODUCT_STATES.EXPANDED) {
      //     setProductUIState({
      //       previous: PRODUCT_STATES.DEFAULT,
      //       active: PRODUCT_STATES.COMPACT,
      //     });

      //     return;
      //   }
      // }

      if (productRenderMode.active === PRODUCT_STATES.EXPANDED) {
        setProductUIState({
          previous: PRODUCT_STATES.EXPANDED,
          active: PRODUCT_STATES.COMPACT,
        });

        return;
      }

      return;
    }

    if (productRenderMode.active === PRODUCT_STATES.DEFAULT) {
      setProductUIState({
        previous: PRODUCT_STATES.DEFAULT,
        active: PRODUCT_STATES.EXPANDED,
      });

      return;
    }

    if (productRenderMode.active === PRODUCT_STATES.EXPANDED) {
      setProductUIState({
        previous: PRODUCT_STATES.EXPANDED,
        active: PRODUCT_STATES.DEFAULT,
      });

      return;
    }
  };

  const handleImageClick = () => {
    if (product?.external_link) {
      window.location.href = product.external_link;
      return;
    }

    history.push(`/product/${product.id}`);
  };

  const hasImage = product.imagesXL && product.imagesXL[0] && !errorImage;

  const hasSocketConnection = Boolean(WSConnection);

  const showOrder = hasOrders && ordering && hasSocketConnection;

  const movie =
    product.moviesXL && Array.isArray(product.moviesXL) && product.moviesXL.length > 0
      ? product.moviesXL[0]
      : null;

  const compact = productRenderMode.active === PRODUCT_STATES.COMPACT;
  const expand = productRenderMode.active === PRODUCT_STATES.EXPANDED;

  const productCannotExpandMore = productRenderMode.active === PRODUCT_STATES.EXPANDED;

  const ultraImage = features?.place?.imageQuality === 'ultra' && product?.imagesULTRA[0];

  return (
    <div className={`p-rel${presentationProduct ? '' : ' bg-product'}`}>
      {((!hasImage && showOrder) || compact) && (
        <ProductOrdering
          product={product}
          showOrder
          style={{ top: -16, right: 8 }}
          noImage={true}
        />
      )}
      <div className={`p-rel mt-24 W100 ${presentationProduct ? '' : ' bds-1'}`}>
        {Number(product.cost) !== 0 && (
          <div
            className="p-abs pc-lr ta-c bg-product-price l1"
            style={{
              left: 0,
              borderRadius: '0px 0px 0px 0px',
              minWidth: 65,
              top: 0,
              height: 30,
            }}
          >
            <h3 className="f2b lh10 mp0 txt-product-price" style={{ lineHeight: '30px' }}>
              <span className="f11 f-ls-64">{`${product.cost}`}</span>
              <span className="f11" style={{ fontWeight: 400 }}>{` ${product.currency}`}</span>
              {informativeCurrency && (
                <span
                  className="f10 f02"
                  style={{ fontWeight: 400, color: '#bbbbffC0' }}
                >{` ≈ ${informativeCurrency(product.cost)}€`}</span>
              )}
            </h3>
          </div>
        )}
        {!presentationProduct && !compact && (hasImage || movie) ? (
          <div className="p-rel W100" onClick={handleImageClick}>
            <div className="p-abs l1" style={{ bottom: 8, left: 8 }}>
              <Badges product={product} />
            </div>
            {product?.external_link && (
              <div className="p-abs p-top-right pt-4 pr-8 l2">
                <LinkIcon className="txt-bread-crumb-trail s-24-i fds-2" />
              </div>
            )}
            {movie ? (
              <Moviez movie={movie} rounded={!top} dropShadow limitHeight={!isMobile} />
            ) : (
              <Imagez
                image={ultraImage ? product.imagesULTRA[0] : product.imagesXL[0]}
                handleNoImage={handleNoImage}
                limitHeight={!isMobile}
                fallbackXL={product.imagesXL[0]}
                maxvh="60vh"
              />
            )}
            {showOrder && (
              <ProductOrdering
                product={product}
                style={{ bottom: 16, right: 8 }}
                showOrder
                noImage={false}
              />
            )}
          </div>
        ) : compact && !presentationProduct ? (
          <>
            <div
              className="p-rel mt-16 W100 bg-product"
              style={{ height: 34, borderRadius: '6px 6px 0px 0px' }}
            />
            <div className="f-c" onClick={toggleProductRenderMode}>
              <div className="f-0 ml-4">
                <Imagez
                  rounded
                  image={product.imagesXL[0]}
                  handleNoImage={handleNoImage}
                  limitHeight={!isMobile}
                  style={{ width: 72, height: 72, borderRadius: '100%' }}
                />
              </div>

              {(hasExpand || hasCompact) && (
                <div className="p-abs p-top-right txt-info-light">
                  {productCannotExpandMore ? (
                    <KeyboardDoubleArrowUp
                      style={{ width: 24, height: 24, margin: 4, marginTop: compact ? 16 : 6.5 }}
                    />
                  ) : (
                    <KeyboardDoubleArrowDown
                      style={{ width: 24, height: 24, margin: 4, marginTop: compact ? 16 : 6.5 }}
                    />
                  )}
                </div>
              )}
              <ProductContent
                product={product}
                expanded={expand}
                hasImage={hasImage}
                compact={compact}
              />
            </div>
          </>
        ) : presentationProduct ? (
          <div className="p-rel W100" onClick={handleImageClick}>
            {product?.external_link && (
              <div className="p-abs p-top-right pt-4 pr-8 l2">
                <LinkIcon className="txt-bread-crumb-trail s-24-i fds-2" />
              </div>
            )}
            {movie ? (
              <Moviez movie={movie} rounded={!top} dropShadow limitHeight={!isMobile} />
            ) : (
              <Imagez
                image={product.imagesXL[0]}
                handleNoImage={handleNoImage}
                limitHeight={!isMobile}
                maxvh="60vh"
              />
            )}
          </div>
        ) : (
          <div
            className="p-rel mt-16 W100 bg-product"
            style={{ height: 24, borderRadius: '6px 6px 0px 0px' }}
          />
        )}
        {presentationProduct || compact ? null : (
          <div
            onClick={toggleProductRenderMode}
            className="p-rel f-c f-dirc f-aic WH100 oflowh mp0 no-border l1"
            style={{
              borderRadius: '0px 0px 6px 6px',
            }}
          >
            {hasExpand && (
              <div className="p-abs p-top-right txt-info-light">
                {productCannotExpandMore ? (
                  <KeyboardDoubleArrowUp
                    style={{ width: 24, height: 24, margin: 4, marginTop: 6.5 }}
                  />
                ) : (
                  <KeyboardDoubleArrowDown
                    style={{ width: 24, height: 24, margin: 4, marginTop: 6.5 }}
                  />
                )}
              </div>
            )}
            <ProductContent
              product={product}
              expanded={expand}
              hasImage={hasImage}
              compact={compact}
            />
            {!hasImage && !isMobile && <div className="W100" style={{ minHeight: 133 }} />}
          </div>
        )}
      </div>
      {hasToppings && !hasOrders && (
        <ToppingsAvailable
          product={product}
          toppingsModalIsOpen={toppingsModalIsOpen}
          setToppingsModalIsOpen={setToppingsModalIsOpen}
        />
      )}
      {hasToppings && showOrder && <Toppings product={product} />}
    </div>
  );
}
