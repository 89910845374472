import React, { useContext } from 'react';
import Facebook from './Facebook';
import Tripadvisor from './Tripadvisor';
import Instagram from './Instagram';
import Bookingham from './Bookingham';
import Google from './Google';
import { RAFContext } from '../../../core/contexts/RAFContext';

import LocationOnIcon from '@mui/icons-material/LocationOn';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

const Header = () => {
  const {
    data: {
      place: { features, name: placeName },
    },
  } = useContext(PlaceContext);
  const { isMobile } = useContext(RAFContext);

  const hasSponsor = Boolean(features?.sponsor?.active);
  const hasTheming = Boolean(features?.theming?.active);

  const location = placeName ? (
    <div className="f-c f-aic" style={{ height: 40, marginLeft: -4, maxWidth: 240 }}>
      <LocationOnIcon className="s-16-i txt-app mr-2" />
      <span className="f2b f07 mr-4 t-UC">{placeName}</span>
    </div>
  ) : null;

  return (
    <>
      <div
        className=" pe-none p-fix p-top mc bg-interface-fader-top l1"
        style={{ opacity: 0.9, height: 150 }}
      />
      <div className="pe-none p-fix p-top mc bg-interface-fader-top-glass l2" />
      <div className="p-fix p-top f-c f-jcsb l3">
        <div className="f-c f-aic p-8 l3">
          {!hasSponsor && !hasTheming ? (
            location
          ) : (
            <div className="p-rel f-1 bg-logo" style={{ minWidth: 128, height: 40 }} />
          )}
        </div>
        {isMobile && (
          <div
            className="p-abs p-top-right f-c f-jcfe p-8 l3"
            style={{ marginRight: -4, top: '5px' }}
          >
            <Bookingham />
            <Tripadvisor />
            <Google />
            <Facebook />
            <Instagram />
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
