import { pushNotification } from '../../../core/functions/pushNotifications';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

export const rejectPendingPoint = (payload, reducers, data) => {
  const uuid = uuidv4();
  const { placeId, pointId, userId, events } = payload;
  const { orderDispatch, notificationDispatch, callDispatch } = reducers;
  const date = new Date().getTime();
  const message = i18next.t('orders.NOTIFICATIONS.reject');
  const newNotification = new CustomEvent('Notification', {
    detail: { message, type: 'danger', orderId: uuid, date: new Date().getTime() },
  });
  window.dispatchEvent(newNotification);
  orderDispatch({
    type: 'REJECT_POINT',
    payload: {
      placeId,
      rejectedOrders: events.orders || {},
      userId,
      pointId,
    },
  });
  callDispatch({
    type: 'CONFIRM_POINT', // if rejected they will still come to you
    payload: {
      placeId,
      rejectedCalls: events.calls || {},
      userId,
      pointId,
    },
  });
  notificationDispatch({
    type: 'UPDATE_NOTIFICATION',
    payload: {
      notificationId: uuid,
      message,
      placeId,
      pointId,
      type: 'danger',
      date,
    },
  });

  pushNotification(message);
};
