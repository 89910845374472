import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectCoverflow } from 'swiper';

import CallProduct from './CallProduct';

import { NotificationStoreContext } from '../../notifications/contexts/NotificationStoreContext';
import { CallStoreContext } from '../contexts/CallStoreContext';
import { initiateCall } from '../api/initiateCall';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { WSContext } from '../../../core/websockets/WSContext';

SwiperCore.use([Autoplay, EffectCoverflow]);

const CallProductLister = ({ close }) => {
  const {
    data: {
      place: { placeId, pointId, features },
      userId,
      userDevice,
    },
  } = useContext(PlaceContext);

  const hasSponsor = Boolean(features?.sponsor?.active);

  const callSponsorProducts = [];
  const callProducts = [];

  try {
    if (hasSponsor) {
      for (const productId in features?.sponsor?.calls?.products) {
        const position = features?.sponsor?.calls?.products[productId];
        callSponsorProducts[position - 1] = productId;
      }
    }
    for (const productId in features?.calls?.products) {
      const position = features?.calls?.products[productId];
      callProducts[position - 1] = productId;
    }
  } catch (error) {}

  const [, notificationDispatch] = useContext(NotificationStoreContext);
  const [callState, callDispatch] = useContext(CallStoreContext);
  const { WSConnection } = useContext(WSContext);

  const reducers = { callState, callDispatch, notificationDispatch };

  const callWithProduct = async (productId) => {
    const data = {
      placeId,
      userId,
      pointId,
      userDevice,
      details: { productId },
    };
    await initiateCall(data, reducers, WSConnection);
    close();
  };

  const productsToCall = [...callSponsorProducts, ...callProducts];

  return (
    <div className="p-rel f-c f-1 f-dirc f-jcc oflowh bg-app-call-product">
      <Swiper
        effect="coverflow"
        id="swiper-call-products"
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => null}
        onSwiper={(swiper) => null}
        autoplay={{
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        }}
      >
        {productsToCall.map((productId) => {
          return (
            <SwiperSlide key={productId} onClick={() => callWithProduct(productId)}>
              <div className="f-c f-jcc f-aic WH100 pe-none">
                <CallProduct productId={productId} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export { CallProductLister };
