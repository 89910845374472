import React, { useState, useEffect } from 'react';
import { placeContextObject } from './PlaceContextObject';
import { initializeMenu } from '../api/initializeMenu';
import { URI } from '../config';
import moment from 'moment-timezone';
import { checkAvailableHours } from '../functions/checkAvailableHours';

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const PlaceContext = React.createContext();
export const PlaceProvider = ({ children, placeRoute, productReference }) => {
  const { placeId, pointId, userId, mode, userDevice = 'mobile', queryParams } = placeRoute;
  const delivery = mode === 'delivery';
  const defaultState = {
    ...placeContextObject,
    id: placeId,
    gdpr: true,
  };
  const [data, setData] = useState(defaultState);
  const [loading, setLoading] = useState(true);

  const initializeTheme = (themeName, themeType) => {
    if (!placeId || !themeType) return;

    const hasSponsor = themeType === 'sponsor';
    try {
      const random = new Date().getTime();
      const cssTheme = `sponsor-${random}`;

      const placeCSS = `${URI.bucket}/${placeId}/theming/${themeName}/${themeName}.css?${random}`;
      const sponsorCSS = `${URI.bucket}/__theming__/${themeName}/${themeName}.css?${random}`;
      const CSS = hasSponsor ? sponsorCSS : placeCSS;

      if (!document.getElementById(cssTheme)) {
        const head = document.getElementsByTagName('head')[0];
        const link = document.createElement('link');
        link.id = cssTheme;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = CSS;
        link.media = 'screen';
        head.appendChild(link);
      }
    } catch (error) {
      console.error('THEMING', error);
    }
  };

  const removeTheme = () => {
    try {
      const list = document.head.getElementsByTagName('link');

      for (const link of list) {
        const linkIsTheme = link.href.includes('sponsor');
        if (linkIsTheme) {
          head.removeChild(link);
          window.location.reload();
          break;
        }
      }
    } catch (error) {}
  };

  const initialize = async (placeId) => {
    !loading && setLoading(true);
    const { place, cache, status } = await initializeMenu(
      placeId,
      userId,
      pointId,
      pointId === 'delivery',
    );

    if (status !== 200) {
      if (status === 401) {
        defaultState.place.active = false;
        setData(defaultState);
        setLoading(false);
        return { error: true, message: `${placeId.toUpperCase()} IS SET INACTIVE` };
      } else if (status === 204) {
        defaultState.place.active = undefined;
        setData(defaultState);
        setLoading(false);
        return { error: true, message: `NO LOCATION FOUND ${placeId.toUpperCase()}` };
      } else {
        defaultState.place.active = null;
        setData(defaultState);
        setLoading(false);
        return {
          error: true,
          message: `THERE WAS A PROBLEM REQUESTING ${placeId.toUpperCase()}'s MENU'`,
        };
      }
    }

    const [placeData] = place;

    const sponsorTheme = placeData?.features?.sponsor?.theme;
    const placeTheme = placeData?.features?.theming?.theme;

    const hasSponsor =
      Boolean(placeData?.features?.sponsor?.active) &&
      typeof sponsorTheme === 'string' &&
      sponsorTheme.length > 0;
    const hasTheming =
      Boolean(placeData?.features?.theming?.active) &&
      typeof placeTheme === 'string' &&
      placeTheme.length > 0;

    const themeType = hasSponsor ? 'sponsor' : hasTheming ? 'theme' : null;
    const theme = hasSponsor ? sponsorTheme : hasTheming ? placeTheme : null;

    if (theme) {
      initializeTheme(theme, themeType);
    }

    const newPointId = mode === 'order' && pointId ? pointId : delivery ? 'delivery' : 'default';

    let newOrdering = false;
    let hasPickup = false;
    let hasDelivery = false;
    const hasDeliveryAvailable =
      delivery &&
      newPointId === 'delivery' &&
      Boolean(placeData?.features?.delivery?.active) &&
      (Boolean(placeData?.features?.delivery?.pickup?.active) ||
        Boolean(placeData?.features?.delivery?.delivery?.active));
    if (hasDeliveryAvailable) {
      const timezone = placeData?.timezone ? placeData?.timezone : 'Europe/Bucharest';
      const day = moment().tz(timezone).local().format('ddd').toLowerCase();

      const deliveryHours = placeData?.features?.delivery?.delivery?.availability?.[day];
      const pickupHours = placeData?.features?.delivery?.pickup?.availability?.[day];

      const availableToday = Boolean(deliveryHours) || Boolean(pickupHours);

      if (!availableToday) {
        return;
      }

      hasPickup =
        Boolean(placeData?.features?.delivery?.pickup?.active) &&
        pickupHours &&
        checkAvailableHours(pickupHours, timezone);
      hasDelivery =
        Boolean(placeData.features.delivery.delivery.active) &&
        deliveryHours &&
        checkAvailableHours(deliveryHours, timezone);

      // console.log('hasPickup', hasPickup);
      // console.log('hasDelivery', hasDelivery);

      if (hasPickup || hasDelivery) {
        // console.log('HAS DELIVERY', true);
        newOrdering = true;
      }
    } else if (!Boolean(placeData?.features?.orders?.deliveryOnly)) {
      newOrdering = mode === 'order' && Boolean(placeData?.features?.orders?.active);
    }

    // console.log('delivery', delivery);
    // console.log('newPointId', newPointId);
    // console.log('placeData.features.delivery.active', placeData?.features?.delivery?.active);
    // console.log('ORDERING', newOrdering);

    const newCalling =
      Boolean(placeData?.features?.calls?.active) &&
      pointId !== 'default' &&
      pointId !== 'delivery';

    const newPlace = {
      active: true,
      address: placeData.address,
      email: placeData.email,
      placeName: placeData.name,
      facebook: placeData.facebook,
      tripadvisor: placeData.tripadvisor,
      bookingham: placeData.bookingham,
      has_image: placeData.has_image,
      instagram: placeData.instagram,
      google: placeData.google,
      latitude: placeData.latitude,
      timezone: placeData?.timezone ? placeData?.timezone : 'Europe/Bucharest',
      longitude: placeData.longitude,
      mobile: placeData.mobile,
      inIframe: inIframe(),
      name: placeData.name,
      placeId: placeData.place_id,
      pointId: newPointId,
      ordering: newOrdering,
      delivery: {
        hasPickup,
        hasDelivery,
      },
      calling: newCalling,
      features: placeData.features,
    };

    const newData = {
      gdpr: queryParams && queryParams.gdpr && queryParams.gdpr === 'false' ? false : true,
      ...placeContextObject,
      place: newPlace,
      userId,
      userDevice,
      cache,
    };

    setData(newData);
    setLoading(false);

    return { error: false, message: 'Success' };
  };

  useEffect(() => {
    initialize(placeId, delivery);
  }, [pointId, mode, placeId, delivery, userId, userDevice]);

  useEffect(() => {
    const noPlaceId = !Boolean(placeId) || placeId === '';
    if (noPlaceId) return;
    if (productReference) {
      history.push(`/product/${productReference}`);
    }
    const handleOnFocus = () => {
      if (document.hidden) {
      } else {
        setLoading(true);
        initialize(placeId, delivery);
      }
    };

    document.addEventListener('visibilitychange', handleOnFocus, true);
    return () => {
      document.removeEventListener('visibilitychange', handleOnFocus, true);
    };
  }, [placeId, userId, pointId]);

  // useEffect(() => {
  //   function checkNotificationPromise() {
  //     try {
  //       Notification.requestPermission().then();
  //     } catch (e) {
  //       return false;
  //     }
  //     return true;
  //   }

  //   function askNotificationPermission() {
  //     // function to actually ask the permissions
  //     function handlePermission(permission) {
  //       // set the button to shown or hidden, depending on what the user answers
  //     }
  //     // Let's check if the browser supports notifications
  //     if (!('Notification' in window)) {
  //     } else {
  //       if (checkNotificationPromise()) {
  //         Notification.requestPermission().then((permission) => {
  //           handlePermission(permission);
  //         });
  //       } else {
  //         Notification.requestPermission(function (permission) {
  //           handlePermission(permission);
  //         });
  //       }
  //     }
  //   }
  //   if (mode === 'order' && Notification.permission !== 'granted') {
  //     askNotificationPermission();
  //   }
  // });

  return (
    <PlaceContext.Provider value={{ data, loading, initialize }}>{children}</PlaceContext.Provider>
  );
};
