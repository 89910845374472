import React, { useState, useEffect, useContext } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import ProgressiveImage from 'react-progressive-image';
import disableScroll from 'disable-scroll';

import { PlaceContext } from '../../core/contexts/PlaceContext';

import { URI } from '../../core/config';

const sponsorDate = new Date().getTime();

const Sponsor = ({ loading }) => {
  const {
    data: {
      place: { placeId, features },
    },
  } = useContext(PlaceContext);

  const [errorImage, setErrorImage] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    disableScroll.on();
    return () => {
      disableScroll.off();
    };
  }, []);

  const hasSponsor = Boolean(features?.sponsor?.active);
  const hasTheming = Boolean(features?.theming?.active);

  const sponsorTheme = features?.sponsor?.theme;
  const placeTheme = features?.theming?.theme;

  const hasSponsorTheme = hasSponsor && typeof sponsorTheme === 'string' && sponsorTheme.length > 0;
  const hasPlaceTheme = hasTheming && typeof placeTheme === 'string' && placeTheme.length > 0;

  const theme = hasSponsorTheme ? sponsorTheme : hasPlaceTheme ? placeTheme : null;

  if (!placeId || !theme) {
    console.log('NO PLACE ID OR NO SPONSOR OR THEME', placeId, hasSponsor, theme);
    return null;
  }

  const imagePlace = `${URI.bucket}/${placeId}/theming/${theme}/loading.jpg?time=${sponsorDate}`;
  const thumbPlace = `${URI.bucket}/${placeId}/theming/${theme}/loading-small.jpg?time=${sponsorDate}`;

  const imageSponsor = `${URI.bucket}/__theming__/${theme}/loading.jpg?time=${sponsorDate}`;
  const thumbSponsor = `${URI.bucket}/__theming__/${theme}/loading-small.jpg?time=${sponsorDate}`;

  const image = hasSponsorTheme ? imageSponsor : imagePlace;
  const thumb = hasSponsorTheme ? thumbSponsor : thumbPlace;

  const onError = (e) => {
    setErrorImage(true);
  };

  if (!loading) {
    return null;
  }

  return (
    <div className="p-abs p-full H100v f-c f-dirc f-jcfe f-aic bg-black">
      <div className="p-rel br-8 p-16 fds-1 f-c f-dirc f-jcc f-aic l12" style={{ bottom: 16 }}>
        <BarLoader height={2} width={120} color="#ffffff" loading={true} />
        {/* <p className="f2b f07 ta-c mt-4">{placeId.toUpperCase()}</p> */}
      </div>
      {image && !errorImage ? (
        <ProgressiveImage src={image} placeholder={thumb}>
          {(src) => {
            return (
              <img
                className="p-abs p-full WH100 H100v of-cover l11"
                src={src}
                onError={onError}
                onLoad={() => {
                  setImageLoaded(true);
                }}
                style={{ filter: !imageLoaded ? 'blur(4px)' : 'none' }}
              />
            );
          }}
        </ProgressiveImage>
      ) : null}
    </div>
  );
};

export default Sponsor;
