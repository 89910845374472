/* eslint-disable react/no-children-prop */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

const parseCookie = (str) => {
  try {
    const parsedCookies = str
      .split(';')
      .map((v) => v.split('='))
      .reduce((acc, v) => {
        try {
          acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(v[1]?.trim());
          return acc;
        } catch (error) {
          console.log('ERROR PARSING COOKIE ACCUMULATOR', error);
          return acc;
        }
      }, {});
    return parsedCookies;
  } catch (error) {
    console.log('ERROR PARSING COOKIE', error);
    return {};
  }
};

const initialCookies = parseCookie(document.cookie);

const GdprOverlay = () => {
  const placeContext = useContext(PlaceContext);
  const [expandGDPR, setExpandGDPR] = useState(false);

  const { t } = useTranslation();

  const {
    data: { gdpr },
    loading: isLoading,
  } = placeContext;

  return (
    <>
      {!isLoading && gdpr && !expandGDPR && !initialCookies['CookieConsent'] && (
        <div
          className="p-fix p-bottom f-c f-jcsb f-aic mc-lr-2x l12"
          style={{ bottom: 65, height: 64 }}
        >
          <div />
          <button
            className="pe-all bg-gdpr-button p-8 br-4 no-border no-outline no-select cursor-default"
            onClick={() => setExpandGDPR(true)}
          >
            <h3 className="f2b f08 txt-gdpr-button">{t('gdpr.view')}</h3>
          </button>
        </div>
      )}
      {!isLoading && gdpr && expandGDPR && (
        <CookieConsent
          location="top"
          buttonText={t('gdpr.accept_all')}
          cookieName="CookieConsent"
          sameSite="strict"
          style={{
            margin: '0 15px',
            marginTop: 'calc(95px + 5vw)',
            width: 'calc(100vw - 10vw) !important',
          }}
          overlay
          acceptOnScroll
          acceptOnScrollPercentage={1}
          containerClasses="f-c f-jcc pe-all txt-gdpr-container bg-gdpr-container"
          buttonClasses="gdpr-decision-button"
          buttonWrapperClasses="f-c mc"
          expires={150}
        >
          <p className="mt-4">{t('gdpr.message.cookie_usage')}</p>
          <p>{t('gdpr.message.cookie_accept')}</p>
          <div className="f06 mt-4 ml-8">
            <p className="f2b">{t('gdpr.message.cookies')}</p>
            <p>{t('gdpr.message.ga')}</p>
            <p>{t('gdpr.message.location')}</p>
            <p>{t('gdpr.message.point')}</p>
            <p>{t('gdpr.message.mode')}</p>
            <p>{t('gdpr.message.identifier')}</p>
          </div>
          <p>{t('gdpr.message.warning')}</p>
        </CookieConsent>
      )}
    </>
  );
};

export { GdprOverlay };
