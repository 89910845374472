import React, { useContext } from 'react';
import Header from '../interface/components/Header';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { PlaceContext } from '../../core/contexts/PlaceContext';

const NutritionPage = () => {
  const history = useHistory();
  const placeContext = useContext(PlaceContext);
  const {
    data: {
      place: { placeId },
    },
  } = placeContext;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  if (window.scrollY !== 0) {
    scrollToTop();
  }

  const closePage = () => {
    history.push('/');
  };

  const bucketUrl =
    process.env.REACT_APP_URI === 'production'
      ? 'unitpay-photobucket'
      : 'unitpay-staging-photobucket';

  return (
    <div className="bg-app">
      <Header />
      <div className="p-fix p-top-right pc-lr mt-32 l5">
        <CloseIcon
          size={24}
          className="br-f mt-8 p-8"
          onClick={closePage}
          style={{ marginRight: -8 }}
        />
      </div>
      <div className="f2 f09" style={{ paddingTop: 84 }}>
        <embed
          src={`https://drive.google.com/viewerng/viewer?embedded=true&url=https://storage.googleapis.com/${bucketUrl}/${placeId}/nutrition/nutrition.pdf?time=${new Date().valueOf()}`}
          width="100%"
          height={Math.round(window.innerHeight * 0.74)}
        />
      </div>
    </div>
  );
};

// https://drive.google.com/viewerng/viewer?url=https://storage.googleapis.com/unitpay-staging-photobucket/friendspub/nutrition/nutrition.pdf?time%3D1679049157298

export default NutritionPage;
