import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ToppingsModalOrdering from './ToppingsModalOrdering';

import { RemoveItemSmall, RemoveCancelItem } from '../../../lib/reusable/AddRemoveButtons';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { OrderStoreContext } from '../../orders/contexts/OrderStoreContext';

const gradientClasses = [
  'bg-gradient-1',
  'bg-gradient-2',
  'bg-gradient-3',
  'bg-gradient-4',
  'bg-gradient-5',
];

const ToppingPerProduct = ({
  index,
  product,
  order,
  data,
  openToppingsModal,
  confirm,
  historyIndex,
}) => {
  const [state, dispatch] = useContext(OrderStoreContext);
  const {
    data: {
      place: { placeId },
      cache: {
        toppings: { toppingId__topping },
      },
    },
  } = useContext(PlaceContext);
  const { t } = useTranslation();

  const hasToppings =
    historyIndex !== undefined
      ? state.orders.history[historyIndex].products[product.id] &&
        state.orders.history[historyIndex].products[product.id][index] &&
        state.orders.history[historyIndex].products[product.id][index].toppings &&
        Object.keys(state.orders.history[historyIndex].products[product.id][index].toppings)
          .length > 0
      : state.orders.active.products[product.id] &&
        state.orders.active.products[product.id][index] &&
        state.orders.active.products[product.id][index].toppings &&
        Object.keys(state.orders.active.products[product.id][index].toppings).length > 0;

  let showToppings = [];
  const selectedToppingsId =
    historyIndex !== undefined
      ? state.orders.history[historyIndex].products[product.id][index].toppings
      : state.orders.active.products[product.id][index].toppings;

  if (hasToppings) {
    for (const toppingId in selectedToppingsId) {
      toppingId__topping[toppingId] !== undefined &&
        showToppings.push(toppingId__topping[toppingId]);
    }
  }

  const showList = !order || showToppings.length > 0;

  const removeProductFromOrderByIndex = (event) => {
    event.stopPropagation();
    dispatch({
      type: 'REMOVE_PRODUCT_INDEX',
      payload: { productId: product.id, index, placeId },
    });
  };

  const handleOpenToppings = () => {
    openToppingsModal(index, data);
  };

  const newColorClassIndex = Math.floor(Math.random() * gradientClasses.length);
  const colorClass = gradientClasses[newColorClassIndex];

  // const color = historyIndex !== undefined ? `${COLORS.I_TEXT}60` : COLORS.I_TEXT;

  if (confirm && showList)
    return (
      <div className="ml-8">
        <div className="f-c f-aic mt-4">
          <h3 className="f2 f06 p0 ml-4">{`${product.name} - ${index + 1}`}</h3>
        </div>
        <ul className="f2b mt-4 mb-8 ml-32">
          {showToppings.map((topping) => (
            <li key={topping.id}>
              <div className="f-c f-1 f-jcsb f-aic">
                <h3 className="f2b f07 p0 ml-4">{topping.name}</h3>
                <h3 className="f2b f07 p0 ml-4 ta-r">{`${topping.cost} lei`}</h3>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );

  if (!order) {
    return (
      <div className={`bt-app ${colorClass} ${showList ? 'p-8' : 'p0'}`}>
        <h3 className="f2b f08 p0 ml-4">{`${product.name} - ${index + 1}`}</h3>
        <div className="f-c f-aic mt-8">
          <div className="p-rel f-jcfe f-asfe p-8">
            <RemoveItemSmall action={removeProductFromOrderByIndex} />
          </div>
          <div
            className="f-none ta-c p-4 ml-4 br-4 bg-toppings-product-add txt-toppings-product-add"
            style={{ width: 200 }}
            onClick={handleOpenToppings}
          >
            {showToppings.length > 0
              ? t('interface.orders.change_toppings')
              : t('interface.orders.add_toppings')}
          </div>
        </div>
        <ul className="mt-4" onClick={handleOpenToppings}>
          {showToppings.map((topping) => (
            <li key={topping.id}>
              <div className="f-c f-jcsb f-aic">
                <p className="f2b f08">{topping.name}</p>
                <p className="f2b f08 ta-r">{`${topping.cost} lei`}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (showToppings.length === 0) {
    return null;
  }

  return (
    <div className={`bt-app ${showList ? 'pt-8 pb-4 pl-16' : 'p0'}`}>
      <div className="f-c f-aic">
        <RemoveCancelItem type="info" action={removeProductFromOrderByIndex} />
        <h3 className="f2b f06 p0 ml-4">{`${product.name} - ${index + 1}`}</h3>
      </div>
      <ul className="mt-4" onClick={handleOpenToppings}>
        {showToppings.map((topping) => (
          <li key={topping.id}>
            <div className="f-c f-jcsb f-aic">
              <p className="f2b f08">{topping.name}</p>
              <p className="f2b f08 ta-r">{`${topping.cost} lei`}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Toppings = (props) => {
  const { product, order, index: historyIndex, confirm } = props;
  const [state] = useContext(OrderStoreContext);
  const [toppingsVisible, setToppingsVisible] = useState(false);
  const [position, setPosition] = useState(0);
  const [toppingsOnPosition, setToppingsOnPosition] = useState([]);
  const {
    data: {
      cache: {
        toppings: { productId__toppingsGroupId },
      },
    },
  } = useContext(PlaceContext);

  const productHasToppings =
    historyIndex !== undefined
      ? state.orders.history[historyIndex].products[product.id] &&
        productId__toppingsGroupId[product.id] !== undefined
      : state.orders.active.products[product.id] &&
        productId__toppingsGroupId[product.id] !== undefined;

  if (!productHasToppings) {
    return null;
  }

  const openToppingsModal = (index, data) => {
    setPosition(index);
    setToppingsOnPosition(data.toppings);
    setToppingsVisible(true);
  };

  const closeToppingsModal = () => {
    setToppingsVisible(false);
  };

  const toppingList =
    historyIndex !== undefined &&
    Array.isArray(state.orders.history[historyIndex].products[product.id])
      ? state.orders.history[historyIndex].products[product.id]
      : state.orders.active.products[product.id];

  if (confirm) {
    return (
      <>
        {toppingList.map((data, index) => {
          return (
            <ToppingPerProduct
              key={index}
              product={product}
              data={data}
              index={index}
              confirm={confirm}
              historyIndex={historyIndex}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      {toppingList.map((data, index) => {
        return (
          <ToppingPerProduct
            key={index}
            product={product}
            data={data}
            index={index}
            openToppingsModal={openToppingsModal}
            order={order}
          />
        );
      })}
      <ToppingsModalOrdering
        isVisible={toppingsVisible}
        closeMenu={closeToppingsModal}
        productId={product.id}
        position={position}
        toppingsOnPosition={toppingsOnPosition}
      />
    </>
  );
};

export default Toppings;
