import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
  AddBox as AddBoxIcon,
} from '@mui/icons-material';

export const IWantItem = (props) => {
  const { action, disabled } = props;

  const { t } = useTranslation();

  if (disabled) {
    return <div className="f-1" />;
  }

  return (
    <button
      className="f-c f-1 f-aic p-4 m0 no-select cursor-default bg-danger fds-1 br-4"
      onClick={action}
    >
      <div className="f-c f-dirc f-jcc f-aic">
        <span className="f2b f09 txt-white p-4">{`${t('product.add')}`}</span>
      </div>
    </button>
  );
};

export const AddItem = (props) => {
  const { action, disabled } = props;

  if (disabled) {
    return <div className="f-1" />;
  }

  return (
    <button className="f-c f-1 no-border m0 no-select cursor-default" onClick={action}>
      <AddBoxIcon className="s-32-i txt-danger mp0 bg-white fds-1" />
    </button>
  );
};

export const RemoveItem = (props) => {
  const { action, disabled } = props;

  if (disabled) {
    return <div className="f-1" />;
  }

  return (
    <button className="f-c f-1 no-border m0 no-select cursor-default" onClick={action}>
      <IndeterminateCheckBoxIcon className="s-32-i txt-danger bg-white mp0 fds-1" />
    </button>
  );
};

export const RemoveItemSmall = (props) => {
  const { action, disabled } = props;

  if (disabled) {
    return <div className="f-1" />;
  }

  return (
    <button className="f-c f-1 no-border p-4 m0 no-select cursor-default" onClick={action}>
      <RemoveCircleIcon className="f12 txt-danger mp0 bg-white br-f fds-1" />
    </button>
  );
};

export const CancelItems = (props) => {
  const { action, disabled } = props;

  if (disabled) {
    return <div className="f-1" />;
  }

  return (
    <button className="f-c f-1 no-border p-4 m0 no-select cursor-default" onClick={action}>
      <RemoveCircleIcon className="f20 txt-danger mp0 bg-white br-f fds-1 r-45" />
    </button>
  );
};

export const RemoveCancelItem = (props) => {
  const { action, type, cancel } = props;

  const classes = {
    danger: 'txt-primary-danger',
    info: 'txt-app-dimer',
  };

  const classNames = `f-c f-0 no-border mr-4 p-3 ${classes[type]}`;

  if (cancel) {
    return (
      <div className={classNames} onClick={action}>
        <AddCircleIcon className="f10 mp0 br-f no-border r-45" />
      </div>
    );
  }

  return (
    <div className={classNames} onClick={action}>
      <RemoveCircleOutlineIcon className="s-24-i mp0 br-f no-border" />
    </div>
  );
};
