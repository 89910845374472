import React from 'react';
import Header from '../interface/components/Header';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';

const Cookies = () => {
  const history = useHistory();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  if (window.scrollY !== 0) {
    scrollToTop();
  }

  const closePage = () => {
    history.push('/');
  };

  return (
    <div className="bg-app">
      <Header />
      <div className="p-fix p-top-right pc-lr mt-32 l5">
        <CloseIcon
          size={24}
          className="br-f mt-8 p-8"
          onClick={closePage}
          style={{ marginRight: -8 }}
        />
      </div>
      <div className="f2 f09 minH100v pc-lr-2x" style={{ paddingTop: 84 }}>
        <h3>POLITICA FISIERELOR COOKIE</h3>
        <p>1. Politica de utilizare Cookie-uri si tehnologii similare</p>
        <p>
          Aceasta politica se refera la cookie-urile si la tehnologiile similare folosite, dupa caz,
          in website-urile si aplicatiile operate de UNITPAY SYSTEMS SRL cu sediul social in strada
          Ion Minulescu 95, camera 2, Bucuresti.
        </p>
        <p>2. Ce sunt Cookie-urile?</p>
        <p>
          Cookie-ul este un fisier de mici dimensiuni, format din litere si numere, care va fi
          stocat pe computerul, terminalul mobil sau alte echipamente ale unui utilizator de pe care
          se acceseaza internetul. Cookie-ul este instalat prin solicitarea emisa de catre
          terminalul utilizatorului catre un server UNIPAY sau catre un server al unei terte parti.
        </p>
        <p>3. La ce sunt folosite Cookie-urile?</p>
        <p>
          Aceste fisiere fac posibila in principal recunoasterea terminalului utilizatorului si
          prezentarea continutului intr-un mod relevant, adaptat preferintelor utilizatorului.
          Cookie-urile asigura utilizatorilor o experienta placuta de navigare si sustin eforturile
          noastre pentru a oferi servicii&nbsp;adaptate utilizatorilor, ex:&nbsp;preferintele in
          materie de confidentialitate online, cosul de cumparaturi sau publicitate relevanta. De
          asemenea, sunt utilizate in pregatirea unor statistici anonime agregate care ne ajuta sa
          intelegem cum un utilizator beneficiaza de paginile noastre de internet, permitandu-ne
          imbunatatirea structurii si continutului lor, fara a permite identificarea personala a
          utilizatorului.
        </p>
        <p>4. Ce Cookie-uri folosim?</p>
        <p>
          Folosim doua tipuri de cookie-uri: per sesiune si fixe. Cookie-urile per sesiune&nbsp;sunt
          fisiere temporare ce raman in terminalul utilizatorului pana la terminarea sesiunii sau
          inchiderea aplicatiei. Cookie-urile fixe raman pe terminalul utilizatorului pe o perioada
          determinata de parametrii cookie-ului sau pana sunt sterse manual de utilizator.
          <br />
          &nbsp;
          <br />
          5. Cum sunt folosite cookie-urile de catre acest site?
        </p>
        <p>O vizita pe acest site poate plasa urmatoarele tipuri de cookie-uri:</p>
        <p>- cookie-uri strict necesare pentru functionarea site-ului</p>
        <p>- cookie-uri de analiza</p>
        <p>- cookie-uri pentru publicitate</p>
        <p>
          Cookie-urile si/sau tehnologiile similare strict necesare sunt esentiale pentru buna
          functionare a site-ului, fiind setate pe dispozitivul dumneavoastra la accesarea site-ului
          sau in urma actiunilor efectuate in site, dupa caz. Puteti seta browser-ul dumneavoastra
          pentru a bloca cookie-urile, insa in acest caz anumite sectiuni ale site-ului nu vor
          functiona corect.
        </p>
        <p>Celelalte categorii de cookie-uri au rolul indicat la sectiunea 12.</p>
        <p>6. Contin Cookie-urile date personale?</p>
        <p>
          Cookie-urile sau tehnologiile similare in sine nu solicita informatii cu caracter personal
          pentru a putea fi utilizate si, in multe cazuri, nici nu identifica personal utilizatorii
          de internet. Exista insa situatii cand datele personale pot fi colectate prin utilizarea
          cookie-urilor pentru a facilita anumite functionalitati pentru utilizator sau pentru a
          oferi utilizatorului o experienta adaptata preferintelor sale. Astfel de date sunt
          criptate intr-un mod care face imposibil accesul persoanelor neautorizate la ele.
        </p>
        <p>7. Ce sunt tehnologiile similare?</p>
        <p>Exista alte tehnologii care pot fi utilizate in aceleasi scopuri precum cookie-urile.</p>
        <p>
          Site-ul digital-menu.ro utilizeaza ca tehnologie similara Local Storage care permite
          stocarea de informatie pe terminal (calculator, telefon mobil, etc).
        </p>
        <p>8. Blocarea Cookie-urilor</p>
        <p>
          Cele mai multe browsere sunt setate implicit sa accepte cookie-uri, dar aveti
          posibilitatea sa modificati setarile pentru a bloca unele sau toate cookie-urile.
        </p>
        <p>
          In cazul in care doriti sa blocati cookie-urile, unele functionalitati ale site-ului vor
          fi oprite, iar acest lucru poate genera anumite disfunctionalitati sau erori in folosirea
          site-ului nostru. De exemplu, blocarea cookie-urilor va poate impiedica sa:
        </p>
        <p> - va autentificati in contul dumneavoastra</p>
        <p> - cumparati online</p>
        <p>
          In cazul in care esti de acord cu aceste limitari si doresti sa blochezi cookie-urile,
          urmeaza instructiunile de mai jos.
        </p>
        <p>
          Alege browser-ul tau din lista de mai jos pentru a afisa instructiunile pe care trebuie sa
          le parcurgeti dupa deschiderea browser-ului.
        </p>
        <p>Microsoft Internet Explorer 9 (sistem de operare Windows 7)</p>
        <p>&nbsp;- fa click pe pictograma Setari(dreapta sus)</p>
        <p>&nbsp;- fa click pe Optiuni Internet</p>
        <p>&nbsp;- click pe Confidentialitate si apoi alege optiunile</p>
        <p>&nbsp;- click pe Aplicare si apoi pe OK pentru salvarea preferintelor</p>
        <p>Google Chrome</p>
        <p>&nbsp;- fa click pe pictograma cheie si selecteaza Optiuni din lista</p>
        <p>&nbsp;- click pe tabul &bdquo;Setari avansate&rdquo;</p>
        <p>&nbsp;- fa click pe Setari privind continutul din sectiunea Confidentialitate.</p>
        <p>&nbsp;- alege setarile pe care le doresti</p>
        <p>&nbsp;- click pe X pentru a inchide</p>
        <p>Safari</p>
        <p>&nbsp;- alege Preferinte</p>
        <p>&nbsp;- fa click pe panoul de confidentialitate</p>
        <p>&nbsp;- alege setarile pe care le doresti</p>
        <p>&nbsp;- click pe X pentru a inchide</p>
        <p>Mozilla Firefox</p>
        <p>&nbsp;- selecteaza Instrumente din bara principala, apoi Optiuni</p>
        <p>&nbsp;- click pe tabul Confidentialitate</p>
        <p>&nbsp;- alege setarile pe care le doresti</p>
        <p>&nbsp;- click pe OK pentru a inchide</p>
        <p>
          Setarile de mai sus sunt specifice fiecarui browser si compania noastra nu este
          responsabila pentru modul de functionare al acestora.
        </p>
        <p>9.&nbsp;Administrarea preferintelor cu privire la plasarea de cookie-uri</p>
        <p>
          In general, o aplicatie folosita pentru accesarea paginilor web permite salvarea
          cookie-urilor si/sau a tehnologiilor similare pe terminal in mod implicit. Acestea sunt
          stocate pe perioade descrise in tabelul de la Sectiunea 12&nbsp;de mai jos. Aceste setari
          pot fi schimbate in asa fel incat administrarea automata a cookie-urilor sa fie blocata de
          browser-ul web sau utilizatorul sa fie informat de fiecare data cand cookie-uri sunt
          trimise catre terminalul sau. Informatii detaliate despre posibilitatile si modurile de
          administrare a cookie-urilor pot fi gasite in zona de setari a aplicatiei (browser-ului
          web). Limitarea folosirii cookie-urilor poate afecta anumite functionalitati ale
          website-ului.
        </p>
        <p>10. De ce sunt cookie-urile importante pentru Internet?</p>
        <p>
          Cookie-urile si/sau tehnologiile similare reprezinta un punct central al functionarii
          eficiente a Internetului, ajutand la generarea unei experiente de navigare prietenoase si
          adaptate preferintelor si intereselor fiecarui utilizator. Refuzarea sau dezactivarea
          cookie-urilor poate face unele site-uri sau parti ale site-urilor imposibil de folosit.
        </p>
        <p>
          Dezactivarea cookie-urilor nu inseamna ca nu veti mai primi, cu respectarea legislatiei,
          publicitate online &ndash; ci doar ca aceasta nu va mai putea tine cont de preferintele si
          interesele dvs., evidentiate prin comportamentul de navigare.
          <br />
          Exemple de intrebuintari importante ale cookie-urilor (care nu necesita autentificarea
          unui utilizator prin intermediul unui cont):
        </p>
        <p>
          &nbsp;- Continut si servicii adaptate preferintelor utilizatorului &ndash; categorii de
          produse si servicii.
        </p>
        <p>&nbsp;- Oferte adaptate intereselor utilizatorilor</p>
        <p>&nbsp;- Retinerea parolelor</p>
        <p>
          &nbsp;- Retinerea filtrelor de protectie a copiilor privind continutul pe Internet
          (optiuni family mode, functii de safe search).
        </p>
        <p>
          &nbsp;- Limitarea frecventei de difuzare a reclamelor &ndash; limitarea numarului de
          afisari a unei reclame pentru un anumit utilizator pe un site.
        </p>
        <p>- Furnizarea de publicitate relevanta pentru utilizator.</p>
        <p>
          - Masurarea, optimizarea si adaptarea caracteristicilor de analiza &ndash; cum ar fi
          confirmarea unui anumit nivel de trafic pe un website, ce tip de continut este vizualizat
          si modul cum un utilizator ajunge pe un website (ex: prin motoare de cautare, direct, din
          alte website-uri etc.). Website-urile deruleaza aceste analize privitoare la utilizarea
          lor pentru a-si imbunatati serviciile in beneficiul utilizatorilor.
        </p>
        <p>11.&nbsp;Securitate si aspecte legate de confidentialitate</p>
        <p>
          In general browserele au integrate setari de confidentialitate care furnizeaza diferite
          niveluri de acceptare a cookie-urilor, perioada de valabilitate si stergere automata dupa
          ce utilizatorul a vizitat un anumit site.
        </p>
        <p>Alte aspecte de securitate legate de cookie-uri:</p>
        <p>
          - Particularizarea setarilor browserului in ceea ce priveste cookie-urile pentru a
          reflecta un nivel confortabil pentru dumneavoastra al securitatii utilizarii
          cookie-urilor.
        </p>
        <h4>UNITPAY</h4>
      </div>
      <div style={{ height: 256 }} />
    </div>
  );
};

export default Cookies;
