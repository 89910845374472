import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import ProductCard from './ProductCard';
import EventsPlacer from '../../visuals/EventsPlacer';

import { RAFContext } from '../../../core/contexts/RAFContext';
import { SearchStoreContext } from '../../search/contexts/SearchStoreContext';
import { PlaceContext } from '../../../core/contexts/PlaceContext';

const itemsToDisplay = 100;

const ProductsList = ({ products, productClick, searchingMode, categoryId }) => {
  const [searchState] = useContext(SearchStoreContext);
  const { searching } = searchState;

  const { isMobile } = useContext(RAFContext);

  const {
    data: {
      cache: { events },
    },
  } = useContext(PlaceContext);

  const safeProducts = products.filter((tag) => Boolean(tag));
  let chunked = _.chunk(safeProducts, itemsToDisplay);
  const [currentProducts, setCurrentTags] = useState({
    index: 0,
    data: chunked,
    products: chunked[0],
    renderLength: itemsToDisplay,
    hasMore: chunked[1] ? true : false,
    withCategories: false,
  });

  useEffect(() => {
    setCurrentTags({
      index: 0,
      data: chunked,
      products: chunked[0],
      renderLength: itemsToDisplay,
      hasMore: chunked[1] ? true : false,
      withCategories: false,
    });
  }, [products]);

  if (
    currentProducts.data &&
    currentProducts.data.length > 0 &&
    (currentProducts.products === undefined || currentProducts.products.length === 0)
  ) {
    setCurrentTags({
      index: 0,
      data: [],
      products: chunked[0],
      renderLength: itemsToDisplay,
      hasMore: itemsToDisplay < products.length,
    });
  }

  const fetchData = () => {
    const { index, products, data } = currentProducts;
    const newIndex = index + 1;
    const oldTags = _.flatten(products);
    const nextTags = currentProducts.data[newIndex];
    setCurrentTags({
      index: newIndex,
      data,
      products: [...oldTags, ...nextTags],
      renderLength: itemsToDisplay + itemsToDisplay * newIndex,
      hasMore: data[newIndex + 1] ? true : false,
    });
  };

  const noSearch =
    searchingMode && typeof searching === 'string' && !(searching?.trim().length > 2);

  if (noSearch) {
    return <div className="ta-c mt-8 p-32">Search any product by name or tag.</div>;
  }

  const noProductsFound =
    !noSearch && (currentProducts.products === undefined || currentProducts.products.length === 0);

  if (noProductsFound) {
    return (
      <div className="ta-c mt-8 p-32">No products has been found, search for a name or a tag.</div>
    );
  }

  const hasAProductWithEvent =
    events?.products?.[categoryId] && Object.keys(events?.products?.[categoryId])?.length > 0;

  return (
    <div className="f-c f-w pt-8 pr-8 pb-16 pl-8" style={{ marginTop: -32 }}>
      <InfiniteScroll
        dataLength={currentProducts.renderLength}
        next={fetchData}
        hasMore={currentProducts.hasMore}
        initialScrollY={0}
        style={{ overflow: 'hidden !important', minWidth: 'calc(100vw - 33px)' }}
      >
        <Grid container spacing={isMobile ? 0 : 1}>
          {currentProducts.products.map((product, index) => {
            if (product?.delimiter) {
              return (
                <Grid key={product.id} item xs={12}>
                  <ProductCard
                    key={index}
                    product={product}
                    action={productClick}
                    calledFromCategoryId={categoryId}
                  />
                </Grid>
              );
            }

            return (
              <Grid key={`${product.id}-${index}`} item xs={12} sm={6} md={4} lg={3}>
                <div key={`${product.id}-${index}`} className="W100">
                  {hasAProductWithEvent && events?.products?.[categoryId]?.[product.id] && (
                    <div className="pt-16">
                      <EventsPlacer
                        menuPosition="products"
                        enforce="product"
                        productData={{ categoryId: product.category_id, productId: product.id }}
                      />
                    </div>
                  )}
                  <ProductCard
                    key={`${product.id}-${index}`}
                    product={product}
                    action={productClick}
                    calledFromCategoryId={categoryId}
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};

export default ProductsList;
