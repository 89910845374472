import React, { useContext, useEffect, useState } from 'react';
import * as FoodIcons from '../../../core/icons';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

export const Badges = ({ product }) => {
  const {
    data: {
      cache: {
        products: { productId__product },
      },
    },
  } = useContext(PlaceContext);

  const [badges, setBadges] = useState({});

  useEffect(() => {
    try {
      if (!productId__product[product.id]) return;

      const { tags } = productId__product[product.id];
      const lowerCaseTags = tags.map((tag) => tag.toLowerCase());

      if (tags) {
        const foundBadges = {};
        for (const tag of lowerCaseTags) {
          switch (tag) {
            case 'spicy':
              foundBadges.spicy = true;
              break;
            case 'vegan':
              foundBadges.vegan = true;
              break;
            case 'vegetarian':
              foundBadges.vegetarian = true;
              break;
            case 'turkey':
              foundBadges.turkey = true;
              break;
            case 'silver':
              foundBadges.silver = true;
              break;
            case 'seafood':
              foundBadges.seafood = true;
              break;
            case 'pork':
              foundBadges.pork = true;
              break;
            case 'popular':
              foundBadges.popular = true;
              break;
            case 'photo':
              foundBadges.photo = true;
              break;
            case 'gold':
              foundBadges.gold = true;
              break;
            case 'gluten_free':
              foundBadges.gluten_free = true;
              break;
            case 'frozen':
              foundBadges.frozen = true;
              break;
            case 'fish':
              foundBadges.fish = true;
              break;
            case 'duck':
              foundBadges.duck = true;
              break;

            case 'chicken':
              foundBadges.chicken = true;
              break;
            case 'chef':
              foundBadges.chef = true;
              break;
            case 'bronze':
              foundBadges.bronze = true;
              break;
            case 'beef':
              foundBadges.beef = true;
              break;
            case 'adults':
              foundBadges.adults = true;
              break;
            case 'new':
              foundBadges.new = true;

            default:
              break;
          }

          Object.keys(foundBadges).length && setBadges(foundBadges);
        }
      }
    } catch (error) {
      console.error('ERROR ON SPICY TAG', error);
    }
  }, [product, productId__product]);

  return (
    <div className="f-c W100 f-aic br-4" style={{ backgroundColor: '#ffffffA0' }}>
      <div style={{ width: 4 }} />
      {badges.spicy && <FoodIcons.GiChiliPepper size={20} color="red" />}
      {badges.vegan && <FoodIcons.Vegan size={22} color="green" />}
      {badges.vegetarian && <FoodIcons.Vegetarian size={22} color="green" />}
      {badges.adults && <FoodIcons.AdultsOnly size={22} />}
      {badges.beef && <FoodIcons.Beef size={22} />}
      {badges.chef && <FoodIcons.Chef size={22} />}
      {badges.chicken && <FoodIcons.Chicken size={22} />}
      {badges.bronze && <FoodIcons.MedalBronze size={22} />}
      {badges.duck && <FoodIcons.Duck size={22} />}
      {badges.frozen && <FoodIcons.Frozen size={22} />}
      {badges.fish && <FoodIcons.Fish size={22} />}
      {badges.gluten_free && <FoodIcons.Gluten size={22} />}
      {badges.gold && <FoodIcons.MedalGold size={22} />}
      {badges.new && <FoodIcons.NewProduct size={28} />}
      {badges.photo && <FoodIcons.Photo size={22} />}
      {badges.popular && <FoodIcons.Popular size={22} />}
      {badges.pork && <FoodIcons.Pork size={22} />}
      {badges.seafood && <FoodIcons.Seafood size={22} />}
      {badges.silver && <FoodIcons.MedalSilver size={22} />}
      {badges.turkey && <FoodIcons.Turkey size={22} />}
    </div>
  );
};
