import React, { useEffect } from 'react';
import { scrollToTop } from '../../core/functions/utilities';
import Order from '../../features/orders/Order';

const OrderPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return <Order />;
};
export default OrderPage;
