import React, { useEffect } from 'react';
import { scrollToTop } from '../../core/functions/utilities';

import Contact from '../../features/contact/Contact';

const ContactPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return <Contact />;
};

export default ContactPage;
