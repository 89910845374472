import i18next from 'i18next';

const handleNoConnection = (callDispatch, notificationDispatch, data) => {
  const { placeId, pointId, userId, userDevice } = data;
  window.__TIMEOUTS__.calls.initiated[userId] !== undefined &&
    clearTimeout(window.__TIMEOUTS__.calls.initiated[userId]);
  window.__TIMEOUTS__.calls.initiated[userId] = setTimeout(() => {
    const date = new Date().getTime();
    const message = i18next.t('calls.NOTIFICATIONS.unreachable');
    const newNotification = new CustomEvent('Notification', {
      detail: { message, type: 'danger' },
    });
    window.dispatchEvent(newNotification);
    notificationDispatch({
      type: 'ADD_NOTIFICATION',
      payload: {
        notificationId: 'call',
        message,
        placeId,
        pointId,
        userId,
        type: 'danger',
        date,
      },
    });
    callDispatch({
      type: 'CALL_NOT_RECEIVED',
      payload: { placeId, userId, pointId, userDevice },
    });
  }, 3000);
};

export const initiateCall = async (data, reducers, WSConnection) => {
  const { placeId, pointId, userId, userDevice, details } = data;
  const { callDispatch, notificationDispatch } = reducers;
  const payload = { placeId, userId, pointId, userDevice, details };

  callDispatch({
    type: 'INITIATE_CALL',
    payload,
  });

  const newCall = JSON.stringify({
    type: 'CALL_NEW',
    payload,
  });

  try {
    await WSConnection.send(newCall);
  } catch (error) {
    handleNoConnection(callDispatch, notificationDispatch, data);
    return;
  }
};
