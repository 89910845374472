import i18next from 'i18next';

const handleNoConnection = (orderDispatch, data) => {
  const { orderId } = data;
  window.__TIMEOUTS__.orders.initiated[orderId] !== undefined &&
    clearTimeout(window.__TIMEOUTS__.orders.initiated[orderId]);
  const message = i18next.t('orders.NOTIFICATIONS.error_on_cancel');
  const date = new Date().getTime();
  const newNotification = new CustomEvent('Notification', {
    detail: { message, type: 'danger', orderId, date },
  });
  window.dispatchEvent(newNotification);
};

export const sendCancelPendingOrder = async (data, reducers, WSConnection) => {
  const { orderId, pointId, userId, placeId } = data;
  const { orderDispatch } = reducers;
  const payload = {
    orderId,
    pointId,
    userId,
    placeId,
  };

  const cancelOrder = JSON.stringify({
    type: 'ORDER_CANCEL',
    payload,
  });

  if (!WSConnection) {
    handleNoConnection(orderDispatch, data);
    return;
  }

  try {
    await WSConnection.send(cancelOrder);
  } catch (error) {
    handleNoConnection(orderDispatch, data);
  }
};
