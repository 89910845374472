export const syncOrders = (state, payload) => {
  if (!Array.isArray(payload)) {
    return state;
  }

  const newState = _.cloneDeep(state);
  newState.orders.history = [];

  const incomingOrdersById = {};
  const orderDatesByOrderId = {};

  for (const order of payload) {
    if (!incomingOrdersById[order.order_id]) {
      incomingOrdersById[order.order_id] = [];
    }
    incomingOrdersById[order.order_id].push(order);
    orderDatesByOrderId[order.order_id] = new Date(order.created_at).valueOf();
  }

  const orderIds = Object.keys(orderDatesByOrderId);

  const orderIdDates = [];
  for (const orderId of orderIds) {
    orderIdDates.push({ orderId, date: orderDatesByOrderId[orderId] });
  }

  orderIdDates.sort((order1, order2) => order2.date - order1.date);

  const orderNoByOrderId = {};
  let index = 0;
  for (const order of orderIdDates) {
    orderNoByOrderId[order.orderId] = index;
    index += 1;
  }

  for (const orderId in incomingOrdersById) {
    // cost: undefined
    // date: 1637672227087
    // discount: 0
    // orderCode: "11-B8EE"
    // orderId: "b8ee2611-4cad-447e-b980-4fd9d9708bad"
    // orderNo: 1
    // products: {bb51eb98-abf0-4ff4-82ac-8d3cdc90ce58: Array(1)}
    // status: "pending"

    const productsData = incomingOrdersById[orderId];
    const [data] = productsData;

    const productsById = {};
    for (const product of productsData) {
      if (!productsById[product.product_id]) {
        productsById[product.product_id] = [];
      }
      productsById[product.product_id].push({
        toppings: product.toppings_ids,
      });
    }

    let status = 'pending';

    if (data.inprogress) {
      status = 'approved-delivery';
    }

    if (data.confirmed) {
      status = 'approved';
    }

    if (data.rejected) {
      status = 'rejected';
    }

    const newOrder = {
      date: orderDatesByOrderId[data.order_id],
      discount: 0,
      orderCode: `${data.point_id}-${data.order_id.substring(0, 4)}`.toUpperCase(),
      orderId: data.order_id,
      orderNo: orderNoByOrderId[data.order_id],
      products: productsById,
      status,
    };

    newState.orders.history.push(newOrder);
  }

  return newState;
};

// assignee: null
// confirmed: null
// created_at: "2021-11-23T12:30:04.230Z"
// details: null
// discount: 0
// id: "9b6e108d-fca1-42be-9928-2186c79b645c"
// order_id: "237b8fde-f492-4a9b-b226-9cc8df864cb5"
// payment: null
// place_id: "friendspub"
// point_id: "11"
// product_id: "bb51eb98-abf0-4ff4-82ac-8d3cdc90ce58"
// rejected: null
// services: {bfff14b7-1ea2-4d12-8869-e1aaaf258d32: 'requested', 4c2f94f2-2fa2-4207-9097-b9eb38347547: 'requested'}
// toppings_ids: []
// updated_at: "2021-11-23T12:30:04.230Z"
// user_device: "device"
// user_id: "231a2ffe-68dc-49f9-94ac-a805371507b2"
