import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import BreadCrumbTrail from '../../bread-crumb-trail/BreadCrumbTrail';
import PhoneCalls from '../../phone-calls/PhoneCalls';
import Category from './Category';
import CategoriesList from './CategoriesList';
import InactivePlace from './InactivePlace';
import NoService from './NoService';
import EventsPlacer from '../../visuals/EventsPlacer';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

const Service = ({ serviceId }) => {
  const history = useHistory();
  const {
    data: {
      place: { active },
      cache: {
        events,
        services: { serviceId__categories, serviceId__service },
        categories: { categoryId__category },
      },
    },
  } = useContext(PlaceContext);

  const [category1x, setCategory1x] = useState([]);
  const [service, setService] = useState(null);

  useEffect(() => {
    if (serviceId__service[serviceId]) {
      setService(serviceId__service[serviceId]);
    }

    if (!serviceId__categories[serviceId]) {
      setCategory1x(null);
      return;
    }

    setCategory1x(serviceId__categories[serviceId]);
  }, [categoryId__category, serviceId, serviceId__categories, serviceId__service]);

  const category1xRef = useRef(category1x);

  if (!serviceId__service[serviceId]) {
    return null;
  }

  if (!active) {
    return <InactivePlace />;
  }

  const categoryClick = ({ categoryId }) => {
    const uri = `/category/${categoryId}`;
    history.push(uri);
  };

  const hasOneService = Array.isArray(category1x) && category1x.length === 1;
  const hasCategories = Array.isArray(category1x) && category1x.length > 1;
  let categoryId = null;
  try {
    if (hasOneService) {
      const [category] = category1x;
      categoryId = category.id;
    }
  } catch (error) {
    console.error(error);
  }

  const hasServicesEvents = Object.values(events?.services).length > 0;

  return (
    <>
      <PhoneCalls serviceId={serviceId} />
      <div className="p-rel minH100">
        <div
          className="p-fix p-top m0 pl-4 bg-app-nav fds-3 l3"
          style={{ backdropFilter: 'blur(2px)' }}
        >
          <BreadCrumbTrail />
        </div>
        <div className="pt-8" style={{ height: 32 }} />
        {hasServicesEvents && (
          <>
            <div style={{ maxWidth: 600 }}>
              <EventsPlacer menuPosition="services" enforce="top" />
            </div>
          </>
        )}
        {hasOneService && categoryId ? (
          <Category categoryId={categoryId} page={false} noCalls />
        ) : hasCategories ? (
          <CategoriesList x1={category1x || []} categoryClick={categoryClick} />
        ) : null}
        {category1xRef.current && !category1x && <NoService service={service} />}
        {hasServicesEvents && (
          <>
            <div style={{ maxWidth: 600 }}>
              <EventsPlacer menuPosition="services" enforce="bottom" />
            </div>
          </>
        )}
        <div className="minH150" />
      </div>
    </>
  );
};
export default Service;
