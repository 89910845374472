export const callRequestCancel = async (data, reducers, WSConnection) => {
  const { placeId, pointId, userId, userDevice } = data;
  // const { notificationDispatch } = reducers;
  const newMessage = JSON.stringify({
    type: 'CALL_CANCEL',
    payload: { placeId, userId, pointId, userDevice },
  });
  try {
    await WSConnection.send(newMessage);
  } catch (error) {
    console.error('Could not send call');
  }

  try {
    window.__TIMEOUTS__.calls.pending[userId] !== undefined &&
      clearTimeout(window.__TIMEOUTS__.calls.pending[userId]);
  } catch (error) {
    console.error('CANNOT CLOSE CALL!');
  }

  // window.__TIMEOUTS__.calls.pending[userId] = setTimeout(() => {
  //   const message = 'Nu s-a putut anula solicitarea la masa. Reincercati.';
  //   const date = new Date().getTime();
  //   const newNotification = new CustomEvent('Notification', {
  //     detail: { message, type: 'danger' },
  //   });
  //   window.dispatchEvent(newNotification);
  //   notificationDispatch({
  //     type: 'UPDATE_NOTIFICATION',
  //     payload: {
  //       notificationId: 'call',
  //       message,
  //       placeId,
  //       pointId,
  //       type: 'danger',
  //       date,
  //     },
  //   });
  // }, 10000);
};
