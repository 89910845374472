import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import CategoriesList from './CategoriesList';
import InactivePlace from './InactivePlace';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

const ServiceCategoriesList = (props) => {
  const { serviceId } = props;
  const history = useHistory();

  const {
    data: {
      place: { active },
      cache: {
        services: { serviceId__categories, serviceId__service },
        categories: { categoryId__category },
      },
    },
  } = useContext(PlaceContext);

  const [category1x, setCategory1x] = useState([]);
  const [service, setService] = useState(null);

  useEffect(() => {
    if (serviceId__service[serviceId]) {
      setService(serviceId__service[serviceId]);
    }

    if (!serviceId__categories[serviceId]) {
      setCategory1x(null);
      return;
    }

    setCategory1x(serviceId__categories[serviceId]);
  }, [categoryId__category, serviceId__categories, serviceId__service]);

  const category1xRef = useRef(category1x);

  if (!active) {
    return <InactivePlace />;
  }

  const categoryClick = ({ categoryId }) => {
    const uri = `/category/${categoryId}`;
    history.push(uri);
  };

  if (!category1x || !Array.isArray(category1x)) return null;
  const hasCategories = Array.isArray(category1x) && category1x.length > 0;

  return (
    <div className="m0 pb-16">
      <div className="f-c f-aic mt-8 mr-16 mb-8 ml-16" style={{ width: 'calc(100% - (--d32))' }}>
        {serviceId__service[serviceId]?.name && (
          <p className="f2b f07">{serviceId__service[serviceId].name.toUpperCase()}</p>
        )}
        <div
          className="f-c f-1 f-aic mt-16 mb-16 ml-16 bg-app-separator"
          style={{ width: 'calc(100% - var(--d32))', height: 1 }}
        />
      </div>
      {hasCategories && <CategoriesList x1={category1x || []} categoryClick={categoryClick} />}
      {category1xRef.current && !category1x && (
        <div className="f-c f-jcc f-aic" style={{ marginTop: 200 }}>
          <p>{`No ${service ? service.name : ''} at the moment`} </p>
        </div>
      )}
    </div>
  );
};
export default ServiceCategoriesList;
