import React, { useContext } from 'react';
import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { iframeExternalLink } from '../../../core/functions/utilities';

const Tripadvisor = () => {
  const {
    data: {
      place: { tripadvisor, inIframe },
    },
  } = useContext(PlaceContext);

  const goToTripadvisor = () => {
    iframeExternalLink(tripadvisor, inIframe);
  };

  if (!tripadvisor) return null;
  return (
    <div
      onClick={goToTripadvisor}
      className="ml-8 mr-4 br-8"
      // style={{ width: 25, height: 25, backgroundColor: '#70dda6' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-brand-tripadvisor"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        {' '}
        <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <circle cx="6.5" cy="13.5" r="1.5" />{' '}
        <circle cx="17.5" cy="13.5" r="1.5" />{' '}
        <path d="M17.5 9a4.5 4.5 0 1 0 3.5 1.671l1 -1.671h-4.5z" />{' '}
        <path d="M6.5 9a4.5 4.5 0 1 1 -3.5 1.671l-1 -1.671h4.5z" />{' '}
        <path d="M10.5 15.5l1.5 2l1.5 -2" /> <path d="M9 6.75c2 -.667 4 -.667 6 0" />{' '}
      </svg>
    </div>
  );
};

export default Tripadvisor;
