import axios from 'axios';
import { URI } from '../config';

export const syncCallsAndOrders = async (user, reducers, hasCalls, hasOrders) => {
  const { placeId, userId, pointId } = user;

  if (!placeId || !userId || !pointId) {
    console.error('NO PLACEID OR USERID OR POINTID');
    return;
  }

  const { callDispatch, orderDispatch } = reducers;

  try {
    const requestCalls = hasCalls ? '&hasCalls=true' : '';
    const requestOrders = hasOrders ? '&hasOrders=true' : '';
    const url = `${URI.sync}?placeId=${placeId}&userId=${userId}&pointId=${pointId}&type=sync${requestCalls}${requestOrders}`;
    const data = await axios.get(url);
    const {
      status,
      data: { calls, orders },
    } = data;

    if (status !== 200) {
      console.error('ERROR ON SYNC CALLS', status);
      return;
    }

    callDispatch({
      type: 'SYNC_CALLS',
      payload: calls,
    });

    orderDispatch({
      type: 'SYNC_ORDERS',
      payload: orders,
    });
  } catch (error) {
    console.error('ERROR ON SYNC CALLS', error);
    return;
  }
};
