import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';

import CategoryCard from './CategoryCard';

const CategoriesList = ({ x1, categoryClick }) => {
  const safeX1 = x1.filter((tag) => Boolean(tag));
  const itemsToDisplay = Math.ceil(safeX1.length);
  const data = safeX1.length === 0 ? [[]] : _.chunk(safeX1, itemsToDisplay);
  const [currentData, setCurrentData] = useState({
    index: 0,
    tags: data[0],
    renderLength: itemsToDisplay,
    hasMore: data[1] ? true : false,
  });

  useEffect(() => {
    const newData = safeX1.length === 0 ? [[]] : _.chunk(safeX1, itemsToDisplay);
    const current = {
      index: 0,
      tags: newData[0],
      renderLength: itemsToDisplay,
      hasMore: newData[1] ? true : false,
    };
    setCurrentData(current);
  }, [x1]);

  const fetchData = () => {
    const { index, tags } = currentData;
    const newIndex = index + 1;
    const current = {
      index: newIndex,
      tags: [...tags, ...data[newIndex]],
      renderLength: itemsToDisplay + itemsToDisplay * newIndex,
      hasMore: data[newIndex + 1] ? true : false,
    };
    setCurrentData(current);
  };

  if (currentData.tags === undefined || currentData.tags.length === 0) {
    return null;
  }

  if (currentData.tags.length === 1) {
    const [item] = currentData.tags;
    if (!item) return null;
    return (
      <div className="f-c f-1 f-jcc f-w pc-lr">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CategoryCard data={item} action={categoryClick} />
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="f-c f-1 f-jcc f-w pc-lr">
      <InfiniteScroll
        dataLength={currentData.renderLength}
        next={fetchData}
        hasMore={currentData.hasMore}
        style={{ overflow: 'hidden !important', minWidth: 'calc(100vw - 33px)' }}
      >
        <Grid container spacing={1}>
          {currentData.tags.map((item, index) => {
            return (
              <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                <CategoryCard data={item} action={categoryClick} />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};

export default CategoriesList;
