import React, { useContext } from 'react';
import { PlaceContext } from '../../../core/contexts/PlaceContext';

const BookinghamSvg = () => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45.41 27.94"
  >
    <g id="Layer_2-2" data-name="Layer 2-2">
      <path
        className="cls-1"
        d="m35.89,21.13l-2.8-4.36,5.26-2.8s-2.46,7.16-2.46,7.16Zm-13.2,2.79h-10.17l3.36-5.25,6.82,3.51,6.82-3.51,3.36,5.25h-10.19Zm-13.19-2.79l-2.47-7.16,5.25,2.8-2.78,4.36Zm13.19-13.41l4.7,7.3-4.7,2.51-4.7-2.51s4.7-7.3,4.7-7.3Zm8.16,5.59L22.7,0l-8.16,13.3L0,5.59l7.49,22.24,15.21.11,15.21-.11,7.5-22.24-14.54,7.71h-.02Z"
      />
    </g>
  </svg>
);

const Bookingham = () => {
  const {
    data: {
      place: { bookingham },
    },
  } = useContext(PlaceContext);

  const goToBookingham = () => {
    window.open(bookingham, '_blank');
  };

  if (!bookingham) return null;

  return (
    <div onClick={goToBookingham} className="ml-8 mr-4 mt-4" style={{ width: 24, height: 24 }}>
      <BookinghamSvg />
    </div>
  );
};

export default Bookingham;
