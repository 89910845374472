import i18next from 'i18next';

export const errorOnCall = async (data, reducers, WSConnection) => {
  const { placeId, pointId, userId } = data;
  const { callDispatch, notificationDispatch } = reducers;

  await callDispatch({
    type: 'CALL_CANCEL',
    payload: {
      placeId,
      pointId,
      userId,
    },
  });

  const message = i18next.t('calls.NOTIFICATIONS.error');
  const date = new Date().getTime();
  const newNotification = new CustomEvent('Notification', {
    detail: { message, type: 'danger' },
  });
  window.__TIMEOUTS__.calls.pending[userId] !== undefined &&
    clearTimeout(window.__TIMEOUTS__.calls.pending[userId]);
  window.dispatchEvent(newNotification);

  notificationDispatch({
    type: 'UPDATE_NOTIFICATION',
    payload: {
      notificationId: 'call',
      message,
      placeId,
      pointId,
      type: 'danger',
      date,
    },
  });
};
