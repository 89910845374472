import React, { useContext, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { SearchStoreContext } from '../contexts/SearchStoreContext';

const SearchBar = () => {
  let inputRef = React.createRef();
  const location = useLocation();

  const { t } = useTranslation();

  const [{ searching }, searchDispatch] = useContext(SearchStoreContext);

  const handleRouting = useCallback(() => {
    const isSearchingRouteActive = location.pathname === '/search';

    if (!isSearchingRouteActive && searching.length > 2) {
      window.scrollTo(0, 0);
    }
  }, [searching]);

  useEffect(() => {
    if (searching.length > 2 && location.pathname !== '/search') {
      setSearching('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    handleRouting();
  }, [handleRouting]);

  const setSearching = (value) => {
    searchDispatch({
      type: 'SET_SEARCHING',
      payload: { searching: value },
    });
  };

  const clearSearch = () => {
    setSearching('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  const handleSearch = (event) => {
    if (event.target.value === '') {
      clearSearch();
    }
    const value = event.target.value.toLowerCase();
    setSearching(value);
  };

  return (
    <div
      className="p-rel f-c f-aic pl-8 pr-8 no-border oflowh pe-all fds-1 bg-search"
      style={{ borderRadius: '40px', height: 40, width: '100%', maxWidth: '500px' }}
    >
      <SearchIcon size={24} className="txt-search" />
      <input
        ref={inputRef}
        id="SEARCH_BAR"
        className="W100 no-border p-4 txt-search"
        style={{ height: 40 }}
        type="text"
        name="name"
        onChange={handleSearch}
        autoComplete="off"
        placeholder={t('search_placeholder')}
        value={searching}
        onKeyDown={handleKeyDown}
      />
      {searching !== '' && (
        <CancelIcon className="f18 mp0 no-border txt-danger" onClick={clearSearch} />
      )}
    </div>
  );
};

export { SearchBar };
