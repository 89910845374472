export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const iframeExternalLink = (link, inIframe) => {
  if (!inIframe) {
    window.location.href = link;
    return;
  }

  const url = link + '?output=embed';
  window.location.replace(url);
};
