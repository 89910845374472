const ROUTES = {
  HOME: '/',
  SERVICE: '/service',
  PRODUCT: '/product',
  CATEGORY: '/category',
  CALL: '/call',
  ORDER: '/order',
  CONTACT: '/contact',
  SEARCH: '/search',
  NOTIFICATIONS: '/notifications',
  TERMS: '/terms',
  NUTRITION: '/nutrition',
  COOKIES: '/cookies',
};

export { ROUTES };
