import React from 'react';
import { css } from '@emotion/react';
import BarLoader from 'react-spinners/BarLoader';

const override = css`
  background-color: var(--basic-loading-text);
  color: #ff0000 !important;
`;

const Loading = () => {
  return (
    <div className="p-abs p-full f-c f-dirc f-jcc f-aic H100v bg-basic-loading l12">
      <BarLoader height={2} width={120} loading={true} css={override} color="#ffffff" />
      <p className="f2 f07 mt-4 txt-basic-loading">GETTING LOCATION DATA</p>
    </div>
  );
};

export default Loading;
