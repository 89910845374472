import cookie from 'cookie';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';

const maxAgeDefaultMenu = 60 * 60 * 24 * 7;
const maxAgeCallsOrdersMenu = 60 * 60 * 3;

const handleCookies = (cookies, cookieName, cookieValue, alwaysOn = false) => {
  if (cookieValue) {
    const cookieSetup = alwaysOn
      ? {
          sameSite: true,
          maxAge: maxAgeDefaultMenu,
        }
      : {
          sameSite: true,
          maxAge: maxAgeCallsOrdersMenu,
        };
    const newCookie = cookie.serialize(cookieName, cookieValue, cookieSetup);
    document.cookie = newCookie;
    return cookieValue;
  }
  const cookieExists = cookies[cookieName] !== undefined;
  if (cookieExists) return cookies[cookieName];
  return null;
};

// handle userId cookie
const handleUserIdCookie = (cookies) => {
  const uuidRegex = RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}');
  const userIdCookieExists = uuidRegex.test(cookies._v1_dm_upuid);

  if (userIdCookieExists) {
    return cookies._v1_dm_upuid;
  }

  const newUserId = uuidv4();
  const _v1_dm_upuid = cookie.serialize('_v1_dm_upuid', newUserId, {
    sameSite: true,
    maxAge: 31556952000,
  });
  document.cookie = _v1_dm_upuid;
  return newUserId;
};

const getCookiesInitializeData = (cookies) => {
  const modes = ['default', 'order', 'delivery'];
  let placeId = null;
  let pointId = 'default';
  let mode = 'default';
  const userId = handleUserIdCookie(cookies);

  if (cookies.dm_place_id !== undefined) placeId = cookies.dm_place_id;
  if (cookies.dm_point_id !== undefined) pointId = cookies.dm_point_id;
  if (cookies.dm_mode !== undefined) {
    mode = cookies.dm_mode;
    if (!modes.includes(mode)) {
      mode = 'default';
    }
  }
  if (!pointId || !mode) {
    mode = 'default';
    pointId = 'default';
  }

  if (placeId) {
    const newCookie = cookie.serialize('dm_place_id', placeId, {
      sameSite: true,
      maxAge: mode === 'default' ? maxAgeDefaultMenu : maxAgeCallsOrdersMenu,
    });
    document.cookie = newCookie;
  }
  return { placeId, userId, pointId, mode };
};

// initialize app (ordering mode , delivery mode, default mode just viewing)
// ref => http://localhost:3001/friendspub?product=083dfc0a-6e5f-44e8-bcb8-c7789e24d220
const initialize = () => {
  console.log('INITIALIZING MENU');
  const query = window.location.href.split('?');
  const queryParams = query.length > 1 ? qs.parse(query[1]) : null;
  const alwaysOn = queryParams && queryParams.alwaysOn === 'true';
  const hasProductReferance = queryParams && queryParams.product;
  const ishomePath = window.location.pathname === '/';
  const cookies = cookie.parse(document.cookie);
  if (ishomePath) {
    return getCookiesInitializeData(cookies);
  }

  const modes = ['default', 'order', 'delivery'];
  let placeId = null;
  let pointId = 'default';
  let mode = 'default';
  const userId = handleUserIdCookie(cookies);

  const routes = [
    'call',
    'order',
    'contact',
    'search',
    'notifications',
    'terms',
    'cookies',
    'nutrition',
  ];

  const placeRef = window.location.pathname.split('/');
  const validInitializeUrl =
    placeRef.length === 2 && placeRef[0] === '' && !routes.includes(placeRef[1]);

  if (!validInitializeUrl) {
    return getCookiesInitializeData(cookies);
  }

  const place = [placeRef[1], null];

  const isAppPath = placeRef.length === 2 && Array.isArray(place) && place.length === 2;

  placeId = isAppPath ? place[0] : null;
  pointId =
    queryParams && queryParams.point && !hasProductReferance ? queryParams.point : 'default';
  const productReference = hasProductReferance ? queryParams.product : null;

  if (!placeId) return { placeId, userId, pointId, mode };

  const hasOrder = !modes.includes(pointId) && pointId !== null && pointId !== undefined;
  mode = hasOrder ? 'order' : pointId === 'delivery' ? 'delivery' : 'default';

  const keepMenuUpIfDefault = mode === 'default' ? true : alwaysOn;

  cookies.dm_place_id !== placeId &&
    handleCookies(cookies, 'dm_place_id', placeId, keepMenuUpIfDefault);
  cookies.dm_point_id !== pointId &&
    handleCookies(cookies, 'dm_point_id', pointId, keepMenuUpIfDefault);
  cookies.dm_mode !== pointId && handleCookies(cookies, 'dm_mode', mode, keepMenuUpIfDefault);

  const newCookie = cookie.serialize('dm_place_id', placeId, {
    sameSite: true,
    maxAge: keepMenuUpIfDefault ? maxAgeDefaultMenu : maxAgeCallsOrdersMenu,
  });
  document.cookie = newCookie;

  window.history.pushState(null, '', `/`);
  return { placeId, userId, pointId, mode, queryParams, productReference };
};

export { initialize };
