import React, { useState, useContext } from 'react';

import PlaceHolder from './PlaceHolder';
import { PlaceContext } from '../../core/contexts/PlaceContext';

const Imagez = (props) => {
  const {
    image,
    rounded,
    dropShadow,
    text,
    subText,
    handleNoImage,
    top,
    limitHeight,
    fullscreen,
    maxvh,
    fallbackXL,
    contain,
    style = {},
  } = props;

  const {
    data: {
      place: { features },
    },
  } = useContext(PlaceContext);

  const [isLoading, setIsLoading] = useState(true);
  const [errorImage, setErrorImage] = useState(false);
  const [hasFallbackXL, setHasFallbackXL] = useState(false);

  const noGradiendShadow = features?.theming?.noGradiendShadow;

  const loadingDone = () => {
    setIsLoading(false);
  };

  const onError = () => {
    if (!hasFallbackXL && !errorImage) {
      setHasFallbackXL(true);
      return;
    }
    setErrorImage(true);
    handleNoImage && handleNoImage();
  };

  const limitedConfigHeight = features?.theming?.['max-image-height'];
  const sameImageHeightConfig = features?.theming?.['same-image-height'];
  const hasLimitedConfigHeight = !sameImageHeightConfig && limitedConfigHeight !== undefined;

  let wrapperClasses = 'p-rel W100 oflowh';
  rounded && (wrapperClasses += ' br-2');
  (limitHeight || top || sameImageHeightConfig) && (wrapperClasses += ' H160');
  fullscreen && (wrapperClasses += ' W600 mc');

  let wrapperClassesText = 'f2b f12 lh08 ib-c mb-8 no-select wb-break pr-16 pl-16 lh10';
  dropShadow && (wrapperClassesText += ' fds-2');

  const maxHeight = hasLimitedConfigHeight ? limitedConfigHeight : maxvh ? maxvh : '100%';

  const imageToRender = hasFallbackXL ? fallbackXL : image;

  return (
    <div className={wrapperClasses}>
      {image && !errorImage ? (
        <div className="l2">
          <PlaceHolder isLoading={isLoading} rounded={rounded && !top} />
        </div>
      ) : null}
      {!isLoading && text && (
        <div className="p-abs f-c f-dirc f-1 W100 l2 pt-8 pb-4" style={{ left: -1, bottom: -1 }}>
          <h1 className={wrapperClassesText}>{text}</h1>
          {subText && <h1 className={wrapperClassesText}>{subText}</h1>}
        </div>
      )}
      {image && !errorImage ? (
        <img
          className={`${contain ? 'of-contain' : 'of-cover'} WH100 ${rounded ? 'br-2' : ''}`}
          style={{ maxHeight, ...style }}
          src={imageToRender}
          onError={onError}
          onLoad={loadingDone}
        />
      ) : null}

      {noGradiendShadow ? null : text && !isLoading ? (
        <div
          className={`p-abs l1 ${rounded ? 'br-2' : ''}`}
          style={{
            background: `linear-gradient(#00000000, #000000${top ? 'A0' : '80'})`,
            top: top ? '25%' : 0,
            left: 0,
            right: 0,
            bottom: -1,
          }}
        />
      ) : null}
    </div>
  );
};

export default Imagez;
