import React from 'react';

const colors = [
  'var(--app-gradient-color-1)',
  'var(--app-gradient-color-2)',
  'var(--app-gradient-color-3)',
  'var(--app-gradient-color-4)',
  'var(--app-gradient-color-5)',
];

const GradientHolder = ({ children, top }) => {
  const color1 = colors[Math.floor(Math.random() * colors.length)];
  const color2 = colors[Math.floor(Math.random() * colors.length)];
  const styles = {
    backgroundImage: `linear-gradient(to bottom right, ${color1}, ${color2})`,
    width: 'calc(100vw - 32px)',
    minHeight: 160,
  };

  return (
    <div className={`f-c f-1 l1 ${!top ? 'br-8' : ''}`} style={styles}>
      {children}
    </div>
  );
};

export default GradientHolder;
