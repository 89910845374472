const callToPointInitiated = (state, payload) => {
  if (state.call.status === null) {
    return state;
  }
  const newState = {
    call: {
      date: new Date().getTime(),
      status: 'pending',
      type: payload.type,
    },
  };
  return newState;
};

const syncCalls = (state, payload) => {
  if (!Array.isArray(payload) || payload.length === 0) {
    return state;
  }
  const [call] = payload;
  const newState = {
    call: {
      date: call?.updated_at,
      status: call?.status ? call?.status : null,
      type: null,
    },
  };
  return newState;
};

const callToPointReceived = (state, payload) => {
  const newState = {
    call: {
      date: state.call.date,
      type: state.call.type,
      status: 'pending',
    },
  };
  return newState;
};

const callToPointNotReceived = (state, payload) => {
  const newState = {
    call: {
      date: state.call.date,
      type: null,
      status: null,
    },
  };
  return newState;
};

const removeCall = (state, payload) => {
  if (state.call.status === null) {
    return state;
  }
  const newState = {
    call: {
      date: 0,
      type: null,
      status: null,
    },
  };
  return newState;
};

const callConfirmed = (state, payload) => {
  if (state.call.status === null) {
    return state;
  }
  const newState = {
    call: {
      date: new Date().getTime(),
      type: null,
      status: 'confirmed',
    },
  };
  return newState;
};

const confirmPoint = (state, payload) => {
  if (state.call.status === null) {
    return state;
  }
  const newState = {
    call: {
      date: new Date().getTime(),
      type: null,
      status: 'confirmed',
    },
  };
  return newState;
};

const CallReducer = (state, action) => {
  switch (action.type) {
    case 'INITIATE_CALL':
      return callToPointInitiated(state, action.payload);
    case 'CALL_RECEIVED':
      return callToPointReceived(state, action.payload);
    case 'CALL_CONFIRMED':
      return callConfirmed(state, action.payload);
    case 'CALL_COMPLETED':
      return removeCall(state, action.payload);
    case 'CALL_NOT_RECEIVED':
      return callToPointNotReceived(state, action.payload);
    case 'CALL_CANCEL':
      return removeCall(state, action.payload);
    case 'SYNC_CALLS':
      return syncCalls(state, action.payload);

    case 'CONFIRM_POINT':
      return confirmPoint(state, action.payload);
    default:
      return state;
  }
};

export default CallReducer;
