import React, { useEffect } from 'react';
import { scrollToTop } from '../../core/functions/utilities';
import Notifications from '../../features/notifications/Notifications';

const NotificationsPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return <Notifications />;
};
export default NotificationsPage;
