import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';

import { PopupArrow } from '../../../lib/reusable/PopupArrow';

let t1 = null;
let t2 = null;

const CallPopupInfo = ({ clear, top, left }) => {
  const { t: translate } = useTranslation();

  const [isClosing, setIsClosing] = useState(false);
  const [hide, setHide] = useState(false);

  const handleAnimation = () => {
    t1 = setTimeout(() => setIsClosing(true), 2600);
    t2 = setTimeout(() => setHide(true), 3000);
  };

  useEffect(() => {
    handleAnimation();
  }, []);

  useEffect(() => {
    if (clear) {
      clearTimeout(t1);
      clearTimeout(t2);
      setHide(true);
    }
  }, [clear]);

  const animation = useSpring({
    from: { x: 0 },
    x: 1,
    config: { duration: 300 },
    reverse: isClosing,
  });

  const styles = {
    transform: animation.x
      .interpolate({
        range: [0, 0.5, 0.75, 1],
        output: [0, 0.9, 1.2, 1],
      })
      .interpolate((x) => `scale(${x})`),
  };

  if (hide) {
    return null;
  }

  const transformOrigin = `${Math.round(Math.abs(left + 50))}px ${Math.round(Math.abs(top))}px`;

  return (
    <animated.div style={{ ...styles, transformOrigin }}>
      <PopupArrow isVisible={true} top={top} left={left}>
        <div
          className="p-rel no-border br-8 p-8 mt-4 mb-4 mr-32 bg-calls-popup-overlay"
          style={{ maxWidth: 128 }}
        >
          <div className="f-c f-dirc f-jcsb f-aic pt-4 pb-4 ta-c">
            <h3 className="f2 f10 txt-calls-popup">{translate('calls.POPUP')}</h3>
          </div>
        </div>
      </PopupArrow>
    </animated.div>
  );
};

export { CallPopupInfo };
