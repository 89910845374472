/* eslint-disable prettier/prettier */
import { URI } from '../config';

const imageRequestDate = new Date().getTime();

const baseImagesPath = (placeId, productId, res) =>
  `${URI.bucket}/${placeId}/${res}/${productId}.jpg?time=${imageRequestDate}`;

const baseMoviesPath = (placeId, productId, res) =>
  `${URI.bucket}/${placeId}/${res}/${productId}.mp4?time=${imageRequestDate}`;

const baseEventsPath = (placeId, eventId, mediaType) =>
  `${URI.bucket}/${placeId}/events/${eventId}.${mediaType}?time=${imageRequestDate}`;

const getDateValue = (date) => {
  const newDate = date ? new Date(date) : new Date();
  return newDate.getTime();
};

const handleTranslations = (object) => {
  const hasNameTranslations = object?.name?.length > 3 && object?.name?.includes('//');
  if (hasNameTranslations) {
    const translations = object.name.split('//');
    const name = translations[0].trim();
    const internationalName = translations[1].trim();
    object.name = name;
    object.intname = internationalName;
  }
  const hasDetailsTranslations = object?.details?.length > 3 && object?.details?.includes('//');
  if (hasDetailsTranslations) {
    const translations = object.details.split('//');
    const details = translations[0].trim();
    const internationalDetails = translations[1].trim();
    object.details = details;
    object.intdetails = internationalDetails;
  }
  const hasDescriptionTranslations =
    object?.description?.length > 3 && object?.description?.includes('//');
  if (hasDescriptionTranslations) {
    const translations = object.description.split('//');
    const description = translations[0].trim();
    const internationalDescription = translations[1].trim();
    object.description = description;
    object.intdescription = internationalDescription;
  }
};

const generateCaching = ({
  place: placeData,
  categories,
  toppingsGroups,
  toppings,
  products,
  services,
  events,
}) => {
  const [place] = placeData;
  const { place_id: placeId, features } = place;

  const cache = {
    top: [],
    events: {
      services: [],
      categories: [],
      subCategories: [],
      products: {},
      main: [],
    },
    services: {
      serviceId__service: {},
      serviceId__categories: {},
      serviceId__images: [],
    },
    products: {
      productId__product: {},
    },
    categories: {
      categoryId__category: {},
      categoryId__images: {},
      categoryId__products: {},
      nestedId__categories: {},
      categoryId__extraProducts: {},
    },
    toppings: {
      toppingId__topping: {},
      toppingsGroupId__toppingsGroup: {},
      toppingsGroupId__toppings: {},
      productId__toppingsGroupId: {},
    },
    index: {
      name__product: {},
      names: [],
      tag__products: {},
      tags: [],
    },
  };

  const featuresEventsActive = Boolean(features?.events?.active);
  // const featuresEventsTop = Boolean(features?.events?.top);
  // const featuresEventsBottom = Boolean(features?.events?.bottom);

  // link
  // {
  //   "url": "https://www.coca-cola.ro/",
  //   "category": null,
  //   "service": null,
  //   "product": null
  // }

  // spread
  // {
  //   "categories": true,
  //   "gallery": true,
  //   "main": false,
  //   "position": "top", (can be top, middle, percent of items displayed for example 33%, random)
  //   "products": null,
  //   "services": false,
  //   "subCategories": false
  // }

  try {
    let serviceProcessed = null;
    for (const service of services) {
      serviceProcessed = service;
      cache.services.serviceId__service[service.id] = service;
    }
  } catch (error) {
    console.error('ERROR ON INITIALIZING SERVICE', serviceProcessed);
  }

  try {
    let toppingsGroupProcessed = null;
    for (const toppingsGroup of toppingsGroups) {
      toppingsGroupProcessed = toppingsGroup;
      cache.toppings.toppingsGroupId__toppings[toppingsGroup.id] = [];
      cache.toppings.toppingsGroupId__toppingsGroup[toppingsGroup.id] = toppingsGroup;
    }
  } catch (error) {
    console.error('ERROR ON INITIALIZING TOPPING GROUPS', toppingsGroupProcessed);
  }

  for (const topping of toppings) {
    try {
      handleTranslations(topping);
      cache.toppings.toppingId__topping[topping.id] = topping;
      if (topping.toppings_group_id) {
        const index = Number(topping.position) - 1;
        if (!cache.toppings.toppingsGroupId__toppings[topping.toppings_group_id]) {
          cache.toppings.toppingsGroupId__toppings[topping.toppings_group_id] = [];
        }
        cache.toppings.toppingsGroupId__toppings[topping.toppings_group_id][index] = topping;
      }
    } catch (error) {
      console.error('ERROR ON TOPPING', topping);
      continue;
    }
  }

  for (const category of categories) {
    try {
      if (!cache.services.serviceId__service[category.service_id]) {
        continue;
      }

      handleTranslations(category);
      cache.categories.categoryId__category[category.id] = category;
      if (category.nested) {
        const index = Math.abs(Number(category.position)) - 1;
        if (!cache.categories.nestedId__categories[category.nested]) {
          cache.categories.nestedId__categories[category.nested] = [];
        }
        cache.categories.nestedId__categories[category.nested][index] = category;
      } else {
        const index = Math.abs(Number(category.position)) - 1;
        if (!cache.services.serviceId__categories[category.service_id]) {
          cache.services.serviceId__categories[category.service_id] = [];
        }
        cache.services.serviceId__categories[category.service_id][index] = category;
      }
    } catch (error) {
      console.error('ERROR ON CATEGORY', category);
      continue;
    }
  }

  for (const product of products) {
    try {
      handleTranslations(product);
      if (!cache.categories.categoryId__category[product.category_id]) {
        continue;
      }

      const index = Number(product.position) - 1;
      if (!cache.categories.categoryId__products[product.category_id]) {
        cache.categories.categoryId__products[product.category_id] = [];
      }
      cache.categories.categoryId__products[product.category_id][index] = product;

      const productHasToppingGroup = product.toppings_group_id !== null;
      const toppingGroupAvailable =
        cache.toppings.toppingsGroupId__toppingsGroup[product.toppings_group_id] !== undefined;
      if (productHasToppingGroup && toppingGroupAvailable) {
        cache.toppings.productId__toppingsGroupId[product.id] =
          cache.toppings.toppingsGroupId__toppingsGroup[product.toppings_group_id].id;
      }

      if (product.has_movie) {
        product.moviesXL = [baseMoviesPath(placeId, product.id, 'high')];
      }
      product.imagesXL = product.has_image ? [baseImagesPath(placeId, product.id, 'high')] : [];
      product.imagesULTRA = product.has_image ? [baseImagesPath(placeId, product.id, 'ultra')] : [];

      const productCategoryIsNested =
        cache.categories.categoryId__category[product.category_id] &&
        cache.categories.categoryId__category[product.category_id].nested !== null;
      const nestedId = productCategoryIsNested
        ? cache.categories.categoryId__category[product.category_id].nested
        : null;

      if (product.has_image && product.promote === true) {
        if (!cache.categories.categoryId__images[product.category_id]) {
          cache.categories.categoryId__images[product.category_id] = [];
        }
        if (nestedId && !cache.categories.categoryId__images[nestedId]) {
          cache.categories.categoryId__images[nestedId] = [];
        }
        const image = {
          imagesXL: [baseImagesPath(placeId, product.id, 'high')],
        };

        cache.categories.categoryId__images[product.category_id].push(image);
        nestedId && cache.categories.categoryId__images[nestedId].push(image);
        const category = cache.categories.categoryId__category[product.category_id];
        if (!cache.services.serviceId__images[category.service_id]) {
          cache.services.serviceId__images[category.service_id] = [];
        }
        cache.services.serviceId__images[category.service_id].push(image);
      }

      cache.products.productId__product[product.id] = product;

      if (Array.isArray(product.extra_cat) && product.extra_cat.length > 0) {
        for (const extra_category_id of product.extra_cat) {
          if (!cache.categories.categoryId__extraProducts[extra_category_id]) {
            cache.categories.categoryId__extraProducts[extra_category_id] = [];
          }
          cache.categories.categoryId__extraProducts[extra_category_id].push(product);
        }
      }

      cache.index.name__product[product.name.toLowerCase()] = product;
      product?.intname && (cache.index.name__product[product.intname.toLowerCase()] = product);
      if (product.tags && Array.isArray(product.tags)) {
        for (const tag of product.tags) {
          const tagName = tag.toLowerCase();
          if (Array.isArray(cache.index.tag__products[tagName])) {
            cache.index.tag__products[tagName].push(product);
          } else {
            cache.index.tag__products[tagName] = [product];
          }
        }
      }
    } catch (error) {
      console.error('ERROR ON PRODUCT', product);
      console.log(error);
      continue;
    }
  }

  for (const serviceId in cache.services.serviceId__service) {
    try {
      const hasCategories = Array.isArray(cache.services.serviceId__categories[serviceId]);
      const categories = hasCategories
        ? cache.services.serviceId__categories[serviceId].filter((category) => Boolean(category))
        : undefined;
      if (!categories) continue;
      for (const category of categories) {
        const delimiter = {
          name: cache.categories.categoryId__category[category.id].name,
          intname: cache.categories.categoryId__category[category.id]?.intname,
          id: cache.categories.categoryId__category[category.id].id,
          delimiter: true,
          position: Math.abs(cache.categories.categoryId__category[category.id].position) - 1,
          main: false,
        };

        if (!cache.categories.categoryId__products[category.id]) {
          cache.categories.categoryId__products[category.id] = [];
        }

        if (!category.group) {
          const categoryProducts = [
            delimiter,
            ...cache.categories.categoryId__products[category.id],
            ...(cache.categories.categoryId__extraProducts[category.id] || []),
          ];
          cache.categories.categoryId__products[category.id] = categoryProducts;
        } else {
          const nestedCategories = cache.categories.nestedId__categories[category.id] || [];
          for (const nestedCategory of nestedCategories) {
            if (!nestedCategory?.id) continue;
            if (!cache.categories.categoryId__products[nestedCategory.id]) {
              cache.categories.categoryId__products[nestedCategory.id] = [];
            }

            const nestedDelimiter = {
              name: cache.categories.categoryId__category[nestedCategory.id].name,
              id: cache.categories.categoryId__category[nestedCategory.id].id,
              delimiter: true,
              position:
                Math.abs(cache.categories.categoryId__category[nestedCategory.id].position) - 1,
              main: false,
            };

            cache.categories.categoryId__products[category.id] = [
              ...cache.categories.categoryId__products[category.id],
              nestedDelimiter,
              ...cache.categories.categoryId__products[nestedCategory.id],
              ...(cache.categories.categoryId__extraProducts[nestedCategory.id] || []),
            ];
          }
        }

        if (category.top === true) {
          cache.top.push(category);
        }
      }
    } catch (error) {
      console.error('ERROR ON SERVICE_ID TASK 1', serviceId, error);
      continue;
    }
  }

  for (const serviceId in cache.services.serviceId__service) {
    try {
      const categories = cache.services.serviceId__categories[serviceId];
      if (!categories) continue;
      for (const category of categories) {
        if (!category) continue;
        if (category.has_movie) {
          cache.categories.categoryId__category[category.id].moviesXL = [
            baseMoviesPath(placeId, category.id, 'high'),
          ];
        } else if (category.has_image) {
          const image = [baseImagesPath(placeId, category.id, 'high')];
          cache.categories.categoryId__category[category.id].imagesXL = image;
          category.imagesXL = image;
        } else {
          const imageProducts = cache.categories.categoryId__images[category.id];
          if (!imageProducts || imageProducts.length === 0) {
            cache.categories.categoryId__category[category.id].imagesXL = [];
            category.imagesXL = [];
            continue;
          }
          const randomProduct = imageProducts[Math.floor(Math.random() * imageProducts.length)];
          cache.categories.categoryId__category[category.id].imagesXL = randomProduct.imagesXL;
          category.imagesXL = randomProduct.imagesXL;
        }
      }
    } catch (error) {
      console.error('ERROR ON SERVICE_ID TASK 2', serviceId);
      continue;
    }
  }

  for (const service of services) {
    try {
      handleTranslations(service);
      if (service.has_movie) {
        cache.services.serviceId__service[service.id].moviesXL = [
          baseMoviesPath(placeId, service.id, 'high'),
        ];
        continue;
      }

      if (service.has_image) {
        cache.services.serviceId__service[service.id].imagesXL = [
          baseImagesPath(placeId, service.id, 'high'),
        ];
        continue;
      }

      const serviceImages = cache.services.serviceId__images[service.id];
      if (!serviceImages || !Array.isArray(serviceImages) || serviceImages.length === 0) {
        cache.services.serviceId__service[service.id].imagesXL = [];
        continue;
      }

      const randomProduct = serviceImages[Math.floor(Math.random() * serviceImages.length)];
      cache.services.serviceId__service[service.id].imagesXL = randomProduct.imagesXL;
    } catch (error) {
      console.error('ERROR ON SERVICE', service);
      continue;
    }
  }

  cache.index.names = Object.keys(cache.index.name__product);
  cache.index.tags = Object.keys(cache.index.tag__products);

  if (featuresEventsActive) {
    const foundEvents = Array.isArray(events) ? events : [];
    for (const event of foundEvents) {
      try {
        const now = getDateValue();
        const from = getDateValue(event.from);
        const to = getDateValue(event.to);
        const showEvent = from <= now && now < to;
        if (showEvent) {
          event.src = baseEventsPath(placeId, event.id, event.type || 'jpg');

          if (event?.spread?.main) {
            cache.events.main[event.position - 1] = event;
          }

          if (event?.spread?.services) {
            cache.events.services[event.position - 1] = event;
          }

          if (event?.spread?.categories) {
            cache.events.categories[event.position - 1] = event;
          }

          if (event?.spread?.subCategories) {
            cache.events.subCategories[event.position - 1] = event;
          }

          if (event?.spread?.products) {
            // console.log('cache?.products', cache?.products.productId__product);
            if (event?.spread?.products) {
              const productsWithEvents = Object.keys(event?.spread?.products).map((productId) => {
                if (cache?.products?.productId__product?.[productId]) {
                  return cache.products.productId__product[productId];
                }
  
                return null;
              });
  
              for (const productWithEvent of productsWithEvents || []) {
                if (productWithEvent) {
                  const hasCategoryProperty = Boolean(cache?.events?.products?.[productWithEvent.category_id]);
                  const hasProductProperty =
                    Boolean(cache?.events?.products?.[productWithEvent.category_id]?.[productWithEvent.id]);
    
                  if (!hasCategoryProperty) {
                    cache.events.products[productWithEvent.category_id] = {};
                  }
    
                  if (!hasProductProperty) {
                    cache.events.products[productWithEvent.category_id][productWithEvent.id] = {
                      events: [],
                    };
                  }
    
                  cache.events.products[productWithEvent.category_id][productWithEvent.id].events[
                    event.position - 1
                  ] = event;
                };
              }
            };
          }
        }
      } catch (error) {
        console.error('ERROR ON ADDING EVENT', event);
        continue;
      }
    }
  }

  cache.events.services = cache.events.services.filter((event) => Boolean(event));
  cache.events.categories = cache.events.categories.filter((event) => Boolean(event));
  cache.events.subCategories = cache.events.subCategories.filter((event) => Boolean(event));
  cache.events.main = cache.events.main.filter((event) => Boolean(event));

  return cache;
};

export { generateCaching };
