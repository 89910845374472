import React, { useContext } from 'react';

import { RemoveCancelItem } from '../../../lib/reusable/AddRemoveButtons';

import Toppings from '../../toppings/components/Toppings';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { OrderStoreContext } from '../../orders/contexts/OrderStoreContext';

export default function Product({ product, action, active, index }) {
  const [state, dispatch] = useContext(OrderStoreContext);
  const {
    data: {
      place: { placeId, pointId },
    },
  } = useContext(PlaceContext);

  const hasProduct = !active
    ? state.orders.history[index].products[product.id]
    : state.orders.active.products[product.id];

  const count = hasProduct
    ? !active
      ? state.orders.history[index].products[product.id].length
      : state.orders.active.products[product.id].length
    : -1;

  const productData = hasProduct
    ? !active
      ? state.orders.history[index].products[product.id]
      : state.orders.active.products[product.id]
    : [];

  let hasAddedToppings = false;
  for (const entry of productData) {
    if (Object.keys(entry.toppings).length > 0) {
      hasAddedToppings = true;
      break;
    }
  }

  const removeProductFromOrder = () => {
    dispatch({
      type: 'REMOVE_PRODUCT',
      payload: { productId: product.id, placeId, pointId },
    });
  };

  const removeProductFromOrderAll = (event) => {
    event.stopPropagation();
    dispatch({
      type: 'REMOVE_PRODUCT_ALL',
      payload: { productId: product.id, placeId, pointId },
    });
  };

  return (
    <div className="p-rel bb-app">
      <div className="p-rel f-c f-jcsb f-aic W100 pt-16 pb-4">
        {!hasAddedToppings ? (
          <RemoveCancelItem type="info" action={removeProductFromOrder} />
        ) : (
          <RemoveCancelItem type="danger" action={removeProductFromOrderAll} />
        )}
        <div className="f-c f-aic f-1 ml-4 pr-4">
          <h3 className="f2b f08 f-lsc-2">{`${count}x`}</h3>
          <button
            className="f-c f-1 mp0 no-border ml-8 no-select cursor-default"
            key={product.id}
            onClick={() => action({ productId: product.id, productName: product.name })}
          >
            <h3 className="f2 f08 f-lsc-2 ta-l">{product.name}</h3>
          </button>
        </div>
        <div className="f-c f-jcfe f-aic">
          <h3 className="f2b f08 f-lsc-2">{`${count * product.cost} ${product.currency}`}</h3>
        </div>
      </div>
      <Toppings product={product} order />
    </div>
  );
}
