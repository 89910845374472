import React, { useEffect } from 'react';
import { scrollToTop } from '../../core/functions/utilities';
import Calls from '../../features/calls/Calls';

const CallPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return <Calls isVisible={true} />;
};

export default CallPage;
