import {
  addProduct,
  removeProduct,
  removeProductAll,
  setToppingsToProduct,
  removeProductByIndex,
  confirmActiveOrder,
  approveOrder,
  approveDeliveryOrder,
  stopDeliveryOrder,
  rejectOrder,
  cancelOrder,
  confirmReceivedOrderByPlace,
  confirmOrderNotReceivedFormPlace,
} from './reducers/addRemoveProducts';
import { syncOrders } from './reducers/syncOrders';
import { orderFromLocalStorage } from './reducers/orderFromLocalStorage';

const OrderReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_PRODUCT':
      return addProduct(state, action.payload);
    case 'REMOVE_PRODUCT':
      return removeProduct(state, action.payload);
    case 'REMOVE_PRODUCT_INDEX':
      return removeProductByIndex(state, action.payload);
    case 'REMOVE_PRODUCT_ALL':
      return removeProductAll(state, action.payload);
    case 'SET_TOPPINGS_OF_PRODUCTS':
      return setToppingsToProduct(state, action.payload);
    case 'ORDER_CONFIRM_ACTIVE':
      return confirmActiveOrder(state, action.payload);
    case 'ORDER_CONFIRM_RECEIVED':
      return confirmReceivedOrderByPlace(state, action.payload);
    case 'ORDER_CONFIRM_ACTIVE_NOT_RECEIVED':
      return confirmOrderNotReceivedFormPlace(state, action.payload);
    case 'CONFIRM_POINT':
      return approveOrder(state, action.payload);
    case 'DELIVERY_START_ON_POINT':
      return approveDeliveryOrder(state, action.payload);
    case 'DELIVERY_STOP_ON_POINT':
      return stopDeliveryOrder(state, action.payload);
    case 'REJECT_POINT':
      return rejectOrder(state, action.payload);
    case 'ORDER_CANCEL':
      return cancelOrder(state, action.payload);
    case 'SYNC_ORDERS':
      return syncOrders(state, action.payload);
    case 'LOCALSTORAGE_ORDER':
      return orderFromLocalStorage(state, action.payload);
    default:
      return state;
  }
};

export default OrderReducer;
