import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ServicePage from './navigation/pages/ServicePage';
import HomePage from './navigation/pages/HomePage';
import ProductPage from './navigation/pages/ProductPage';
import CategoryPage from './navigation/pages/CategoryPage';
import CallPage from './navigation/pages/CallPage';
import OrderPage from './navigation/pages/OrderPage';
import ContactPage from './navigation/pages/ContactPage';
import TermsAndConditions from './navigation/pages/TermsAndConditionsPage';
import NutritionPage from './navigation/pages/NutritionPage';
import CookiesPage from './navigation/pages/CookiesPage';
import SearchPage from './navigation/pages/SearchPage';
import NotificationsPage from './navigation/pages/NotificationsPage';

import { Unitpay } from './features/unitpay/Unitpay';
import { InterfaceSwitcher } from './features/interface/Interface';
import { initialize } from './core/functions/appInitialize';

import { PlaceProvider } from './core/contexts/PlaceContext';
import { RAFProvider } from './core/contexts/RAFContext';
import { SAFProvider } from './core/contexts/SAFContext';
import { UIMemoryProvider } from './core/contexts/UIMemoryContext';
import { CurrenciesProvider } from './core/contexts/CurrenciesContext';
import { InterfaceProvider } from './core/contexts/InterfaceContext';
import { WSProvider } from './core/websockets/WSContext';
import { OrderStore } from './features/orders/contexts/OrderStoreContext';
import { SearchStore } from './features/search/contexts/SearchStoreContext';
import { NotificationStore } from './features/notifications/contexts/NotificationStoreContext';
import { CallStore } from './features/calls/contexts/CallStoreContext';
import { ThemeProvider } from './features/theming/contexts/ThemeContext';

import './App.css';

window.__TIMEOUTS__ = {
  orders: { initiated: {}, pending: {} },
  calls: { initiated: {}, pending: {} },
};

const { placeId, userId, pointId, mode, queryParams, productReference } = initialize();

function App() {
  const userDevice = 'device';
  const [ageValid, setAgeValid] = useState(undefined);

  if (!placeId) return <Unitpay />;

  // pp-background-forced => math random on images and logo to replace fast images

  return (
    <div className="App">
      <div className="p-rel oflowh minWH100v bg-app txt-app">
        <div className="p-fix p-full minWH100v bg-app-linear l0" />
        <div className="p-fix p-full minWH100v bg-app-linear90 l1" />
        <div id="app-background-forced" className="p-fix p-full minWH100v bg-app-image l2" />
        <ThemeProvider>
          <PlaceProvider
            placeRoute={{
              placeId,
              userId,
              pointId,
              mode,
              userDevice,
              queryParams,
              productReference,
            }}
          >
            <OrderStore>
              <SearchStore>
                <NotificationStore>
                  <CallStore>
                    <WSProvider>
                      <RAFProvider>
                        <SAFProvider>
                          <UIMemoryProvider>
                            <InterfaceProvider>
                              <CurrenciesProvider>
                                <Router basename="/">
                                  <div className="p-rel l4">
                                    <InterfaceSwitcher ageValid={ageValid} />
                                  </div>
                                  <div className="p-rel l3">
                                    <Switch>
                                      <Route exact path="/service/:serviceId">
                                        <ServicePage />
                                      </Route>
                                      <Route exact path="/category/:categoryId">
                                        <CategoryPage />
                                      </Route>
                                      <Route exact path="/product/:productId">
                                        <ProductPage />
                                      </Route>
                                      <Route exact path="/call">
                                        <CallPage />
                                      </Route>
                                      <Route exact path="/order">
                                        <OrderPage />
                                      </Route>
                                      <Route exact path="/contact">
                                        <ContactPage />
                                      </Route>
                                      <Route exact path="/search">
                                        <SearchPage />
                                      </Route>
                                      <Route exact path="/notifications">
                                        <NotificationsPage />
                                      </Route>
                                      <Route exact path="/terms">
                                        <TermsAndConditions />
                                      </Route>
                                      <Route exact path="/nutrition">
                                        <NutritionPage />
                                      </Route>
                                      <Route exact path="/cookies">
                                        <CookiesPage />
                                      </Route>

                                      <Route path={[`/${placeId}`, '/']}>
                                        <HomePage ageValid={ageValid} setAgeValid={setAgeValid} />
                                      </Route>
                                    </Switch>
                                  </div>
                                </Router>
                              </CurrenciesProvider>
                            </InterfaceProvider>
                          </UIMemoryProvider>
                        </SAFProvider>
                      </RAFProvider>
                    </WSProvider>
                  </CallStore>
                </NotificationStore>
              </SearchStore>
            </OrderStore>
          </PlaceProvider>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default App;
