import React from 'react';
import { motion } from 'framer-motion';

const RoundButtonSimple = (props) => {
  const { action, children, size, disabledBg = false } = props;

  const radius = size ? size + 10 : 50;

  return (
    <motion.button
      className={
        disabledBg
          ? 'p-rel f-c f-jcc f-aic pe-all mp0 fds-3'
          : 'p-rel f-c f-jcc f-aic pe-all mp0 bg-buttons fds-3'
      }
      onClick={action}
      whileTap={{ scale: 0.84 }}
      style={{
        height: `${radius}px`,
        width: `${radius}px`,
        borderRadius: `${radius}px`,
      }}
    >
      {children}
    </motion.button>
  );
};

export { RoundButtonSimple };
