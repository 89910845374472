import React, { useReducer } from 'react';
import CallReducer from './CallReducer';

const initialState = {
  call: {
    date: 0,
    status: null,
    type: null,
  },
};

export const CallStoreContext = React.createContext(initialState);
export const CallStore = ({ children }) => {
  const [state, dispatch] = useReducer(CallReducer, initialState);
  return (
    <CallStoreContext.Provider value={[state, dispatch]}>{children}</CallStoreContext.Provider>
  );
};

export default CallStore;
