import React, { useState, useContext, useEffect, useRef } from 'react';
import Badge from '@mui/material/Badge';
import { useHistory, useLocation } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { RAFContext } from '../../../core/contexts/RAFContext';
import { CallStoreContext } from '../../calls/contexts/CallStoreContext';
import { OrderStoreContext } from '../../orders/contexts/OrderStoreContext';
import { WSContext } from '../../../core/websockets/WSContext';
import { ROUTES } from '../../../navigation/routes';
import { CallPopupInfo } from '../../calls/components/CallPopupInfo';
import CircularProgress from '@mui/material/CircularProgress';

const cls = (value, active) => {
  return value === active ? 'txt-app' : 'txt-app-dim';
};

const Label = ({ name, active, value }) => (
  <h3 className={`f1b f06 ${cls(value, active)}`}>{name}</h3>
);

const CallLabel = ({ selected, setCallPopopPos }) => {
  const callRef = useRef(null);

  const setPositionOfCallPopop = (element) => {
    const rect = element.getBoundingClientRect();
    const newPosition = { left: rect.x - 46, top: -120 };
    setCallPopopPos(newPosition);
  };

  useEffect(() => {
    if (callRef?.current) {
      setPositionOfCallPopop(callRef.current);
    }
  }, [callRef.current]);

  return (
    <div className="p-rel" ref={callRef}>
      <Label name="CALL" active={selected} value={ROUTES.CALL} />
    </div>
  );
};

const CallIcon = ({ selected }) => {
  const [callState] = useContext(CallStoreContext);
  const { WSConnection } = useContext(WSContext);

  const status = callState?.call?.status;
  const isPending = status === 'pending';
  const isConfirmed = status === 'confirmed';

  if (!WSConnection) {
    return (
      <div className="f-c f-dirc f-aic f-jcc txt-app">
        <CircularProgress size={24} color="inherit" />
      </div>
    );
  }

  if (isPending) {
    return <RoomServiceIcon className="txt-warning" />;
  }

  if (isConfirmed) {
    return <RoomServiceIcon className="txt-success" />;
  }

  return <RoomServiceIcon className={cls(ROUTES.CALL, selected)} />;
};

const OrderIcon = ({ selected }) => {
  const { WSConnection } = useContext(WSContext);
  const [state] = useContext(OrderStoreContext);
  const [orderCount, setOrderCount] = useState(0);

  useEffect(() => {
    try {
      const newOrderCount = Object.keys(state.orders.active.products).length;
      if (newOrderCount !== orderCount) {
        setOrderCount(newOrderCount);
      }
    } catch (error) {
      console.error(error);
    }
  }, [state]);

  if (!WSConnection) {
    return (
      <div className="f-c f-dirc f-aic f-jcc txt-app">
        <CircularProgress size={24} color="inherit" />
      </div>
    );
  }

  return (
    <Badge badgeContent={orderCount} color="error">
      <FeaturedPlayListIcon className={cls(ROUTES.ORDER, selected)} />
    </Badge>
  );
};

export default function InterfaceBottomNavigation() {
  const [selected, setSelected] = useState(ROUTES.HOME);
  const [hasCalls, setHasCalls] = useState(false);
  const [hasOrders, setHasOrders] = useState(false);
  const [callPopopPos, setCallPopopPos] = useState(null);

  const history = useHistory();

  const { pathname } = useLocation();

  const { WSConnection } = useContext(WSContext);
  const { isMobile } = useContext(RAFContext);
  const placeContext = useContext(PlaceContext);
  const {
    data: {
      place: { pointId, ordering, features, calling },
    },
    loading: isLoading,
  } = placeContext;

  useEffect(() => {
    setHasCalls(calling);

    const validPaths = [ROUTES.HOME, ROUTES.SEARCH];
    calling && validPaths.push(ROUTES.CALL);

    const pathArray = pathname.split('/').filter((path) => path !== '');
    const activePath = `/${pathArray[0]}`;
    const differentPaths = validPaths.includes(activePath) && selected !== activePath;

    if (differentPaths) {
      setSelected(activePath);
    }
  }, [features, pointId]);

  useEffect(() => {
    setHasOrders(ordering);

    const validPaths = [ROUTES.HOME, ROUTES.SEARCH];
    ordering && validPaths.push(ROUTES.ORDER);

    const pathArray = pathname.split('/').filter((path) => path !== '');
    const activePath = `/${pathArray[0]}`;
    const differentPaths = validPaths.includes(activePath) && selected !== activePath;

    if (differentPaths) {
      setSelected(activePath);
    }
  }, [ordering]);

  if (isLoading) {
    return null;
  }

  const featuresCallsInfo = Boolean(features?.calls['call-popup-info']);

  return (
    <div className="p-rel pb-4 ml-8 mr-8 bg-app-nav l1" style={{ backdropFilter: 'blur(2px)' }}>
      {hasCalls && featuresCallsInfo && callPopopPos && (
        <CallPopupInfo clear={false} top={callPopopPos.top} left={callPopopPos.left} />
      )}
      <div className={`${isMobile ? 'l2' : 'f-c f-aic l2'}`}>
        {pathname !== '/' && !isMobile && (
          <div className="f-c f-aic" onClick={() => history.goBack()}>
            <ArrowLeftIcon style={{ width: 48, height: 48, marginLeft: 32 }} />
            <span className="f08">BACK</span>
          </div>
        )}
        <BottomNavigation
          sx={{
            backgroundColor: 'transparent',
            maxWidth: 500,
            margin: '0 auto',
          }}
          value={selected}
          onChange={(event, newRoute) => {
            history.push(newRoute);
            setSelected(newRoute);
          }}
        >
          <BottomNavigationAction
            label={<Label name="HOME" active={selected} value={ROUTES.HOME} />}
            value={ROUTES.HOME}
            icon={<HomeIcon className={`${cls(ROUTES.HOME, selected)}`} />}
            sx={{ backgroundColor: 'transparent' }}
          />
          <BottomNavigationAction
            label={<Label name="SEARCH" active={selected} value={ROUTES.SEARCH} />}
            value={ROUTES.SEARCH}
            icon={<SearchIcon className={cls(ROUTES.SEARCH, selected)} />}
            sx={{ backgroundColor: 'transparent' }}
          />
          {hasCalls && (
            <BottomNavigationAction
              label={<CallLabel selected={selected} setCallPopopPos={setCallPopopPos} />}
              value={ROUTES.CALL}
              icon={<CallIcon selected={selected} />}
              sx={{ backgroundColor: 'transparent' }}
              disabled={!WSConnection}
            />
          )}
          {hasOrders && (
            <BottomNavigationAction
              label={<Label name="ORDERS" active={selected} value={ROUTES.ORDER} />}
              value={ROUTES.ORDER}
              icon={<OrderIcon />}
              sx={{ backgroundColor: 'transparent' }}
              disabled={!WSConnection}
            />
          )}
        </BottomNavigation>
        {pathname !== '/' && !isMobile && (
          <div className="f-c f-aic" onClick={() => history.goForward()}>
            <span className="f08">FORWARD</span>
            <ArrowRightIcon style={{ width: 48, height: 48, marginRight: 32 }} />
          </div>
        )}
      </div>
    </div>
  );
}
