import { pushNotification } from '../../../core/functions/pushNotifications';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

export const confirmDeliveryStopOnPoint = (payload, reducers, data) => {
  const uuid = uuidv4();
  const { placeId, pointId, userId, events } = payload;
  const { orderDispatch, notificationDispatch } = reducers;
  const date = new Date().getTime();
  const message = i18next.t('orders.NOTIFICATIONS.delivery_stop');
  const newNotification = new CustomEvent('Notification', {
    detail: { message, type: 'warning', orderId: uuid, date: new Date().getTime() },
  });
  window.dispatchEvent(newNotification);
  orderDispatch({
    type: 'DELIVERY_STOP_ON_POINT',
    payload: {
      placeId,
      stoppedOrders: events.orders || {},
      userId,
      pointId,
    },
  });
  notificationDispatch({
    type: 'UPDATE_NOTIFICATION',
    payload: {
      notificationId: uuid,
      message,
      placeId,
      pointId,
      type: 'warning',
      date,
    },
  });

  pushNotification(message);
};
