import React, { useContext } from 'react';

import Toppings from '../../toppings/components/Toppings';

import { OrderStoreContext } from '../../orders/contexts/OrderStoreContext';

export default function Product({ product, active, index, disabled }) {
  const [state] = useContext(OrderStoreContext);

  const hasProduct = !active
    ? state.orders.history[index] &&
      state.orders.history[index].products &&
      state.orders.history[index].products[product.id]
    : state.orders.active.products[product.id];

  const count = hasProduct
    ? !active
      ? state.orders.history[index].products[product.id].length
      : state.orders.active.products[product.id].length
    : -1;

  return (
    <>
      <div className="p-rel f-c f-jcsb f-aic W100 pt-8 pb-8">
        <div className="f-c f-aic f-1 pr-32">
          <h3
            className={`f2b f08 mr-4 ${disabled ? 'txt-app-disabled' : 'txt-app'}`}
          >{`${count}x`}</h3>
          <h3 className={`f2 f08 ${disabled ? 'txt-app-disabled' : 'txt-app'}`}>{product.name}</h3>
        </div>
        <div className="f-c f-jcfe f-aic">
          <h3 className={`f2b f08 ${disabled ? 'txt-app-disabled' : 'txt-app'}`}>{`${
            count * product.cost
          }${product.currency}`}</h3>
        </div>
      </div>
      <Toppings product={product} confirm index={index} />
    </>
  );
}
