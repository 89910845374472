import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

const useEventsClick = () => {
  const history = useHistory();

  const handleClickUrl = (event) => {
    if (!event?.link?.url) return;
    window.open(event?.link?.url, '_blank');
  };

  const handleClickService = (event) => {
    if (!event?.link?.service) return;
    history.push(`./service/${event?.link?.service}`);
  };

  const handleClickCategory = (event) => {
    if (!event?.link?.category) return;
    history.push(`./category/${event?.link?.category}`);
  };

  const handleClickProduct = (event) => {
    if (!event?.link?.product) return;
    history.push(`./product/${event?.link?.product}`);
  };

  const handleNoClick = () => null;

  const clickHandler = (type, event) => {
    switch (type) {
      case 'url':
        return () => handleClickUrl(event);

      case 'service':
        return () => handleClickService(event);

      case 'category':
        return () => handleClickCategory(event);

      case 'product':
        return () => handleClickProduct(event);

      default:
        return handleNoClick;
    }
  };

  return { clickHandler };
};

export default useEventsClick;
