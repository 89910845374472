import i18n from '../../i18n';

const roAlergens = {
  1: `Cereale care conțin gluten (grâu, secară, orz, ovăz, grâu spelt, grâu mare, sau hibriziai acestora) și produse derivate`,
  2: `Crustacee și produse derivate`,
  3: `Ouă și produse derivate`,
  4: `Pește și produse derivate`,
  5: `Arahide și produse derivate`,
  6: `Soia și produse derivate`,
  7: `Lapte și produse derivate (inclusiv lactoza)`,
  8: `Fructe cu coajă, de exemplu migdale, alune de padure, nuci, fistic, nuci macadamia precum și produse derivate`,
  9: `Țelină și produse derivate`,
  10: `Muștar și produse derivate`,
  11: `Semințe de susan și produse derivate`,
  12: `Dioxid de sulf și sulfiți în concentrații de peste 10 mg/kg sau 10 mg/litru, exprimate în SO2`,
  13: `Lupin și produse derivate`,
  14: `Moluște și produse derivate`,
};

const enAlergens = {
  1: `Cereals containing gluten (wheat, rye, barley, oats, spelled wheat, buckwheat, or their hybrids) and derived products`,
  2: `Crustaceans and derived products`,
  3: `Eggs and derived products`,
  4: `Fish and fish products`,
  5: `Peanuts and their products`,
  6: `Soya and derived products`,
  7: `Milk and milk products (including lactose)`,
  8: `Nuts, e.g. almonds, hazelnuts, walnuts, pistachios, macadamia nuts and their derivatives`,
  9: `Celery and derived products`,
  10: `Mustard and derived products`,
  11: `Sesame seeds and derived products`,
  12: `Sulfur dioxide and sulphites in concentrations above 10 mg/kg or 10 mg/litre, expressed as SO2`,
  13: `Lupine and derived products`,
  14: `Molluscs and derived products`,
};

const grAlergens = {
  1: `Δημητριακά που περιέχουν γλουτένη (σίτος, σίκαλη, κριθάρι, βρώμη, σιτάρι, φαγόπυρο ή τα υβρίδια τους) και παράγωγα προϊόντα`,
  2: `Οστρακόδερμα και παράγωγα προϊόντα`,
  3: `Αυγά και παράγωγα προϊόντα`,
  4: `Ψάρια και προϊόντα ψαριών`,
  5: `Τα φιστίκια και τα προϊόντα τους`,
  6: `Σόγια και παράγωγα προϊόντα`,
  7: `Γάλα και γαλακτοκομικά προϊόντα (συμπεριλαμβανομένης της λακτόζης)`,
  8: `Ξηροί καρποί, π.χ. αμύγδαλα, φουντούκια, καρύδια, φιστίκια Αιγίνης, καρύδια macadamia και τα παράγωγά τους`,
  9: `Σέλινο και παράγωγα προϊόντα`,
  10: `Μουστάρδα και παράγωγα προϊόντα`,
  11: `Σουσάμι και παράγωγα προϊόντα`,
  12: `Διοξείδιο του θείου και θειώδη σε συγκεντρώσεις πάνω από 10 mg/kg ή 10 mg/λίτρο, εκφρασμένα σε SO2`,
  13: `Λούπινο και παράγωγα προϊόντα`,
  14: `Μαλάκια και παράγωγα προϊόντα`,
};

export const getAlergensHeader = () => {
  switch (i18n.language) {
    case 'ro':
      return {
        h3: `Substanțe care cauzează alergii sau intoleranțe alimentare:`,
        p: `Regulamentul UE 1169/2011`,
      };
    case 'en':
      return {
        h3: `Substanțe care cauzează alergii sau intoleranțe alimentare:`,
        p: `Regulamentul UE 1169/2011`,
      };
    case 'gr':
      return {
        h3: `Substanțe care cauzează alergii sau intoleranțe alimentare:`,
        p: `Regulamentul UE 1169/2011`,
      };
    default:
      return {
        h3: `Substanțe care cauzează alergii sau intoleranțe alimentare:`,
        p: `Regulamentul UE 1169/2011`,
      };
  }
};

export const getAlergensMap = () => {
  switch (i18n.language) {
    case 'ro':
      return roAlergens;
    case 'en':
      return enAlergens;
    case 'gr':
      return grAlergens;

    default:
      return enAlergens;
  }
};
