import React, { useContext } from 'react';
import { MenuButton } from '../../../lib/reusable/MenuButton';
import HorizontalScrollableList from './ScrollableXList';
import { RAFContext } from '../../../core/contexts/RAFContext';

const SubCategoryTags = ({ delimiters, action, setIsOnHomeScreen }) => {
  const { isMobile } = useContext(RAFContext);
  if (delimiters.length === 0) {
    return null;
  }

  return (
    <div className="f-c f-w p-16">
      <HorizontalScrollableList
        items={delimiters}
        action={action}
        isMobile={isMobile}
        setIsOnHomeScreen={setIsOnHomeScreen}
      />
    </div>
  );
};

export { SubCategoryTags };
