import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import OrderConfirmProduct from './OrderConfirmProduct';
import ReactModal from '../../../lib/reusable/ReactModal';

import { sendPendingOrder } from '../api/sendPendingOrder';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { WSContext } from '../../../core/websockets/WSContext';
import { OrderStoreContext } from '../../orders/contexts/OrderStoreContext';

let scrollLockX = 0;
let scrollLockY = 0;

function scrollBlock() {
  window.scrollTo(scrollLockX, scrollLockY);
}

const phoneRegex = new RegExp(/^\d{10}$/);
const limitRegex = new RegExp(/^[a-zA-Z0-9.&-@#_ ]*$/);

export default function OrderModal(props) {
  const { isVisible, closeModal } = props;
  const [state, orderDispatch] = useContext(OrderStoreContext);
  const {
    data: {
      place: { placeId, pointId, delivery, features },
      cache: {
        products: { productId__product },
      },
      userDevice,
      userId,
    },
  } = useContext(PlaceContext);

  const { WSConnection } = useContext(WSContext);

  const { hasPickup, hasDelivery } = delivery;
  const hasCard = Boolean(features?.delivery?.delivery?.payment?.card);
  const hasCash = Boolean(features?.delivery?.delivery?.payment?.cash);
  const hasLocality = Boolean(features?.delivery?.delivery?.extras?.defaultLocality);

  const defaultLocality = hasLocality ? features.delivery.delivery.extras.defaultLocality : '';

  // console.log('hasPickupHours', pickupHours, hasPickup);
  // console.log('hasDeliveryHours', deliveryHours, hasDelivery);

  const [deliveryType, setDeliveryType] = React.useState(hasPickup ? 'pickup' : 'delivery');
  const [paymentType, setPaymentType] = React.useState('cash');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [locality, setLocality] = React.useState(defaultLocality);
  const [street, setStreet] = React.useState('');
  const [others, setOthers] = React.useState('');
  const [errors, setErrors] = React.useState({});

  const { t } = useTranslation();

  let wrapperRef = useRef();

  const handlePhoneChange = (event) => {
    if (errors?.phone) {
      const newErrors = { ...errors };
      delete newErrors.phone;
      setErrors(newErrors);
    }
    if (event.target?.value?.length < 128) {
      setPhone(event.target.value);
    }
  };

  const handleLocalityChange = (event) => {
    if (errors?.locality) {
      const newErrors = { ...errors };
      delete newErrors.locality;
      setErrors(newErrors);
    }
    if (event.target?.value?.length < 128) {
      setLocality(event.target.value);
    }
  };

  const handleStreetChange = (event) => {
    if (errors?.street) {
      const newErrors = { ...errors };
      delete newErrors.street;
      setErrors(newErrors);
    }
    if (event.target?.value?.length < 128) {
      setStreet(event.target.value);
    }
  };

  const handleOthersChange = (event) => {
    if (errors?.others) {
      const newErrors = { ...errors };
      delete newErrors.others;
      setErrors(newErrors);
    }
    if (event.target?.value?.length < 128) {
      setOthers(event.target.value);
    }
  };

  const handleNameChange = (event) => {
    if (errors?.name) {
      const newErrors = { ...errors };
      delete newErrors.name;
      setErrors(newErrors);
    }
    if (event.target?.value?.length < 128) {
      setName(event.target.value);
    }
  };

  const handleCloseModal = () => {
    window.removeEventListener('scroll', scrollBlock, true);
    closeModal();
  };

  useEffect(() => {
    scrollLockX = window.scrollX;
    scrollLockY = window.scrollY;
    isVisible && window.addEventListener('scroll', scrollBlock, true);

    return () => {
      window.removeEventListener('scroll', scrollBlock, true);
    };
  });

  if (!isVisible || (!hasPickup && !hasDelivery)) return null;

  const orderProducts = Object.keys(state.orders.active.products);
  const products = [];
  const orderFormatProducts = [];
  let orderCost = 0;
  for (const productId in productId__product) {
    if (orderProducts.includes(productId)) {
      products.push(productId__product[productId]);
      for (const product of state.orders.active.products[productId]) {
        orderCost += productId__product[productId].cost;
        const toppingsFromProduct =
          product.toppings && typeof product.toppings === 'object'
            ? Object.keys(product.toppings)
            : [];
        const orderFormatProduct = {
          id: productId,
          service_id: productId__product[productId].service_id,
          toppings_ids: Array.isArray(toppingsFromProduct) ? toppingsFromProduct : [],
          discount: 0,
        };
        orderFormatProducts.push(orderFormatProduct);
      }
    }
  }

  const sendOrderToPlace = async () => {
    let details = {};
    const pointIsDelivery = pointId === 'delivery';
    if (pointIsDelivery) {
      const newErrors = {};

      if (phone.length < 10 || phone[0] !== '0' || !phoneRegex.test(phone)) {
        newErrors.phone = 'Invalid Phone Number';
      }

      if (name.length < 2) {
        newErrors.name = 'Invalid Name';
      }

      if (deliveryType === 'delivery' && hasLocality && locality.length > 256) {
        newErrors.locality = 'Invalid Locality';
      }

      if (
        deliveryType === 'delivery' &&
        (street.length < 3 || street.length > 256 || !limitRegex.test(street))
      ) {
        newErrors.street = 'Invalid street';
      }

      if (deliveryType === 'delivery' && (others.length > 256 || !limitRegex.test(others))) {
        newErrors.others = 'Invalid Others';
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }

      details = {
        delivery: {
          mode: pointIsDelivery ? 'delivery' : 'local',
          type: deliveryType,
          payment: pointIsDelivery ? paymentType : null,
          phone: `${phone}-C${String(new Date().getTime()).slice(-4)}`,
          name: name,
          locality: pointIsDelivery && hasLocality ? locality : null,
          street: pointIsDelivery ? street : null,
          others: pointIsDelivery ? others : null,
        },
      };
    }

    const { orderId } = state.orders.active;
    const data = {
      orderId,
      placeId,
      pointId: details?.delivery?.phone ? details?.delivery?.phone : pointId,
      userId,
      userDevice,
      products: orderFormatProducts,
      details,
    };
    const reducers = { orderDispatch };
    await sendPendingOrder(data, reducers, WSConnection);
    handleCloseModal();
  };

  const cancelOrder = () => {
    handleCloseModal();
  };

  const scrollOnFocus = (e) => {
    const bound = e.target.getBoundingClientRect();
    wrapperRef.current.scrollTo(0, bound.top - 128);
  };

  return (
    <ReactModal modalIsOpen={isVisible} closeModal={closeModal} scrollable>
      <div className="p-rel H100">
        <div
          className="p-abs p-top f-c f-jcc f-aic bg-info-light txt-white l2"
          style={{ height: 16 }}
        />

        <div
          className="p-abs p-full pl-16 pr-16 f-c f-dirc oflows l1 bg-info-light txt-white"
          style={{ top: 16, bottom: 60 }}
          ref={wrapperRef}
        >
          <div className="txt-white f-c f-dirc f-jcc l2 p-16 mb-8 br-8 bdf-2 bg-overlay-1">
            <div className="order-confirm-type f-c">
              {hasPickup && (
                <div className="f-c f-aic f-1">
                  <input
                    type="radio"
                    id="order-confirm-type-pickup"
                    className="mr-8"
                    name="delivery-type"
                    value="pickup"
                    onChange={(e) => setDeliveryType(e.target.value)}
                    checked={deliveryType === 'pickup'}
                  />
                  <label className="f2b f10" htmlFor="order-confirm-type-pickup">
                    Pickup
                  </label>
                </div>
              )}
              {hasDelivery && (
                <div className="f-c f-aic f-1">
                  <input
                    type="radio"
                    className="mr-8"
                    id="order-confirm-type-delivery"
                    name="delivery-type"
                    value="delivery"
                    onChange={(e) => setDeliveryType(e.target.value)}
                    checked={deliveryType === 'delivery'}
                  />
                  <label className="f2b f10" htmlFor="order-confirm-type-delivery">
                    Delivery
                  </label>
                </div>
              )}
            </div>

            {deliveryType === 'delivery' && (
              <div className="order-confirm-type f-c mt-32">
                {hasCash && (
                  <div className="f-c f-aic f-1">
                    <input
                      type="radio"
                      id="order-confirm-payment-type-cash"
                      className="mr-8"
                      name="payment-type-cash"
                      value="cash"
                      onChange={(e) => setPaymentType(e.target.value)}
                      checked={paymentType === 'cash'}
                    />
                    <label className="f2b f10" htmlFor="order-confirm-payment-type-cash">
                      Cash
                    </label>
                  </div>
                )}
                {hasCard && (
                  <div className="f-c f-aic f-1">
                    <input
                      type="radio"
                      className="mr-8"
                      id="order-confirm-payment-type-card"
                      name="payment-type-card"
                      value="card"
                      onChange={(e) => setPaymentType(e.target.value)}
                      checked={paymentType === 'card'}
                    />
                    <label className="f2b f10" htmlFor="order-confirm-payment-type-card">
                      Card
                    </label>
                  </div>
                )}
              </div>
            )}

            <div className="mt-16 mb-8">
              <div className="f-c f-dirc mt-16">
                <label className="f2 f08 mb-8" htmlFor="order-confirm-name">
                  <b className="mr-4 txt-danger">*</b>Nume / Prenume
                </label>
                <input
                  required
                  type="text"
                  id="order-confirm-name"
                  className="f10 f1 txt-app bb-app pb-4"
                  value={name}
                  onChange={handleNameChange}
                  onFocus={scrollOnFocus}
                />
              </div>
              {errors.name && <div className="f10 f2b txt-danger mt-4">{errors.name}</div>}
              <div className="f-c f-dirc mt-16">
                <label className="f2 f08 mb-8" htmlFor="order-confirm-phone">
                  <b className="mr-4 txt-danger">*</b>Telefon
                </label>
                <input
                  required
                  type="tel"
                  id="order-confirm-phone"
                  className="f10 f1 txt-app bb-app pb-4"
                  value={phone}
                  onChange={handlePhoneChange}
                  onFocus={scrollOnFocus}
                />
              </div>
              {errors.phone && <div className="f10 f2b txt-danger mt-4">{errors.phone}</div>}
              {deliveryType !== 'pickup' && hasLocality && (
                <>
                  <div className="f-c f-dirc mt-16 pt-4">
                    <label className="f2 f08 mb-8" htmlFor="order-confirm-locality">
                      Localitate
                    </label>
                    <input
                      required
                      type="text"
                      id="order-confirm-locality"
                      className="f10 f1 txt-app bb-app pb-4"
                      value={locality}
                      onChange={handleLocalityChange}
                      onFocus={scrollOnFocus}
                    />
                  </div>
                  {errors.locality && (
                    <div className="f10 f2b txt-danger mt-4">{errors.locality}</div>
                  )}
                </>
              )}
              {deliveryType !== 'pickup' && (
                <>
                  <div className="f-c f-dirc mt-16 pt-4">
                    <label className="f2 f08 mb-8" htmlFor="order-confirm-street">
                      <b className="mr-4 txt-danger">*</b>Adresa
                    </label>
                    <input
                      required
                      type="text"
                      id="order-confirm-street"
                      className="f10 f1 txt-app bb-app pb-4"
                      value={street}
                      onChange={handleStreetChange}
                      onFocus={scrollOnFocus}
                    />
                  </div>
                  {errors.street && <div className="f10 f2b txt-danger mt-4">{errors.street}</div>}
                </>
              )}
              {deliveryType !== 'pickup' && (
                <>
                  <div className="f-c f-dirc mt-16 pt-4">
                    <label className="f2 f08 mb-8" htmlFor="order-confirm-others">
                      Alte Detalii
                    </label>
                    <input
                      required
                      type="text"
                      id="order-confirm-others"
                      className="f10 f1 txt-app bb-app pb-4"
                      value={others}
                      onChange={handleOthersChange}
                      onFocus={scrollOnFocus}
                    />
                  </div>
                  {errors.others && <div className="f10 f2b txt-danger mt-4">{errors.others}</div>}
                </>
              )}
            </div>
          </div>
          <h3 className="f2 f10 f-1 pt-16 pb-16 txt-app">ORDER DETAILS</h3>
          {products.map((product) => {
            return (
              <div key={product.id} className="p-rel W100 bb-app">
                <OrderConfirmProduct product={product} active confirm />
              </div>
            );
          })}
          <div className="f-c f-jcsb W100">
            <h3 className="f2 f10 f-1 pt-16 pb-16 pl-4 txt-app">
              {t('interface.orders.confirm_order_total')}
            </h3>
            <h3 className="f2b f10 f-1 ta-c pt-16 pb-16 pl-16 mr-2 ta-e txt-app">{`${orderCost} lei`}</h3>
          </div>
          <div className="minH300" />
        </div>

        <div className="p-abs p-bottom l2">
          <div className="f-c f-jcsb f-aic ta-c" style={{ height: 60 }}>
            <div
              className="p-rel m0 p0 ta-c f-c f-1 f-jcsb f-aic WH100 bg-info-light txt-app-dim"
              onClick={cancelOrder}
            >
              <h3 className="f2b f08 f-1 ta-c pt-16 pb-16 pl-16 mr-2 ta-c">
                {t('interface.basic.back').toUpperCase()}
              </h3>
            </div>

            <div
              className="p-rel m0 p0 ta-c f-c f-1 f-jcsb f-aic WH100 bg-info-light txt-app"
              onClick={sendOrderToPlace}
            >
              <h3
                className={`f2b f08 f-1 ta-c pt-16 pb-16 pl-16 mr-2 ta-c${
                  Object.keys(errors).length > 0 ? ' txt-danger' : 'txt-white'
                }`}
              >
                TRIMITE COMANDA
              </h3>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
