export const placeContextObject = {
  place: {
    address: '',
    email: '',
    facebook: '',
    tripadvisor: '',
    bookingham: '',
    has_image: false,
    instagram: '',
    google: '',
    latitude: 0,
    longitude: 0,
    mobile: '',
    inIframe: false,
    name: '',
    place_id: '',
    active: true,
    calling: false,
    ordering: false,
    timezone: 'Europe/Bucharest',
    delivery: {
      hasPickup: false,
      hasDelivery: false,
    },
  },
  cache: {
    top: [],
    events: {
      services: [],
      categories: [],
      subCategories: [],
      products: {},
      main: [],
    },
    services: {
      serviceId__service: {},
      serviceId__categories: {},
      serviceId__images: [],
    },
    products: {
      productId__product: {},
    },
    categories: {
      categoryId__category: {},
      categoryId__images: {},
      categoryId__products: {},
      nestedId__categories: {},
      categoryId__extraProducts: {},
    },
    toppings: {
      toppingId__topping: {},
      toppingsGroupId__toppingsGroup: {},
      toppingsGroupId__toppings: {},
      productId__toppingsGroupId: {},
    },
    index: {
      name__product: {},
      names: [],
      tag__products: {},
      tags: [],
    },
  },
};
