/* eslint-disable react/no-children-prop */
import React, { useContext } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { RoundButtonSimple } from '../../../lib/reusable/RoundButtonSimple';

import { SAFContext } from '../../../core/contexts/SAFContext';

const ScrollToTop = () => {
  const { scrollY } = useContext(SAFContext);

  const scrollToTop = () => window.scrollTo(0, 0);

  if (scrollY < 100) return null;

  return (
    <div className="p-fix" style={{ bottom: '84px', right: 'calc(50% - 13px)', opacity: 0.64 }}>
      <RoundButtonSimple action={scrollToTop} size={16}>
        <div className="f-c f-jcc f-aic p-16">
          <KeyboardArrowUpIcon size={24} sx={{ color: '#000000A0' }} />
        </div>
      </RoundButtonSimple>
    </div>
  );
};

export { ScrollToTop };
