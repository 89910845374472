import React from 'react';
import { animated } from 'react-spring';

const Notification = ({ id, message, action, type, date }) => {
  const classes = {
    success: 'bg-success-dim',
    info: 'bg-info-dim',
    warning: 'bg-warning-dim',
    danger: 'bg-danger-dim',
  };

  const handleOk = (e) => {
    e.stopPropagation();
    action(id);
  };

  const dateRaw = date ? new Date(date) : 0;
  const parsedDate = dateRaw ? dateRaw.toTimeString().split(' ')[0] : 0;

  const notificationClasses = `p-rel oflowh br-4 mt-4 mb-8 ml-16 mr-16 box-bb W100 ${
    classes[type] || classes.info
  }`;

  return (
    <animated.div className={notificationClasses} style={{ width: 'calc(100% - var(--d16)' }}>
      <div className="f-c f-jcsb f-aic p-8 br-4 oflowh" style={{ opacity: 0.9 }}>
        <div className="f-c f-dirc">
          {date !== 0 && (
            <h3 className="f2 f08 no-select pb-16 fds-1 txt-notifications f-lsc-2">{parsedDate}</h3>
          )}
          <h3 className="f2b f09 f-1 no-select fds-1 mr-8 txt-notifications f-lsc-2">{message}</h3>
        </div>
        <div
          className="f-c f-jcc f-aic"
          onClick={handleOk}
          style={{ minWidth: '20%', minHeight: 60 }}
        >
          <h3 className="f2 f09 pb-4 txt-notifications ta-c no-select">OK</h3>
        </div>
      </div>
    </animated.div>
  );
};

export { Notification };
