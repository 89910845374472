import React, { useState, useEffect } from 'react';

let timeout = null;

const eventTriggerResizeFrame = new Event('custom-rezise-af-event');
// Resize context
export const RAFContext = React.createContext();
export const RAFProvider = ({ children }) => {
  const [data, setData] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < 600,
  });

  useEffect(() => {
    const handler = function (event) {
      // If there's a timer, cancel it
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }

      // Setup the new requestAnimationFrame()
      timeout = window.requestAnimationFrame(function () {
        // Run our scroll functions
        setData({
          width: window.innerWidth,
          height: window.innerHeight,
          isMobile: window.innerWidth < 600,
        });
        window.dispatchEvent(eventTriggerResizeFrame);
      }, 200);
    };
    window.addEventListener('resize', handler, false);

    return () => {
      window.removeEventListener('resize', handler, false);
    };
  }, []);

  return <RAFContext.Provider value={{ ...data }}>{children}</RAFContext.Provider>;
};
