import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { SearchStoreContext } from '../../../features/search/contexts/SearchStoreContext';
import { RAFContext } from '../../../core/contexts/RAFContext';

const Tag = ({ tag }) => {
  const history = useHistory();
  const [, searchDispatch] = useContext(SearchStoreContext);
  const { isMobile } = useContext(RAFContext);

  const searchTag = (tagName) => {
    searchDispatch({
      type: 'SET_SEARCHING',
      payload: { searching: tagName.toLowerCase() },
    });
    history.push('/search');
  };

  const size = isMobile ? 32 : 64;

  return (
    <div
      key={tag}
      className="f-c f-aic mt-8 mr-16 br-4 bg-tags"
      onClick={() => searchTag(tag)}
      style={{ height: size }}
    >
      <div
        className={`f2b ${!isMobile ? 'f12' : 'f08'} br-4 pc-lr t-LC txt-tags bg-tags`}
        style={{ height: size, lineHeight: `${size}px` }}
      >
        {tag}
      </div>
      <LocalOfferIcon className="s-16-i txt-tags pl-4 pr-4" />
    </div>
  );
};

export default Tag;
