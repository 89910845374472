import React, { useReducer } from 'react';
import SearchReducer from './SearchReducer';

const initialState = {
  searching: '',
};

export const SearchStoreContext = React.createContext(initialState);
export const SearchStore = ({ children }) => {
  const [state, dispatch] = useReducer(SearchReducer, initialState);
  return (
    <SearchStoreContext.Provider value={[state, dispatch]}>{children}</SearchStoreContext.Provider>
  );
};

export default SearchStore;
