import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { NotificationStoreContext } from '../../notifications/contexts/NotificationStoreContext';
import { CallStoreContext } from '../contexts/CallStoreContext';
import { initiateCall } from '../api/initiateCall';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { WSContext } from '../../../core/websockets/WSContext';
import { RAFContext } from '../../../core/contexts/RAFContext';

const CallActions = () => {
  const {
    data: {
      place: { placeId, pointId, features },
      userId,
      userDevice,
    },
  } = useContext(PlaceContext);
  const { width: vWidth, height: vHeight } = useContext(RAFContext);
  const [, notificationDispatch] = useContext(NotificationStoreContext);
  const [callState, callDispatch] = useContext(CallStoreContext);
  const [heightBtnBig, setHeightBtnBig] = useState(null);
  const [heightBtnSmall, setHeightBtnSmall] = useState(null);
  const { WSConnection } = useContext(WSContext);
  const history = useHistory();

  const { t } = useTranslation();
  const reducers = { callState, callDispatch, notificationDispatch };

  let refBtnBig = useRef(null);
  let refBtnSmall = useRef(null);

  const hasCallToPoint = Boolean(features?.calls['call-to-point']);
  const hasPayCash = Boolean(features?.calls['pay-cash']);
  const hasPayCard = Boolean(features?.calls['pay-card']);

  const setCircleSize = (set, element) => {
    const { height } = element.getBoundingClientRect();
    if (height) {
      const newHeight = Math.round(height - 16);
      set(newHeight);
    }
  };

  useEffect(() => {
    if (refBtnBig?.current) {
      setCircleSize(setHeightBtnBig, refBtnBig.current);
    }
  }, [refBtnBig.current, vWidth, vHeight]);

  useEffect(() => {
    if (refBtnSmall?.current) {
      setCircleSize(setHeightBtnSmall, refBtnSmall.current);
    }
  }, [refBtnSmall.current, vWidth, vHeight]);

  const callWaiter = async () => {
    const data = { placeId, userId, pointId, userDevice, details: {} };
    await initiateCall(data, reducers, WSConnection);
    history.goBack();
  };

  const callCash = async () => {
    const data = {
      placeId,
      userId,
      pointId,
      userDevice,
      details: { payment: { cash: true } },
    };
    await initiateCall(data, reducers, WSConnection);
    history.goBack();
  };

  const callCard = async () => {
    const data = {
      placeId,
      userId,
      pointId,
      userDevice,
      details: { payment: { card: true } },
    };
    await initiateCall(data, reducers, WSConnection);
    history.goBack();
  };

  return (
    <div className="p-rel f-c f-1 f-dirc f-aic f-jcc oflowh mc-lr pb-32">
      <div className="f-c f-15 m-8 H100 l1" ref={refBtnBig}>
        {hasCallToPoint && heightBtnBig && (
          <button
            className="p-rel f-c f-jcc f-aic mp0 no-border no-select cursor-default bg-call-to-point p-16 fds-3 br-f"
            onClick={callWaiter}
            style={{ width: heightBtnBig, height: heightBtnBig }}
          >
            <div className="p-8">
              <p
                className="f2b f12 t-UC lh10 txt-call-to-point"
                style={{
                  fontSize: 'calc(16px + 1vw)',
                  marginBottom: 8,
                  lineHeight: 'calc(16px + 0.64vw)',
                }}
              >
                {t('calls.CALL')}
              </p>
              <p
                className="f2b f12 t-UC lh10 txt-call-to-point"
                style={{ fontSize: 'calc(10px + 0.32vw)', lineHeight: 'calc(10px + 0.36vw)' }}
              >
                {t('calls.CALL_INFO')}
              </p>
            </div>
          </button>
        )}
      </div>
      <div className="f-c f-1 l1" style={{ marginTop: heightBtnSmall ? -(heightBtnSmall / 4) : 0 }}>
        <div className="l1 WH100 m-8" ref={refBtnSmall}>
          {hasPayCash && heightBtnSmall && (
            <button
              className="p-rel f-c f-jcc f-aic mp0 no-border no-select cursor-default bg-call-cash p-8 fds-3 WH100 br-f"
              onClick={callCash}
              style={{
                width: heightBtnSmall,
                height: heightBtnSmall,
                marginRight: heightBtnSmall / 4,
              }}
            >
              <div className="p-8">
                <p
                  className="f2b f10 t-UC lh10 txt-call-cash"
                  style={{ fontSize: 'calc(12px + 0.5vw)', marginBottom: 8 }}
                >
                  {t('calls.CASH')}
                </p>
                <p
                  className="f2b f10 t-UC lh10 txt-call-cash"
                  style={{ fontSize: 'calc(9px + 0.32vw)', lineHeight: 'calc(9px + 0.36vw)' }}
                >
                  {t('calls.CASH_INFO')}
                </p>
              </div>
            </button>
          )}
        </div>
        <div className="l1 WH100 m-8">
          {hasPayCard && heightBtnSmall && (
            <button
              className="p-rel f-c f-jcc f-aic mp0 no-border no-select cursor-default bg-call-card p-8 fds-3 WH100 br-f"
              onClick={callCard}
              style={{ width: heightBtnSmall, height: heightBtnSmall }}
            >
              <div className="p-8">
                <p
                  className="f2b f10 t-UC lh10 txt-call-card"
                  style={{ fontSize: 'calc(12px + 0.5vw)', marginBottom: 8 }}
                >
                  {t('calls.CARD')}
                </p>
                <p
                  className="f2b f10 t-UC lh10 txt-call-card"
                  style={{ fontSize: 'calc(9px + 0.32vw)', lineHeight: 'calc(9px + 0.36vw)' }}
                >
                  {t('calls.CARD_INFO')}
                </p>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const TranslatedComponent = withTranslation()(CallActions);
export { TranslatedComponent as CallActions };
