import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../../core/functions/utilities';

import Category from '../../features/menu/components/Category';

const getCategoryIdFromPathname = (pathname) => {
  try {
    const params = pathname.split('/');
    return params[params.length - 1];
  } catch (error) {
    return null;
  }
};

const CategoryPage = () => {
  const { pathname } = useLocation();
  const [categoryId, setCategoryId] = useState(getCategoryIdFromPathname(pathname));

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    setCategoryId(getCategoryIdFromPathname(pathname));
  }, [pathname]);

  return <Category categoryId={categoryId} />;
};
export default CategoryPage;
