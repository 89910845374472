import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { setWithExpiry } from '../../../../core/functions/localStorage';

// 1h(in ms) * (how many hours you want)
const ttl = 3600000 * 6;

const addProduct = (state, payload) => {
  const newState = _.cloneDeep(state);
  if (newState.orders.active.products[payload.product.id] === undefined) {
    newState.orders.active.products[payload.product.id] = [{ toppings: [] }];
  } else {
    newState.orders.active.products[payload.product.id].push({ toppings: [] });
  }
  setWithExpiry(
    `order-${payload.pointId}-${payload.placeId}`,
    JSON.stringify(newState.orders.active),
    ttl,
  );
  return newState;
};

const removeProduct = (state, payload) => {
  if (state.orders.active.products[payload.productId] === undefined) {
    return state;
  }

  const newState = _.cloneDeep(state);
  if (newState.orders.active.products[payload.productId].length === 1) {
    delete newState.orders.active.products[payload.productId];
  } else {
    newState.orders.active.products[payload.productId].pop();
  }
  setWithExpiry(
    `order-${payload.pointId}-${payload.placeId}`,
    JSON.stringify(newState.orders.active),
    ttl,
  );
  return newState;
};

const removeProductByIndex = (state, payload) => {
  if (state.orders.active.products[payload.productId] === undefined) {
    return state;
  }

  const newState = _.cloneDeep(state);
  newState.orders.active.products[payload.productId].splice(payload.index, 1);
  if (newState.orders.active.products[payload.productId].length === 0) {
    delete newState.orders.active.products[payload.productId];
  }
  setWithExpiry(
    `order-${payload.pointId}-${payload.placeId}`,
    JSON.stringify(newState.orders.active),
    ttl,
  );
  return newState;
};

const removeProductAll = (state, payload) => {
  if (state.orders.active.products[payload.productId] === undefined) {
    return state;
  }

  const newState = _.cloneDeep(state);
  delete newState.orders.active.products[payload.productId];
  setWithExpiry(
    `order-${payload.pointId}-${payload.placeId}`,
    JSON.stringify(newState.orders.active),
    ttl,
  );
  return newState;
};

const setToppingsToProduct = (state, payload) => {
  if (state.orders.active.products[payload.productId] === undefined) {
    return state;
  }

  const { placeId, productId, toppings, position } = payload;

  const newState = _.cloneDeep(state);
  newState.orders.active.products[productId][position].toppings = toppings;
  setWithExpiry(`order-${payload.pointId}-${placeId}`, JSON.stringify(newState.orders.active), ttl);
  return newState;
};

const confirmReceivedOrderByPlace = (state, payload) => {
  const { orderId, placeId } = payload;
  const newState = _.cloneDeep(state);
  for (const order of newState.orders.history) {
    if (order.orderId === orderId) {
      order.status = 'pending';
      continue;
    }
  }
  setWithExpiry(`order-${payload.pointId}-${placeId}`, JSON.stringify(newState.orders.active), ttl);
  return newState;
};

const confirmOrderNotReceivedFormPlace = (state, payload) => {
  const { orderId, placeId } = payload;
  const newState = _.cloneDeep(state);
  for (const order of newState.orders.history) {
    if (order.orderId === orderId) {
      order.status = 'notsent';
      continue;
    }
  }
  setWithExpiry(`order-${payload.pointId}-${placeId}`, JSON.stringify(newState.orders.active), ttl);
  return newState;
};

const confirmActiveOrder = (state, payload) => {
  const newState = _.cloneDeep(state);
  const { placeId, cost, pointId, userId, orderId } = payload;
  const notTheActiveOrder = orderId !== state.orders.active.orderId;

  if (orderId !== undefined && notTheActiveOrder) {
    for (const order of newState.orders.history) {
      if (order.orderId === orderId) {
        order.status = 'sending';
        break;
      }
    }
    setWithExpiry(
      `order-${payload.pointId}-${placeId}`,
      JSON.stringify(newState.orders.active),
      ttl,
    );
    return newState;
  }

  const hasProducts = Object.keys(state.orders.active.products).length > 0;
  if (!hasProducts && orderId === undefined) {
    return state;
  }

  const newConfirmedActiveOrder = {
    orderId: state.orders.active.orderId,
    status: 'sending',
    date: new Date().getTime(),
    products: state.orders.active.products,
    cost,
    orderNo: state.orders.active.orderNo,
    orderCode: state.orders.active.orderCode,
    discount: 0,
  };

  const nextOrderIndex = state.orders.orderIndex + 1;
  const newActiveOrderId = uuidv4();
  const newOrders = {
    orderIndex: nextOrderIndex,
    history: [...state.orders.history, newConfirmedActiveOrder],
    active: {
      orderId: newActiveOrderId,
      orderCode: `${pointId}-${newActiveOrderId.substring(0, 4)}`.toUpperCase(),
      placeId,
      userId,
      orderNo: nextOrderIndex,
      pointId,
      status: 'active',
      date: 0,
      products: {},
      cost: 0,
      discount: 0,
    },
  };

  newState.orders = newOrders;
  setWithExpiry(`order-${payload.pointId}-${placeId}`, JSON.stringify(newState.orders.active), ttl);
  return newState;
};

const approveOrder = (state, payload) => {
  if (!payload.confirmedOrders) {
    return state;
  }

  const newState = _.cloneDeep(state);

  const { confirmedOrders } = payload;

  for (const order of newState.orders.history) {
    if (confirmedOrders[order.orderId] !== undefined) {
      order.status = 'approved';
    }
  }

  return newState;
};

const approveDeliveryOrder = (state, payload) => {
  if (!payload.confirmedOrders) {
    return state;
  }

  const newState = _.cloneDeep(state);

  const { confirmedOrders } = payload;

  for (const order of newState.orders.history) {
    if (confirmedOrders[order.orderId] !== undefined) {
      order.status = 'approved-delivery';
    }
  }

  return newState;
};

const stopDeliveryOrder = (state, payload) => {
  if (!payload.stoppedOrders) {
    return state;
  }

  const newState = _.cloneDeep(state);

  const { stoppedOrders } = payload;

  for (const order of newState.orders.history) {
    if (stoppedOrders[order.orderId] !== undefined) {
      order.status = 'pending';
    }
  }

  return newState;
};

const rejectOrder = (state, payload) => {
  if (!payload.rejectedOrders) {
    return state;
  }

  const newState = _.cloneDeep(state);

  const { rejectedOrders } = payload;

  for (const order of newState.orders.history) {
    if (rejectedOrders[order.orderId] !== undefined) {
      order.status = 'rejected';
    }
  }

  return newState;
};

const cancelOrder = (state, payload) => {
  if (!payload.orderId) {
    return state;
  }

  const { placeId } = payload;
  const newState = _.cloneDeep(state);

  const history = state.orders.history.filter((order) => !(order.orderId === payload.orderId));

  newState.orders.history = history;

  setWithExpiry(`order-${payload.pointId}-${placeId}`, JSON.stringify(newState.orders.active), ttl);
  return newState;
};

export {
  addProduct,
  removeProduct,
  removeProductAll,
  removeProductByIndex,
  setToppingsToProduct,
  confirmActiveOrder,
  approveOrder,
  approveDeliveryOrder,
  stopDeliveryOrder,
  rejectOrder,
  cancelOrder,
  confirmReceivedOrderByPlace,
  confirmOrderNotReceivedFormPlace,
};
