const handleHasConnection = (orderDispatch, data) => {
  const { orderId, placeId, pointId, userId } = data;
  window.__TIMEOUTS__.orders.initiated[orderId] !== undefined &&
    clearTimeout(window.__TIMEOUTS__.orders.initiated[orderId]);
  window.__TIMEOUTS__.orders.initiated[orderId] = setTimeout(() => {
    orderDispatch({
      type: 'ORDER_CONFIRM_ACTIVE_NOT_RECEIVED',
      payload: {
        orderId,
        userId,
        placeId,
        pointId,
      },
    });
  }, 10000);
  orderDispatch({
    type: 'ORDER_CONFIRM_ACTIVE',
    payload: {
      orderId,
      placeId,
      pointId,
      userId,
    },
  });
};

const handleNoConnection = (orderDispatch, data) => {
  const { orderId, placeId, pointId, userId } = data;
  window.__TIMEOUTS__.orders.initiated[orderId] !== undefined &&
    clearTimeout(window.__TIMEOUTS__.orders.initiated[orderId]);
  orderDispatch({
    type: 'ORDER_CONFIRM_ACTIVE',
    payload: {
      orderId,
      placeId,
      pointId,
      userId,
    },
  });
  orderDispatch({
    type: 'ORDER_CONFIRM_ACTIVE_NOT_RECEIVED',
    payload: {
      orderId,
      userId,
      placeId,
      pointId,
    },
  });
};

export const sendPendingOrder = async (data, reducers, WSConnection) => {
  const { orderId, placeId, pointId, userId, userDevice, products, details } = data;
  const { orderDispatch } = reducers;
  const services = {};
  for (const product of products) {
    services[product.service_id] = 'requested';
  }
  const payload = {
    orderId,
    placeId,
    pointId,
    userId,
    userDevice,
    products,
    services,
    details,
  };

  const newOrder = JSON.stringify({
    type: 'ORDER_NEW',
    payload,
  });

  if (!WSConnection) {
    handleNoConnection(orderDispatch, data);
    return;
  }

  try {
    await WSConnection.send(newOrder);
  } catch (error) {
    handleNoConnection(orderDispatch, data);
    return;
  }

  handleHasConnection(orderDispatch, data);
};
