import React, { useEffect } from 'react';
import { scrollToTop } from '../../core/functions/utilities';

import TermsAndConditions from '../../features/terms-and-conditions/TermsAndConditions';

const TCPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return <TermsAndConditions />;
};

export default TCPage;
