import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import MaxCharsComponent from './MaxCharsComponent';
import './ScrollableXList.css';

const HorizontalScrollableList = ({ items, action, isMobile, setIsOnHomeScreen }) => {
  const [clickedItemId, setClickedItemId] = useState(null);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOnHomeScreen(window.location.pathname === '/');
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  return (
    <div className={isMobile ? 'scroll-list-container mobile' : 'scroll-list-container'}>
      {items.map((item, index) => {
        return (
          <div
            key={index}
            className="mr-8 mb-8"
            style={{
              display: 'inline-block',
              maxWidth: '100%',
              whiteSpace: 'normal',
              '@media (width: 150px)': {
                maxWidth: '150px',
                whiteSpace: 'nowrap',
              },
            }}
          >
            <Button
              sx={{
                display: 'flex',
                color: 'black',
                fontWeight: 'bold',
                letterSpacing: '-0.5px',
                backgroundColor: 'white',
                height: '50px',
                lineHeight: 1,
                '&:hover': {
                  backgroundColor: isMobile ? 'white' : '#90EE90',
                  color: isMobile ? 'black' : 'white',
                },
              }}
              variant="contained"
              onClick={() => {
                setClickedItemId(item.id);
                return action({ categoryId: item.id });
              }}
            >
              {item.name ? (
                <MaxCharsComponent
                  text={item.name}
                  maxChars={32}
                  shouldBeOutlined={clickedItemId === item.id}
                />
              ) : (
                ''
              )}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default HorizontalScrollableList;
