import i18next from 'i18next';

export const errorOnCancelingOrder = async (payload, reducers, data) => {
  const { placeId, pointId } = data;
  const { notificationDispatch } = reducers;
  const { orderId } = payload;

  const message = i18next.t('orders.NOTIFICATIONS.error_on_cancel');
  const date = new Date().getTime();
  const newNotification = new CustomEvent('Notification', {
    detail: { message, type: 'danger', orderId, date },
  });
  window.dispatchEvent(newNotification);
  notificationDispatch({
    type: 'UPDATE_NOTIFICATION',
    payload: {
      notificationId: orderId,
      message,
      placeId,
      pointId,
      type: 'danger',
      date,
    },
  });
};
