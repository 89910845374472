import axios from 'axios';
import { URI } from '../config';

export const getExchange = async () => {
  try {
    const url = `${URI.exchange}`;
    const data = await axios.get(url);
    const {
      status,
      data: { currencies },
    } = data;

    if (status !== 200) {
      console.error('ERROR ON GET EXCHANGE', status);
      return;
    }

    return currencies;
  } catch (error) {
    console.error('ERROR ON GET EXCHANGE', error);
    return null;
  }
};
