import React, { useContext } from 'react';

import {
  RemoveItem,
  CancelItems,
  IWantItem,
  AddItem,
} from '../../../lib/reusable/AddRemoveButtons';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { OrderStoreContext } from '../../orders/contexts/OrderStoreContext';

const Get = ({ count, action }) => {
  return (
    <div className="f-c f-jcc f-aic">
      <div className="p-rel f-jcfe f-asfe l2 H100">
        <IWantItem action={action} count={count} />
      </div>
    </div>
  );
};

const Quantity = ({ count, add, subtract, cancel, hasToppings, disabled }) => {
  return (
    <div className="f-c f-jcsb f-aic" style={{ width: '148px' }}>
      <div className="f-c f-jcc f-aic">
        {!hasToppings ? (
          <div className="p-rel f-jcfe f-asfe l2">
            <RemoveItem action={subtract} disabled={disabled} />
          </div>
        ) : (
          <div className="p-rel f-jcfe f-asfe l2">
            <CancelItems action={cancel} disabled={disabled} />
          </div>
        )}
      </div>
      {!disabled ? (
        <div className="f-c f-jcc f-aic l1">
          <h1 className="f2b f12 txt-danger bg-white p-4 pl-8 pr-8 br-2">{`+${count}`}</h1>
        </div>
      ) : null}
      <div className="p-rel f-jcfe f-asfe l2">
        <AddItem action={add} count={count} />
      </div>
    </div>
  );
};

export default function ProductOrdering({ product, style, showOrder, noImage, compact }) {
  const [state, dispatch] = useContext(OrderStoreContext);
  const {
    data: {
      place: { placeId, pointId },
      cache: {
        toppings: { toppingsId__products },
      },
    },
  } = useContext(PlaceContext);

  const hasValidOrderPoint = !(pointId === 'default') || pointId === 'delivery';

  if (!product || !hasValidOrderPoint) {
    return null;
  }

  const addProductToOrder = (event) => {
    event.stopPropagation();
    dispatch({
      type: 'ADD_PRODUCT',
      payload: {
        product,
        placeId,
        pointId,
      },
    });
  };

  const removeProductFromOrder = (event) => {
    event.stopPropagation();
    dispatch({
      type: 'REMOVE_PRODUCT',
      payload: {
        productId: product.id,
        placeId,
        pointId,
      },
    });
  };

  const removeProductFromOrderAll = (event) => {
    event.stopPropagation();
    dispatch({
      type: 'REMOVE_PRODUCT_ALL',
      payload: {
        productId: product.id,
        placeId,
        pointId,
      },
    });
  };

  const hasToppings = toppingsId__products && toppingsId__products[product.id];

  const count = state.orders.active?.products?.[product.id]?.length || 0;
  const hasRequestedProduct = count > 0;
  const noProduct = state.orders.active.products[product.id] === undefined;

  const extraPosition = compact ? {} : {};

  return (
    <div
      className="p-abs f-c f-jcc f-aic f-dirc bg-white br-4"
      style={{ ...style, ...extraPosition }}
    >
      {showOrder && hasRequestedProduct && !noImage && (
        <div
          className="f-c f-jcc f-aic bg-danger W100 l1"
          style={{
            height: 32,
            borderRadius: '0 0 2px 2px',
          }}
        >
          <h1 className="f2b f12 txt-app">
            {`${count * product.cost}`}
            {` ${product.currency}`}
          </h1>
        </div>
      )}
      {hasRequestedProduct ? (
        <Quantity
          count={count}
          add={addProductToOrder}
          subtract={removeProductFromOrder}
          cancel={removeProductFromOrderAll}
          hasToppings={hasToppings}
          disabled={noProduct}
        />
      ) : (
        <Get count={count} action={addProductToOrder} />
      )}
    </div>
  );
}
