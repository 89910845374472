import React, { useState } from 'react';

const initialState = {
  bottomNavigation: true,
};

export const InterfaceContext = React.createContext();
export const InterfaceProvider = ({ children }) => {
  const [data, setData] = useState(initialState);

  const setBottomNavigation = (visible) => {
    const newState = { ...data, bottomNavigation: visible };
    setData(newState);
  };

  return (
    <InterfaceContext.Provider value={[data, setBottomNavigation]}>
      {children}
    </InterfaceContext.Provider>
  );
};
