import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ReactModal from '../../../lib/reusable/ReactModal';
import { PlaceContext } from '../../../core/contexts/PlaceContext';

let scrollLockX = 0;
let scrollLockY = 0;

function scrollBlock() {
  window.scrollTo(scrollLockX, scrollLockY);
}

export default function ToppingsModal(props) {
  const { isVisible, closeModal, productId } = props;
  const {
    data: {
      cache: {
        toppings,
        products: { productId__product },
      },
    },
  } = useContext(PlaceContext);

  const { t } = useTranslation();

  useEffect(() => {
    scrollLockX = window.scrollX;
    scrollLockY = window.scrollY;
    isVisible && window.addEventListener('scroll', scrollBlock, true);

    return () => {
      window.removeEventListener('scroll', scrollBlock, true);
    };
  });

  if (!isVisible) return null;

  const closeHandler = () => {
    window.removeEventListener('scroll', scrollBlock, true);
    closeModal();
  };

  const product = productId__product[productId];

  const toppingsList =
    toppings &&
    toppings.toppingsGroupId__toppings &&
    toppings.toppingsGroupId__toppings[product.toppings_group_id]
      ? toppings.toppingsGroupId__toppings[product.toppings_group_id]
      : [];

  return (
    <ReactModal modalIsOpen={isVisible} closeModal={closeModal} scrollable>
      <div
        className="p-fix p-full H100v W100 no-border bg-toppings txt-toppings"
        onClick={closeModal}
      />
      <div className="p-fix p-full f-c f-dirc br-8 txt-toppings l1">
        <div className="p-fix p-full f-c f-jcc f-aic ta-c" style={{ height: 60 }}>
          <div className="f-c f-jcc f-aic ta-c" style={{ borderRadius: '0px 0px 8px 8px' }}>
            <p className="f2 f08 pr-4">Extras</p>
            <p className="f2 f08">{product.name}</p>
          </div>
        </div>

        <div className="p-rel f-c f-dirc ml-4 mr-4 mt-64 mb-64 oflows l1">
          <div className="f-c f-dirc p-16">
            <div className="p-rel f-c f-dirc mt-16">
              {toppingsList.map((topping) => (
                <div className="f-c f-jcsb f-aic br-4 W100 mt-2 mb-8" key={topping.id}>
                  <div>
                    <p className="f2b f08 mp0">{topping.name}</p>
                    <p className="f2 f06 mp0">{topping.weight || ''}</p>
                  </div>
                  <p className="f2b f08 mp0">{`${topping.cost} lei`}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="p-fix p-bottom f-c f-jcc f-aic pc-lr l1" style={{ height: 60 }}>
          <button className="pl-16 no-select cursor-default" onClick={closeHandler}>
            <h3 className="f2b f09 pr-8">{t('interface.basic.done')}</h3>
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
