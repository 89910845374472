import React, { useEffect, useState, useContext } from 'react';

import { WebSockets } from './WSConnection';
import { messageHandler } from './messageHandler';

import { PlaceContext } from '../contexts/PlaceContext';
import { OrderStoreContext } from '../../features/orders/contexts/OrderStoreContext';
import { CallStoreContext } from '../../features/calls/contexts/CallStoreContext';
import { NotificationStoreContext } from '../../features/notifications/contexts/NotificationStoreContext';

export const WSContext = React.createContext();
export const WSProvider = ({ children }) => {
  const [, orderDispatch] = useContext(OrderStoreContext);
  const [, callDispatch] = useContext(CallStoreContext);
  const [, notificationDispatch] = useContext(NotificationStoreContext);
  const placeContext = useContext(PlaceContext);
  const {
    data: {
      place: { placeId, pointId, delivery },
      userId,
    },
    initialize,
  } = placeContext;

  const [WSConnection, setWSConnection] = useState(null);

  const onWSMessage = async (message) => {
    const reducers = {
      orderDispatch,
      callDispatch,
      notificationDispatch,
      initialize,
    };
    const data = { placeId, pointId, userId, delivery };
    await messageHandler(message, reducers, data);
  };

  const onReconnectCallback = (socket) => {
    setWSConnection(socket);
  };

  const onDisconnectCallback = () => {
    setWSConnection(null);
  };

  const connectSockets = () => {
    if (!userId || !placeId || WSConnection || WebSockets.isConnecting) return;
    const socket = WebSockets.getInstance(
      userId,
      placeId,
      onWSMessage,
      onDisconnectCallback,
      onReconnectCallback,
    );
    !document.hidden && socket.connect();
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', connectSockets, true);

    return () => {
      document.removeEventListener('visibilitychange', connectSockets, true);
    };
  }, []);

  useEffect(() => {
    connectSockets();
  }, [placeId, userId, pointId]);

  return (
    <WSContext.Provider value={{ WSConnection, setWSConnection }}>{children}</WSContext.Provider>
  );
};
