import React from 'react';
import Imagez from '../../lib/reusable/Imagez.jsx';

export const GiChiliPepper = (props) => (
  <svg viewBox="0 0 512 512" fill="red" height={props.size} width={props.size}>
    <path d="M446.738 28.814c-25.117 13.687-48.889 42.68-61.957 71.809-7.818-2.893-16.676-4.618-25.513-4.545-14.73.121-29.385 5.227-39.008 18.168.886.005 1.774.018 2.666.05 12.22.443 24.958 3.41 37.304 8.102 22.98 8.734 45.207 23.286 58.29 41.83 12.723-30.603-.83-45.203-17.569-55.43 11.733-25.618 34.789-53.49 54.4-64.177zM319.824 132.261a58.819 58.819 0 0 0-6.894.35c-11.725 1.322-19.854 5.705-24.686 14.477-45.314 82.267-40.39 117.237-53.092 156.177-6.35 19.47-17.347 39.092-40.322 63.21-22.975 24.116-57.954 53.12-113.379 93.007-12.743 9.171-20.766 16.8-24.03 21.39-.686.967-.496.828-.773 1.417 2.1.465 6.218 1.262 13.534.709 9.923-.751 23.7-3.263 40.53-7.672 85.471-22.392 164.479-75.553 220.118-132.317 27.82-28.381 49.794-57.658 63.861-84.048 14.068-26.39 19.84-49.872 17.239-65.627-3.273-19.817-29.295-43.162-58.096-54.11-10.8-4.105-21.814-6.517-31.592-6.908a69.925 69.925 0 0 0-2.418-.055zm1.977 17.813c1.725.06 3.385.555 4.967 1.533-22.945 39.64-46.91 81.1-60.47 138.438-22.95-11.989 25.686-141.007 55.503-139.971z"></path>
  </svg>
);

export const Vegan = (props) => {
  return (
    <div style={{ height: props.size, width: props.size, padding: 4 }}>
      <Imagez image="./icons/vegan.png" />
    </div>
  );
};

export const Vegetarian = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/vegetarian.png" />
  </div>
);

export const Turkey = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/turkey.png" />
  </div>
);

export const MedalSilver = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/silver.png" />
  </div>
);

export const Pork = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/pork.png" />
  </div>
);

export const Popular = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/star.png" />
  </div>
);

export const Photo = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/photo.png" />
  </div>
);

export const MedalGold = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/gold.png" />
  </div>
);

export const Gluten = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/gluten_free.png" />
  </div>
);

export const Fish = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/fish.png" />
  </div>
);

export const Frozen = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/frozen.png" />
  </div>
);

export const Duck = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/duck.png" />
  </div>
);

export const MedalBronze = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/copper.png" />
  </div>
);

export const Chicken = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/chicken.png" />
  </div>
);

export const Chef = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/chef.png" />
  </div>
);

export const Beef = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/beef.png" />
  </div>
);

export const AdultsOnly = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/adults.png" />
  </div>
);

export const NewProduct = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/newproduct.png" />
  </div>
);

export const Seafood = (props) => (
  <div style={{ height: props.size, width: props.size, padding: 4 }}>
    <Imagez image="./icons/seafood.png" />
  </div>
);
