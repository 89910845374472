import React, { useState, useEffect, useContext } from 'react';
import { getExchange } from '../api/getExchange';
import { PlaceContext } from '../contexts/PlaceContext';

const initialState = {
  active: null,
  informative: {},
};

export const CurrenciesContext = React.createContext();
export const CurrenciesProvider = ({ children }) => {
  const placeContext = useContext(PlaceContext);

  const {
    data: {
      place: { features },
    },
  } = placeContext;

  const [exchange, setExchange] = useState(null);
  const [currencies, setCurrencies] = useState(initialState);

  const handleCurrencies = async () => {
    try {
      const newCurrencies = await getExchange();
      const newExchange = {};

      const newCurrenciesMap = {};
      for (const currency of newCurrencies) {
        newCurrenciesMap[currency.id] = currency.value;
      }

      const placeCurrencyId = features?.place?.currency || 'RON';
      for (const currency of newCurrencies) {
        newExchange[currency.id] = (price) => {
          try {
            return Number(
              (Number(price) / newCurrenciesMap[placeCurrencyId]) * currency.value,
            ).toFixed(1);
          } catch (error) {
            return 'N/A';
          }
        };
      }

      const newInformative = { ...currencies };
      if (currencies.active) {
        if (newExchange[currencies.active]) {
          newInformative.informative[currencies.active] = newExchange[currencies.active];
        }
      }

      setExchange(newExchange);
      setCurrencies(newInformative);
    } catch (error) {
      console.error(error);
      setExchange(null);
    }
  };

  useEffect(() => {
    if (exchange !== null) return;
    if (currencies.active !== null && !currencies.informative[currencies.active]) {
      handleCurrencies();
    }
  }, [currencies, exchange]);

  return (
    <CurrenciesContext.Provider value={[currencies, setCurrencies]}>
      {children}
    </CurrenciesContext.Provider>
  );
};
