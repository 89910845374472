import i18next from 'i18next';

export const pendingOrderReceivedByPlace = async (payload, reducers, data) => {
  const { placeId, pointId, userId } = data;
  const { orderDispatch, notificationDispatch } = reducers;
  const { orderId } = payload;

  const message = i18next.t('orders.NOTIFICATIONS.send');

  await orderDispatch({
    type: 'ORDER_CONFIRM_RECEIVED',
    payload: {
      orderId,
      userId,
      placeId,
      pointId,
    },
  });

  const date = new Date().getTime();
  const newNotification = new CustomEvent('Notification', {
    detail: { message, type: 'info', orderId },
  });
  window.__TIMEOUTS__.orders.initiated[orderId] !== undefined &&
    clearTimeout(window.__TIMEOUTS__.orders.initiated[orderId]);
  delete window.__TIMEOUTS__.orders.initiated[orderId];
  window.dispatchEvent(newNotification);
  notificationDispatch({
    type: 'ADD_NOTIFICATION',
    payload: {
      notificationId: orderId,
      message,
      placeId,
      pointId,
      type: 'info',
      date,
    },
  });
};
