import React from 'react';

const NoService = ({ service }) => {
  return (
    <div className="f-c f-jcc f-aic ta-c p-32" style={{ marginTop: '200px' }}>
      <p className="f2 f1">{`No categories with products have been found!`} </p>
    </div>
  );
};

export default NoService;
