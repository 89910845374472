import React, { useEffect } from 'react';
import { scrollToTop } from '../../core/functions/utilities';

import Search from '../../features/search/Search';

const SearchPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return <Search />;
};
export default SearchPage;
