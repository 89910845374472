import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import OrderList from './components/OrderList';
import OrderModal from './components/OrderModal';
import OrderModalDelivery from './components/OrderModalDelivery';

import Loading from '../basic-loading/Loading';

import { LongRoundButton } from '../../lib/reusable/LongRoundButton';
import { MenuButton } from '../../lib/reusable/MenuButton';

import { PlaceContext } from '../../core/contexts/PlaceContext';
import { OrderStoreContext } from './contexts/OrderStoreContext';

import Countdown from 'react-countdown';
import { availableTill } from '../../core/functions/checkAvailableHours';
import moment from 'moment-timezone';

const OrderPage = () => {
  const history = useHistory();
  const [state] = useContext(OrderStoreContext);
  const [orderVisible, setOrderVisible] = useState(false);
  const [orderViewFinal, setOrderViewFinal] = useState(false);
  const [counter, setCounter] = useState(false);
  const [orderTimeEnded, setOrderTimeEnded] = useState(false);
  const {
    data: {
      place: { pointId, ordering, features, timezone },
      cache: {
        products: { productId__product },
        toppings,
      },
    },
    loading: isLoading,
  } = useContext(PlaceContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (pointId !== 'delivery') {
      if (counter) {
        setCounter(null);
      }
      return;
    }
    const { latestDeliveryDateAvailable } = availableTill(features?.delivery, timezone);
    const hasCountdownTimer = ordering && latestDeliveryDateAvailable;
    if (!hasCountdownTimer) {
      return;
    }

    const counterDuration = moment.duration(
      moment().tz(timezone).local().diff(latestDeliveryDateAvailable),
    );
    const counterDurationHours = counterDuration.asHours();

    // console.log(
    //   'counterDurationHours',
    //   moment(latestDeliveryDateAvailable).tz(timezone).local().format(),
    //   counterDurationHours,
    // );

    // show if only 1 hour left
    if (counterDurationHours >= -1 && counterDurationHours < 0) {
      setCounter(latestDeliveryDateAvailable);
    } else {
      setCounter(null);
    }
  }, [features]);

  const hasActiveOrder = Object.keys(state.orders.active.products).length > 0;
  const orders = hasActiveOrder
    ? [...state.orders.history, state.orders.active]
    : state.orders.history;
  const aciveIndex = hasActiveOrder ? state.orders.history.length : -1;
  let hasConfirmedOrder = false;
  const finalOrderMap = new Map();
  let finalCost = 0;

  const getProducts = (order) => {
    const products = [];
    const orderProducts = Object.keys(order.products);
    for (const productId of orderProducts) {
      products.push(productId__product[productId]);
    }
    return products;
  };

  const getCost = (order, products) => {
    let totalCost = 0;
    for (const product of products) {
      if (!product?.id) continue;
      const count = order.products[product.id].length;
      let toppingCost = 0;
      for (const toppingsOfProduct of order.products[product.id]) {
        const { toppings: productToppings } = toppingsOfProduct;
        const hasCachedToppings = toppings.toppingId__topping;
        if (productToppings && hasCachedToppings) {
          if (Array.isArray(productToppings)) {
            for (const toppingId of productToppings) {
              toppingCost += toppings.toppingId__topping[toppingId].cost;
            }
          } else {
            for (const toppingId in productToppings) {
              toppingCost += toppings.toppingId__topping[toppingId].cost;
            }
          }
        }
      }
      totalCost += product.cost * count + toppingCost;
    }
    return totalCost;
  };

  for (const order of state.orders.history) {
    if (order.status === 'approved' || 'approved-delivery') {
      hasConfirmedOrder = true;
      const orderProducts = Object.keys(order.products);
      for (const productId of orderProducts) {
        const count = order.products[productId].length;
        if (finalOrderMap.has(productId)) {
          const product = finalOrderMap.get(productId);
          finalOrderMap.set(productId, {
            product: productId__product[productId],
            count: product.count + count,
          });
        } else {
          finalOrderMap.set(productId, {
            product: productId__product[productId],
            count,
          });
        }
      }
      finalCost += order.cost;
    }
  }

  const finalOrder = Array.from(finalOrderMap.values());

  let visualOrders = [];
  for (const order of orders) {
    if (!order.products) continue;
    const products = getProducts(order);
    const totalCost = getCost(order, products);
    const newVisualOrder = {
      products,
      orderId: order.orderId,
      status: order.status,
      date: order.date,
      cost: totalCost,
      orderNo: order.orderNo,
      orderCode: order.orderCode,
    };
    visualOrders.push(newVisualOrder);
  }

  const activeOrderTotalCost = hasActiveOrder ? visualOrders[visualOrders.length - 1].cost : -1;

  const productClick = ({ productId }) => {
    const uri = `/product/${productId}`;
    history.push(uri);
  };

  const openOrderMoldal = () => {
    setOrderVisible(true);
  };

  const closeOrderMoldal = () => {
    setOrderVisible(false);
  };

  const listClick = () => {
    if (!orderViewFinal) {
      setOrderViewFinal(true);
    }
  };

  const orderClick = () => {
    if (orderViewFinal) {
      setOrderViewFinal(false);
    }
  };

  const activeOrderTotalCostSafe = activeOrderTotalCost < 0 ? 0 : activeOrderTotalCost;

  return (
    <div className="p-rel minH100v oflows l1">
      {isLoading && <Loading />}
      {orderVisible && (
        <>
          {pointId === 'delivery' ? (
            <OrderModalDelivery
              isVisible={orderVisible}
              closeModal={closeOrderMoldal}
              totalCost={activeOrderTotalCost}
            />
          ) : (
            <OrderModal
              isVisible={orderVisible}
              closeModal={closeOrderMoldal}
              totalCost={activeOrderTotalCost}
            />
          )}
        </>
      )}
      <h3 className="f2b f10 ta-l pt-16 pl-16 ml-4">ORDERS</h3>
      {counter && (
        <div className="f-c p-16 mt-8 W100 bg-danger">
          <h3 className="f2b f10 ta-c ml-4">{`Time left to order: `}</h3>
          <span className="ml-8">
            <Countdown
              date={moment(counter).valueOf()}
              onComplete={() => setOrderTimeEnded(true)}
              daysInHours={true}
            />
          </span>
        </div>
      )}
      <div className="p-rel f-c f-dirc pt-16 WH100 f-1 l1">
        {visualOrders.length === 0 && (
          <div className="f-c f-jcc f-aic pt-32 txt-app W100">
            <span>{t('interface.orders.no_products')}</span>
          </div>
        )}
        {hasConfirmedOrder && (
          <div className="f-c f-aic m-8 pb-16 bb-app">
            <div className="mr-4 p-4">
              <MenuButton
                action={orderClick}
                name={t('interface.orders.list_by_orders')}
                active={!orderViewFinal}
              />
            </div>

            <div className="p-4">
              <MenuButton
                action={listClick}
                name={t('interface.orders.list_all')}
                active={orderViewFinal}
              />
            </div>
          </div>
        )}
        {visualOrders.length > 0 && !orderViewFinal ? (
          visualOrders.map((order, index) => {
            let lastConfirmedOrder = false;
            if (hasActiveOrder) {
              lastConfirmedOrder =
                visualOrders.length === 1 ? false : index === visualOrders.length - 2;
            } else {
              lastConfirmedOrder = index === visualOrders.length - 1;
            }
            return (
              <OrderList
                key={index}
                order={order}
                productClick={productClick}
                index={index}
                active={index === aciveIndex}
                lastConfirmedOrder={lastConfirmedOrder}
              />
            );
          })
        ) : (
          <>
            {finalOrder.map((entry) => (
              <div
                className="p-rel f-c f-aic f-jcsb mc-lr-2x"
                key={entry.product.id}
                style={{ minHeight: '40px' }}
              >
                <div className="f-c f-aic f-1 ml-4 pr-32">
                  <h3 className="f1b f08">{`${entry.count}x`}</h3>
                  <h3 className="f1 f08 t-UC ml-4">{entry.product.name}</h3>
                </div>
                <div className="f-c f-jcfe f-aic">
                  <h3 className="f1b f08">{`${entry.count * entry.product.cost}${
                    entry.product.currency
                  }`}</h3>
                </div>
              </div>
            ))}
          </>
        )}
        <div className="minH300" />
      </div>

      {hasActiveOrder && !orderTimeEnded && (
        <div className="p-fix l3" style={{ bottom: 80, right: 12, minWidth: '112px' }}>
          <LongRoundButton
            action={openOrderMoldal}
            danger
            name={`${t('interface.basic.order').toUpperCase()} +${activeOrderTotalCostSafe} lei`}
          />
        </div>
      )}

      {/* <div
        className="p-fix f-c f-dirc l3"
        style={{ bottom: '32px', right: '16px', width: '140px' }}
      >
        <h3 className="f2 f08 txt-app ta-r p-2" style={{ marginLeft: '-2px' }}>
          {t('interface.orders.price_info')}
        </h3>
        <h3 className="f2b f15 txt-app ta-r p-2" style={{ marginLeft: '-2px' }}>
          {`${finalCost} lei`}
        </h3>
      </div>
      <div
        className="p-fix bg-overlay-2 l1"
        style={{ bottom: 0, left: 0, right: 0, height: 110 }}
      /> */}
    </div>
  );
};
export default OrderPage;
