import { pushNotification } from '../../../core/functions/pushNotifications';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

export const confirmPendingPoint = (payload, reducers, data) => {
  const uuid = uuidv4();
  const { placeId, pointId, userId, events } = payload;
  const { orderDispatch, notificationDispatch, callDispatch } = reducers;
  const date = new Date().getTime();
  const message = i18next.t('orders.NOTIFICATIONS.confirm');
  const newNotification = new CustomEvent('Notification', {
    detail: { message, type: 'success', orderId: uuid, date: new Date().getTime() },
  });
  window.dispatchEvent(newNotification);
  orderDispatch({
    type: 'CONFIRM_POINT',
    payload: {
      placeId,
      confirmedOrders: events.orders || {},
      userId,
      pointId,
    },
  });
  callDispatch({
    type: 'CONFIRM_POINT',
    payload: {
      placeId,
      confirmedCalls: events.calls || {},
      userId,
      pointId,
    },
  });
  notificationDispatch({
    type: 'UPDATE_NOTIFICATION',
    payload: {
      notificationId: uuid,
      message,
      placeId,
      pointId,
      type: 'success',
      date,
    },
  });

  pushNotification(message);
};
