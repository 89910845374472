import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ReactModal from '../../../lib/reusable/ReactModal';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { OrderStoreContext } from '../../orders/contexts/OrderStoreContext';

let scrollLockX = 0;
let scrollLockY = 0;

function scrollBlock() {
  window.scrollTo(scrollLockX, scrollLockY);
}

export default function ToppingsModalOrdering(props) {
  const { isVisible, closeMenu, productId, position, toppingsOnPosition } = props;
  const [state, dispatch] = useContext(OrderStoreContext);
  const {
    data: {
      place: { placeId },
      cache: {
        toppings,
        products: { productId__product },
      },
    },
  } = useContext(PlaceContext);

  const [selected, setSelected] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    scrollLockX = window.scrollX;
    scrollLockY = window.scrollY;
    isVisible && window.addEventListener('scroll', scrollBlock, true);

    return () => {
      window.removeEventListener('scroll', scrollBlock, true);
    };
  });

  useEffect(() => {
    setSelected(toppingsOnPosition);
  }, [toppingsOnPosition]);

  useEffect(() => {
    const hasOrderInCache =
      Object.keys(selected).length === 0 &&
      state.orders.active.products[productId][position] &&
      state.orders.active.products[productId][position].toppings &&
      Object.keys(state.orders.active.products[productId][position].toppings).length > 0;
    if (hasOrderInCache) {
      setSelected({ ...state.orders.active.products[productId][position].toppings });
    }
  }, []);

  if (!isVisible) return null;

  const closeModal = () => {
    window.removeEventListener('scroll', scrollBlock, true);
    closeMenu();
  };

  const setToppings = () => {
    dispatch({
      type: 'SET_TOPPINGS_OF_PRODUCTS',
      payload: { productId, placeId, toppings: selected, position },
    });
    closeModal();
  };

  const cancelToppings = () => {
    setSelected({ ...state.orders.active.products[productId][position].toppings });
    closeModal();
  };

  const toggleTopping = (id) => {
    const newToppings = { ...selected };
    if (newToppings[id]) {
      delete newToppings[id];
    } else {
      newToppings[id] = true;
    }
    setSelected(newToppings);
  };

  const product = productId__product[productId];

  const toppingsList =
    toppings &&
    toppings.toppingsGroupId__toppings &&
    toppings.toppingsGroupId__toppings[product.toppings_group_id]
      ? toppings.toppingsGroupId__toppings[product.toppings_group_id]
      : [];

  return (
    <ReactModal modalIsOpen={isVisible} closeModal={closeModal} scrollable>
      <div
        className="p-fix p-full H100v W100 no-border bg-toppings txt-toppings"
        onClick={closeModal}
      />
      <div className="p-fix p-full f-c f-dirc br-8 txt-toppings l1">
        <div className="p-fix p-full f-c f-jcc f-aic ta-c l1" style={{ height: 60 }}>
          <h3 className="f2b f09">{t('interface.orders.select_toppings')}</h3>
        </div>

        <div className="p-rel f-c f-dirc ml-4 mr-4 mt-64 mb-64 oflows l1">
          {toppingsList.map((topping) => {
            const bgColor = selected[topping.id] ? ' bg-toppings-selected' : ' bg-transparent';
            const txtColor = selected[topping.id] ? ' txt-toppings-selected' : ' txt-toppings';
            const wrapperClass = `p-rel f-c f-jcsb f-aic br-4 W100 p0 ${bgColor} ${txtColor}`;
            return (
              <div
                className={wrapperClass}
                onClick={() => toggleTopping(topping.id)}
                key={topping.id}
                style={{ minHeight: '60px' }}
              >
                <h3 className="f2b f10 p0 ml-16">{topping.name}</h3>
                <h3 className="f2b f10 p0 mr-16">{`${topping.cost} lei`}</h3>
              </div>
            );
          })}
        </div>

        <div className="p-fix p-bottom f-c f-jcsb f-aic l1" style={{ height: 60 }}>
          <button
            className="pl-16 bg-toppings f-1 H100 no-select cursor-default"
            onClick={cancelToppings}
          >
            <h3 className="f2b f09">{t('interface.basic.cancel')}</h3>
          </button>
          <button
            className="pl-16 bg-toppings f-1 H100 no-select cursor-default"
            onClick={setToppings}
          >
            <h3 className="f2b f09 pr-8">{t('interface.basic.done')}</h3>
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
