import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { PlaceContext } from '../../core/contexts/PlaceContext';
import { RAFContext } from '../../core/contexts/RAFContext';

const limitTrail = (str, isMobile = false) => {
  if (!isMobile) {
    return str.length > 40 ? `${str.substring(0, 40)}...` : str;
  }
  return str.length > 20 ? `${str.substring(0, 20)}...` : str;
};

const Trail = (props) => {
  const { isMobile } = useContext(RAFContext);
  const history = useHistory();

  const handleClick = () => {
    history.push(props.route);
  };

  if (!isMobile) {
    return (
      <div className="f-c f-aic ml-2" onClick={handleClick}>
        <ArrowRightIcon className="txt-bread-crumb-trail s-16-i" />
        <div className="f-c f-aic">
          <span className="f07 t-UC txt-bread-crumb-trail pl-16 pr-16 pt-8 pb-8 bg-app br-8">
            {props.name}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="f-c f-aic ml-2" onClick={handleClick}>
      <ArrowRightIcon className="txt-bread-crumb-trail s-16-i" />
      <span className="f07 t-UC txt-bread-crumb-trail">{props.name}</span>
    </div>
  );
};

const BreadCrumbTrail = () => {
  const {
    data: {
      cache: { services, categories },
    },
  } = useContext(PlaceContext);
  const { isMobile } = useContext(RAFContext);

  const { t } = useTranslation();

  const location = useLocation();
  const [trail, setTrail] = useState([]);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const { pathname } = location;
    const pathArray = pathname.split('/').filter((path) => path !== '');
    const [type, id] = pathArray;

    let typeName = null;
    let serviceName = null;
    let route = null;
    if (type && id) {
      switch (type) {
        case 'service':
          if (services.serviceId__service[id]) {
            typeName = services.serviceId__service[id].name;
          }
          break;

        case 'category':
          if (categories.categoryId__category[id].name) {
            typeName = categories.categoryId__category[id].name;
            const serviceId = categories.categoryId__category[id].service_id;
            serviceName = services.serviceId__service[serviceId].name;
            route = `/service/${serviceId}`;
          }
          break;

        default:
          console.error('ERROR DEFAULT STATE IN BREADCRUMBS TRAIL');
          break;
      }
    }

    const newTrail = [t('interface.basic.home')];
    const newRoutes = ['/'];

    if (serviceName) {
      newTrail.push(limitTrail(serviceName, isMobile));
      newRoutes.push(route);
    }
    if (typeName) {
      newTrail.push(limitTrail(typeName, isMobile));
    }

    setTrail(newTrail);
    setRoutes(newRoutes);
  }, [categories.categoryId__category, location, services.serviceId__service]);

  return (
    <div className="f-c f-aic" style={{ height: !isMobile ? 40 : 32 }}>
      {trail.map((name, index) => (
        <Trail key={index} name={name} route={routes[index]} />
      ))}
    </div>
  );
};

export default BreadCrumbTrail;
