import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const PopupArrow = (props) => {
  const { top, left, children } = props;

  if (!top || !left) {
    return null;
  }

  return (
    <div className="p-fix l10 fds-3" style={{ top, left }}>
      <div
        className="p-rel f-c f-dirc f-jcc f-aic pe-none br-8 box-bb"
        style={{ width: 128, maxWidth: 128 }}
      >
        <div className="W100">{children}</div>
        <div className="W100 f-c f-jcc" style={{ marginTop: -32, marginLeft: -32 }}>
          <ArrowDropDownIcon className="txt-calls-popup-overlay s-64-i" />
        </div>
      </div>
    </div>
  );
};

export { PopupArrow };
