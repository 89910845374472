import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import ProductsList from '../menu/components/ProductsList';
import { SearchBar } from '../search/components/SearchBar';
import SearchTagList from '../tags/SearchTagList';

import { PlaceContext } from '../../core/contexts/PlaceContext';
import { SearchStoreContext } from './contexts/SearchStoreContext';

const Search = () => {
  const history = useHistory();
  const {
    data: {
      cache: { index },
    },
  } = useContext(PlaceContext);
  const [searchState] = useContext(SearchStoreContext);

  const { searching } = searchState;

  const productClick = ({ productId }) => {
    const uri = `/product/${productId}`;
    history.push(uri);
  };

  const getCache = (searching) => {
    let products = [];

    const containsName = index.names.filter((productName) =>
      productName.includes(searching?.trim()),
    );
    const containsTags = index.tags.filter((productName) =>
      productName.includes(searching?.trim()),
    );
    for (const name of containsName) {
      products.push(index.name__product[name]);
    }
    for (const tag of containsTags) {
      products = [...products, ...index.tag__products[tag]];
    }

    return _.uniqWith(products, _.isEqual);
  };

  const filteredProducts = searching.length > 2 ? getCache(searching) : [];

  return (
    <div className="p-rel minH100v">
      <div className="p-fix p-top l3">
        <div className="f-c f-jcc f-aic pt-16 ml-16 mr-16">
          <SearchBar />
        </div>
      </div>
      <div style={{ height: 80 }} />
      <div className="pb-16">
        <ProductsList products={filteredProducts} productClick={productClick} searchingMode />
        {!(searching.length > 2) && (
          <div className="p-16">
            <SearchTagList />
          </div>
        )}
        <div className="minH150" />
      </div>
    </div>
  );
};
export default Search;
