import _ from 'lodash';

import { setWithExpiry } from '../../../core/functions/localStorage';

const ttl = 3600000 * 6;

const addNotification = (state, payload) => {
  const newState = _.cloneDeep(state);
  const newNotification = {};
  newNotification[payload.notificationId] = {
    message: payload.message,
    type: payload.type,
    date: payload.date || 0,
  };
  newState.notifications = {
    ...newState.notifications,
    ...newNotification,
  };
  setWithExpiry(
    `notifications-${payload.pointId}-${payload.placeId}`,
    JSON.stringify(newState.notifications),
    ttl,
  );
  return newState;
};

const removeNotification = (state, payload) => {
  const newState = _.cloneDeep(state);
  delete newState.notifications[payload.notificationId];
  setWithExpiry(
    `notifications-${payload.pointId}-${payload.placeId}`,
    JSON.stringify(newState.notifications),
    ttl,
  );
  return newState;
};

const updateNotification = (state, payload) => {
  const newState = _.cloneDeep(state);
  newState.notifications[payload.notificationId] = {
    ...newState.notifications[payload.notificationId],
    message: payload.message,
    type: payload.type,
    date: payload.date || 0,
  };
  setWithExpiry(
    `notifications-${payload.pointId}-${payload.placeId}`,
    JSON.stringify(newState.notifications),
    ttl,
  );
  return newState;
};

const notificationsFromLocalStorage = (state, payload) => {
  const newState = _.cloneDeep(state);
  newState.notifications = payload.notifications;
  return newState;
};

const NotificationReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return addNotification(state, action.payload);
    case 'REMOVE_NOTIFICATION':
      return removeNotification(state, action.payload);
    case 'UPDATE_NOTIFICATION':
      return updateNotification(state, action.payload);
    case 'LOCALSTORAGE_NOTIFICACTIONS':
      return notificationsFromLocalStorage(state, action.payload);
    default:
      return state;
  }
};

export default NotificationReducer;
