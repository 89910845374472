import moment from 'moment-timezone';

export const getStartEndDates = (hours, timezone) => {
  const hourInterval = hours.split('-');
  const [startHour, endHour] = hourInterval;
  const deliveryPassesMidnight =
    // hour difference must be negative to pass midnight
    Number(endHour.split(':')[0]) - Number(startHour.split(':')[0]) <= 0;
  const startDate = moment()
    .set({
      hour: Number(startHour.split(':')[0]),
      minute: Number(startHour.split(':')[1]),
      second: 0,
      millisecond: 0,
    })
    .tz(timezone)
    .local();
  const endDate = deliveryPassesMidnight
    ? moment()
        .add(1, 'days')
        .set({
          hour: Number(endHour.split(':')[0]),
          minute: Number(endHour.split(':')[1]),
          second: 0,
          millisecond: 0,
        })
        .tz(timezone)
        .local()
    : moment()
        .set({
          hour: Number(endHour.split(':')[0]),
          minute: Number(endHour.split(':')[1]),
          second: 0,
          millisecond: 0,
        })
        .tz(timezone)
        .local();
  return { startDate, endDate };
};

export const checkAvailableHours = (hours, timezone) => {
  try {
    const { startDate, endDate } = getStartEndDates(hours, timezone);

    return (
      moment().tz(timezone).local().isAfter(startDate) &&
      moment().tz(timezone).local().isBefore(endDate)
    );
  } catch (error) {
    console.error('ERROR', error);
    return false;
  }
};

export const availableTill = (delivery, timezone) => {
  try {
    if (!delivery) {
      return null;
    }

    const day = moment().tz(timezone).local().format('ddd').toLowerCase();

    const deliveryHours = delivery?.delivery?.availability?.[day];
    const pickupHours = delivery?.pickup?.availability?.[day];

    const availableToday = Boolean(deliveryHours) || Boolean(pickupHours);

    if (!availableToday) {
      return null;
    }

    const { endDate: endDateDelivery } = getStartEndDates(deliveryHours, timezone);
    const { endDate: endDatePickup } = getStartEndDates(pickupHours, timezone);

    // console.log('END DATE DELIVERY', moment(endDateDelivery).tz(timezone).local().format());

    // console.log('END DATE PICKUP', moment(endDatePickup).tz(timezone).local().format());

    const latestDeliveryDateAvailable = endDateDelivery.isAfter(endDatePickup)
      ? endDateDelivery
      : endDatePickup;

    if (moment().tz(timezone).local().isAfter(latestDeliveryDateAvailable)) {
      return null;
    }

    // console.log(
    //   'latestDeliveryDateAvailable',
    //   moment(latestDeliveryDateAvailable).tz(timezone).local().format(),
    // );
    return { latestDeliveryDateAvailable };
  } catch (error) {
    console.error('ERROR', error);
    return null;
  }
};
