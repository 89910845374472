import React, { useReducer, useEffect, useContext } from 'react';
import OrderReducer from './OrderReducer';
import { v4 as uuidv4 } from 'uuid';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

import { getWithExpiry } from '../../../core/functions/localStorage';

// const orderStatusType = ['active', 'pending', 'approved', 'rejected', 'completed'];

export const OrderStoreContext = React.createContext({});
export const OrderStore = ({ children }) => {
  const placeContext = useContext(PlaceContext);
  const {
    data: {
      place: { placeId, pointId, features },
      userId,
      userDevice = 'mobile',
    },
  } = placeContext;
  const newActiveOrderId = uuidv4();
  const initialState = {
    orders: {
      orderIndex: 1,
      history: [],
      active: {
        orderId: newActiveOrderId,
        orderCode: `${pointId}-${newActiveOrderId.substring(0, 4)}`.toUpperCase(),
        userDevice,
        placeId,
        userId,
        orderNo: 1,
        pointId,
        status: 'active',
        date: 0,
        products: {},
        cost: 0,
        discount: 0,
      },
    },
  };

  const [state, dispatch] = useReducer(OrderReducer, initialState);

  useEffect(() => {
    try {
      const noPlaceId = !Boolean(placeId) || placeId === '';
      const hasOrders = Boolean(features?.orders?.active) && pointId !== 'default';
      const hasActiveOrders = Object.keys(state.orders.active.products).length > 0;
      if (noPlaceId || !hasOrders || hasActiveOrders) return;
      const orderFromStorage = getWithExpiry(`order-${pointId}-${placeId}`);
      if (orderFromStorage !== null) {
        dispatch({
          type: 'LOCALSTORAGE_ORDER',
          payload: { active: JSON.parse(orderFromStorage), placeId },
        });
      }
    } catch (error) {
      console.error('ERROR ON SYNC FROM LOCALSTORAGE');
    }
  }, [placeId, pointId]);
  return (
    <OrderStoreContext.Provider value={[state, dispatch]}>{children}</OrderStoreContext.Provider>
  );
};

export default OrderStore;
