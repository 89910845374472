import { pendingOrderReceivedByPlace } from '../../features/orders/api/pendingOrderReceivedByPlace';
import { errorOnSendingOrder } from '../../features/orders/api/errorOnSendingOrder';
import { errorOnCancelingOrder } from '../../features/orders/api/errorOnCancelingOrder';
import { confirmPendingPoint } from '../../features/orders/api/confirmPendingPoint';
import { confirmDeliveryStartOnPoint } from '../../features/orders/api/confirmDeliveryStartOnPoint';
import { confirmDeliveryStopOnPoint } from '../../features/orders/api/confirmDeliveryStopOnPoint';
import { confirmDeliveryReadyOnPoint } from '../../features/orders/api/confirmDeliveryReadyOnPoint';
import { rejectPendingPoint } from '../../features/orders/api/rejectPendingPoint';
import { cancelPendingOrderReceivedByPlace } from '../../features/orders/api/cancelPendingOrderReceivedByPlace';

import { errorOnCall } from '../../features/calls/api/errorOnCall';
import { callRequestReceivedByPlace } from '../../features/calls/api/callRequestReceivedByPlace';
import { callRequestConfirmed } from '../../features/calls/api/callRequestConfirmed';

import { vibrate } from '../../core/functions/vibrate';

export const messageHandler = async (message, reducers, data) => {
  try {
    const { data: messageDataString } = message;
    const messageData = JSON.parse(messageDataString);
    const { type, payload } = messageData;

    switch (type) {
      // ORDERS

      case 'ORDER_RECEIVED':
        await pendingOrderReceivedByPlace(payload, reducers, data);
        break;
      case 'ORDER_ERROR':
        await errorOnSendingOrder(payload, reducers, data);
        vibrate();
        break;
      case 'ORDER_CANCELED':
        await cancelPendingOrderReceivedByPlace(payload, reducers, data);
        break;
      case 'ORDER_CANCELED_ERROR':
        await errorOnCancelingOrder(payload, reducers, data);
        vibrate();
        break;

      // CALLS

      case 'CALL_RECEIVED':
        await callRequestReceivedByPlace(payload, reducers, data);
        break;
      case 'CALL_ERROR':
        await errorOnCall(payload, reducers, data);
        vibrate();
        break;
      case 'CALL_CANCELED':
        // await callCancelRequestReceivedByPlace(payload, reducers, data);
        break;
      case 'CALL_CANCELED_ERROR':
        await errorOnCall(payload, reducers, data);
        vibrate();
        break;
      case 'CALL_CONFIRMED':
        callRequestConfirmed(payload, reducers, data);
        break;

      // POINT GROUP

      case 'CONFIRM_DELIVERY_START':
        confirmDeliveryStartOnPoint(payload, reducers, data);
        vibrate();
        break;

      case 'CONFIRM_DELIVERY_STOP':
        confirmDeliveryStopOnPoint(payload, reducers, data);
        vibrate();
        break;

      case 'CONFIRM_DELIVERY_READY':
        confirmDeliveryReadyOnPoint(payload, reducers, data);
        vibrate();
        break;

      case 'CONFIRM_POINT':
        confirmPendingPoint(payload, reducers, data);
        vibrate();
        break;
      case 'REJECT_POINT':
        rejectPendingPoint(payload, reducers, data);
        vibrate();
        break;

      // DEFAULT

      case 'MENU_CHANGE':
        await reducers.initialize(data.placeId);
        break;

      // DEFAULT

      default:
        console.log('ERROR: DEFAULT ON MESSAGE HANDLER SWITCH', type);
        break;
    }
  } catch (error) {
    console.log('ERROR ON MESSAGE HANDLER', error);
  }
};
