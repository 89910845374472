import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getAlergensMap } from '../../alergens/getAlergens';

import ProductTags from '../../tags/ProductTags';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

export const ProductName = ({ product }) => {
  return (
    <div className="f-c f-dirc">
      <div className="f-c f-aic">
        <h1 className="f1b f12 f-lsc-4 lh14 pr-16 wb-break txt-product">{product.name}</h1>
      </div>
      {product.intname && (
        <h1 className="f2 f11 lh14 pr-16 wb-break txt-product">{product.intname}</h1>
      )}
    </div>
  );
};

export const ProductWeight = ({ product }) => {
  const { t } = useTranslation();

  const hasWeight = typeof product?.weight === 'string' && product.weight.length > 0;

  if (!hasWeight) {
    return null;
  }

  const hasRecommended = product?.consumers?.length > 0;
  const text = hasRecommended
    ? `${t('product.quantity')}: ${product.weight} / ${t('product.recommended')}: ${
        product.consumers
      }`
    : `${t('product.quantity')}: ${product.weight}`;

  return <h3 className="f2 f07 txt-product-dim mt-4">{text}</h3>;
};

const ProductMinimized = ({ product, hasImage }) => {
  const { t } = useTranslation();

  const limit = !hasImage ? 128 : 32;

  let ingredients = product.contents ? product.contents.join(', ') : null;

  if (typeof ingredients === 'string' && ingredients.length > limit) {
    ingredients = ingredients.substring(0, limit) + '...';
  }

  return (
    <>
      {ingredients && ingredients.length > 0 && (
        <div className="txt-product">
          <p className="f2b f07 mt-8">{`${t('product.contents')}:`}</p>
          <p className="f2 f07">{ingredients}</p>
        </div>
      )}
    </>
  );
};

const nutritionFieldsOrdered = [
  'ENERGY VALUE',
  'FATS',
  'SATURATED FATS',
  'CARBOHYDRATES',
  'SUGARS',
  'FIBERS',
  'PROTEINS',
  'SALTS',
];

const nutritionFieldsUnits = {
  'ENERGY VALUE': 'KCal',
  FATS: 'g',
  'SATURATED FATS': 'g',
  CARBOHYDRATES: 'g',
  SUGARS: 'g',
  FIBERS: 'g',
  PROTEINS: 'g',
  SALTS: 'g',
};

const ProductExpanded = ({ product }) => {
  const { t } = useTranslation();
  const {
    data: {
      place: { features },
    },
  } = useContext(PlaceContext);

  const disableNutrition = Boolean(features?.place?.disableNutritionOnProducts);
  const disableAlergens = Boolean(features?.place?.disableAlergensOnProducts);

  const nutritionFieldsName = {
    'ENERGY VALUE': t('product.nutrition.energyValue'),
    FATS: t('product.nutrition.fats'),
    'SATURATED FATS': t('product.nutrition.saturatedFats'),
    CARBOHYDRATES: t('product.nutrition.carbohydrates'),
    SUGARS: t('product.nutrition.sugars'),
    FIBERS: t('product.nutrition.fibers'),
    PROTEINS: t('product.nutrition.proteins'),
    SALTS: t('product.nutrition.salts'),
  };

  const alergens = getAlergensMap();

  const nutrition100g = product?.nutrition?.['100g'];
  const nutritionPortion = product?.nutrition?.['portion'];
  const totalQty = product?.nutrition?.totalQty;
  const additives = product?.nutrition?.additives;
  const others = product?.nutrition?.others;

  const nutrition100gArray = [];
  const nutritionPortionArray = [];
  for (const nutritionType of nutritionFieldsOrdered) {
    const key = nutritionFieldsName[nutritionType];
    const value100g = nutrition100g?.[nutritionType];
    const valuePortion = nutritionPortion?.[nutritionType];

    value100g &&
      nutrition100gArray.push({
        nutritionName: key,
        value: value100g,
        ref: nutritionType,
      });
    valuePortion &&
      nutritionPortionArray.push({
        nutritionName: key,
        value: valuePortion,
        ref: nutritionType,
      });
  }

  const nTypes = (
    <>
      {nutritionPortionArray.length > 0 && (
        <div className="txt-info f-c f-dirc">
          <p className="f2b f09">{`Qty`}</p>
          <ul className="mp0 ml-8 mt-4" style={{ listStyleType: 'none' }}>
            {nutritionPortionArray.map((item, index) => {
              return (
                <li className="mp0 mt-2" key={index}>
                  <p className="f2 f07" style={{ lineHeight: '0.8rem' }}>
                    <span>{`${item.nutritionName}`}</span>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );

  const nQtyg = (
    <>
      {nutritionPortionArray.length > 0 && (
        <div className="txt-info f-c f-dirc">
          <p className="f2b f09">{`${totalQty}g`}</p>
          <ul className="mp0 mt-4" style={{ listStyleType: 'none' }}>
            {nutritionPortionArray.map((item, index) => {
              const nutritionValue = `${item.value}${nutritionFieldsUnits[item.ref]}`;
              const energyValueKj =
                item.ref === 'ENERGY VALUE'
                  ? `${' / ' + String(Math.round(item.value * 4.184))}Kj`
                  : null;
              return (
                <li className="mp0 mt-2" key={index}>
                  <p className="f2 f08" style={{ lineHeight: '0.8rem' }}>
                    <span className="ml-2 f2b f07">{`${nutritionValue}${
                      energyValueKj ? energyValueKj : ''
                    }`}</span>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );

  const n100g = (
    <>
      {nutrition100gArray.length > 0 && (
        <div className="ml-16 txt-info f-c f-dirc">
          <p className="f2b f09">{`100g`}</p>
          <ul className="mp0 mt-4" style={{ listStyleType: 'none' }}>
            {nutrition100gArray.map((item, index) => {
              const nutritionValue = `${item.value}${nutritionFieldsUnits[item.ref]}`;
              const energyValueKj =
                item.ref === 'ENERGY VALUE'
                  ? `${' / ' + String(Math.round(item.value * 4.184))}Kj`
                  : null;

              return (
                <li className="mp0 mt-2 txt-info" key={index}>
                  <p className="f2 f08" style={{ lineHeight: '0.8rem' }}>
                    <span className="ml-2 f2b f07">{`${nutritionValue}${
                      energyValueKj ? energyValueKj : ''
                    }`}</span>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );

  return (
    <>
      <div className="m-8 br-4 txt-product-dim">
        {product.details && product.details.length > 0 && (
          <div className="mt-16">
            <p className="f2b f09">{`${t('product.details')}:`}</p>
            <p className="f2 f08 mt-4">{product.details}</p>
            {product.intdetails && <p className="f2 f08 mt-4">{product.intdetails}</p>}
          </div>
        )}
        {product.contents && Array.isArray(product.contents) && product.contents.length > 0 && (
          <>
            <p className="f2b f09 mt-16">{`${t('product.contents')}:`}</p>
            <ul className="mp0 ml-32 mt-4">
              {product.contents.map((item, index) => (
                <li className="mp0" key={index}>
                  <p className="f2 f08" style={{ lineHeight: '0.8rem' }}>
                    {item}
                  </p>
                </li>
              ))}
            </ul>
          </>
        )}
        {product.description && product.description.length > 0 && (
          <div className="mt-16">
            <p className="f2b f09">{`${t('product.description')}:`}</p>
            <p className="f2 f08 mt-4">{product.description}</p>
          </div>
        )}
        {!disableNutrition && nutrition100gArray.length > 0 && nutritionPortionArray.length > 0 && (
          <>
            <div className="f-c f-dirc">
              <p className="f2b f09 mt-16 txt-info">{`${t('product.nutrition.nutrition')}`}</p>
              <div className="f-c">
                <div className="f-1 mr-16">{nTypes}</div>
                <div className="f-2">{nQtyg}</div>
                <div className="f-2">{n100g}</div>
              </div>
            </div>
          </>
        )}
        {additives && <p className="f2b f08 mt-8 txt-info">{`Additives: ${additives}`}</p>}
        {others && <p className="f2b f08 mb-8 txt-info">{`Others: ${others}`}</p>}
        {!disableAlergens &&
          product?.alergens &&
          Array.isArray(product.alergens) &&
          product.alergens.length > 0 && (
            <div className="txt-danger">
              <p className="f2b f09 mt-16">{`${t('product.alergens')}:`}</p>
              <ul className="mp0 ml-16 mt-4" style={{ listStyleType: 'none' }}>
                {product.alergens.map((item, index) => (
                  <li className="f-c mp0 mt-2" key={index}>
                    <span
                      className="f2 f08 mr-2"
                      style={{ lineHeight: '0.8rem' }}
                    >{`${item}.`}</span>
                    <p className="f2b f08" style={{ lineHeight: '0.8rem' }}>
                      {` ${alergens[item]}`}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          )}
      </div>
      <ProductTags product={product} />
    </>
  );
};

export default function ProductContent({ product, expanded, compact, hasImage }) {
  if (!product) return null;

  return (
    <div
      className="p-rel oflowh box-bb p-8 W100"
      style={{ transition: 'max-height 0.3s ease-out' }}
    >
      <ProductName product={product} />
      <ProductWeight product={product} />
      {expanded ? (
        <ProductExpanded product={product} />
      ) : !compact ? (
        <ProductMinimized product={product} hasImage={hasImage} />
      ) : null}
    </div>
  );
}
