import React, { useContext } from 'react';
import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { iframeExternalLink } from '../../../core/functions/utilities';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';

const Facebook = () => {
  const {
    data: {
      place: { facebook, inIframe },
    },
  } = useContext(PlaceContext);

  const goToFacebook = () => {
    iframeExternalLink(facebook, inIframe);
  };

  if (!facebook) return null;
  return (
    <div onClick={goToFacebook} className="ml-8 mr-4">
      <FacebookRoundedIcon />
    </div>
  );
};

export default Facebook;
