import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import { GiChiliPepper } from '../../../core/icons';
import MetaTags from 'react-meta-tags';

import Loading from '../../basic-loading/Loading';
import ProductOrdering from './ProductOrdering';

import Imagez from '../../../lib/reusable/Imagez';

import ProductTags from '../../tags/ProductTags';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { InterfaceContext } from '../../../core/contexts/InterfaceContext';
import { WSContext } from '../../../core/websockets/WSContext';
import { RAFContext } from '../../../core/contexts/RAFContext';

import { URI } from '../../../core/config';

const viewportmeta = document.querySelector('meta[name="viewport"]');

const Product = ({ productId }) => {
  const [, setErrorImage] = useState(false);
  const [isSpicy, setIsSpicy] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(true);
  const {
    data: {
      place: { placeId, ordering, placeName, pointId, features },
      cache: {
        products: { productId__product },
      },
    },
    loading: isLoading,
  } = useContext(PlaceContext);
  const { WSConnection } = useContext(WSContext);
  const [, setBottomNavigation] = useContext(InterfaceContext);
  const { isMobile } = useContext(RAFContext);
  const history = useHistory();

  const { t } = useTranslation();

  let overlayRef = React.createRef();

  const product = productId__product[productId];

  const hasUsablePointId = pointId !== 'default' && pointId !== 'delivery';
  const hasOrders = Boolean(features?.orders?.active) && pointId !== 'default';
  const hasSocketConnection = Boolean(WSConnection);

  useEffect(() => {
    try {
      if (!productId__product[product.id]) return;
      const { tags } = productId__product[product.id];
      if (tags) {
        for (const tag of tags) {
          if (tag !== 'spicy') continue;
          else {
            setIsSpicy(true);
          }
        }
      }
    } catch (error) {
      console.error('ERROR ON SPICY TAG', error);
    }
  }, [product, productId__product]);

  if (!product) {
    return null;
  }

  const hasContents = Array.isArray(product?.contents) && product.contents.length > 0;
  const hasDetails = product?.details?.length > 0;
  const hasTags = Array.isArray(product?.tags) && product?.tags.length > 0;

  const hasBottomDetails = hasDetails || hasTags;

  function blockZoom() {
    viewportmeta.setAttribute('content', 'initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0');
  }

  function allowZoom() {
    viewportmeta.setAttribute('content', 'initial-scale=1.0');
  }

  const handlerStart = () => {
    setOverlayVisible(false);
    allowZoom();
    setBottomNavigation(false);
  };
  const handlerEnd = () => {
    setOverlayVisible(true);
    blockZoom();
    setBottomNavigation(true);
  };

  const handleTouch = () => {
    if (overlayVisible) {
      handlerStart();
    } else {
      handlerEnd();
    }
  };

  const handleNoImage = () => {
    setErrorImage(true);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  if (product === undefined) return null;

  if (window.scrollY !== 0) {
    scrollToTop();
  }

  const closePage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.goBack();
  };

  const sharePage = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      navigator.share({
        title: placeName,
        text: product.name,
        url: `${URI.domain}/${placeId}?product=${productId}`,
      });
    } catch (error) {
      console.log('Could not share, browser not supported');
    }
  };

  return (
    <div className="p-rel">
      {isLoading && <Loading />}
      <MetaTags>
        <title>{product.name}</title>
        <meta property="og:image" content={product.imagesXL[0]} />
        <meta property="og:image:width" content="470" />
        <meta property="og:image:height" content="246" />
      </MetaTags>
      <div className="p-rel minWH100v">
        <div
          className="p-fix p-full f-c f-jcc f-aic bg-product-full l1"
          ref={overlayRef}
          onClick={handleTouch}
        >
          <Imagez
            image={product.imagesXL[0]}
            handleNoImage={handleNoImage}
            fallbackXL={product.imagesXL[0]}
            maxvh="100vh"
            contain
          />
        </div>

        {overlayVisible && (
          <>
            <div
              className="p-fix p-top f-c f-dirc f-jcsb p-16 l3 bg-overlay-1 l1 txt-product-full l1"
              style={{ top: 0, paddingTop: 64 }}
            >
              {hasOrders && ordering && hasSocketConnection && (
                <ProductOrdering
                  product={product}
                  showOrder
                  noImage={false}
                  style={{ top: 'calc(50vh - 30px)', right: 8 }}
                />
              )}
              <div className="f-c f-jcsb">
                <div className="f-c f-dirc">
                  <div className="f-c">
                    <h3 className="f2b f12">{product.name}</h3>
                    {isSpicy && <GiChiliPepper className="ml-8" size={20} color="red" />}
                  </div>
                  {product.intname && (
                    <h1 className="f2b f11 lh14 pr-8 wb-break">{product.intname}</h1>
                  )}
                </div>
                {Number(product.cost) !== 0 && (
                  <h3 className="f2b f12">{`${product.cost}${product.currency}`}</h3>
                )}
              </div>

              <div className="f-c f-jcsb f07 mt-16">
                {hasContents && (
                  <div>
                    <p className="f2b f07">{`${t('product.contents')}:`}</p>
                    <ul className="mp0 ml-32 mt-4">
                      {product.contents.map((item, index) => (
                        <li key={index}>
                          <p className="f2 f07">{item}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="txt-product-full">
                  {product.consumers && product.consumers.length > 0 && (
                    <div>
                      <p className="f2b f07">{`${t('product.recommended')}: ${
                        product.consumers
                      }`}</p>
                    </div>
                  )}
                  {typeof product?.weight === 'string' && product.weight.length > 0 && (
                    <div>
                      <p className="f2b f07 mt-4">{`${t('product.quantity')}: ${
                        product.weight
                      }`}</p>
                    </div>
                  )}
                </div>
              </div>
              {hasBottomDetails && (
                <div
                  className="p-fix p-bottom f-c f-dirc f-jcsb l3 bg-overlay-1 txt-product-full pt-16 l1"
                  style={{ paddingBottom: 48 }}
                >
                  <div className="p-16 oflows-y l2" style={{ maxHeight: '20vh' }}>
                    {product.details && product.details.length > 0 && (
                      <div className="mb-8" style={{ overflowWrap: 'break-word' }}>
                        <p className="f2 f09">{`${t('product.details')}:`}</p>
                        <p className="f2 f08 mt-4">{product.details}</p>
                      </div>
                    )}
                    <ProductTags product={product} />
                  </div>
                </div>
              )}
            </div>

            <div className="p-fix p-top f-c f-dirc f-jcsb l3 bg-overlay-1 txt-product-full l2">
              <div className="p-16 oflows-y l4">
                <div
                  className="f-c f-jcsb f-aic pc-lr-2x"
                  style={{ height: 48, margin: '-16px -16px -16px -16px' }}
                >
                  <div className="f-c f-aic mp0" onClick={closePage}>
                    <ArrowBackIosIcon
                      size={24}
                      className="br-f l5 p-8"
                      style={{ paddingRight: 4, marginTop: 2, marginLeft: -15 }}
                    />
                    <p className="f2 f08 t-UC">{t('interface.basic.menu')}</p>
                  </div>
                  {navigator.canShare || true ? (
                    <div onClick={sharePage}>
                      <ShareIcon size={24} className="br-f p-8 l5" style={{ marginTop: 2 }} />
                    </div>
                  ) : (
                    <div />
                  )}
                  <div className="f-c f-aic mp0" onClick={closePage}>
                    <CloseIcon size={24} className="br-f l5 p-8" style={{ marginRight: -8 }} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Product;
