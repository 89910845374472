import axios from 'axios';
import { generateCaching } from '../functions/caching';
import { URI } from '../config';

const initializeMenu = async (placeId, userId, pointId, delivery) => {
  try {
    const data = await axios.get(
      `${URI.menu}?id=${placeId}&userId=${userId}&pointId=${pointId}&delivery=${delivery}`,
    );
    const {
      status,
      data: { menu: menuData, delivery: deliveryData },
    } = data;

    const hasDeliveryAndNoData = delivery && deliveryData === undefined;
    const noDeliveryModeButNoMenu = !delivery && menuData === undefined;

    if (status !== 200 || hasDeliveryAndNoData || noDeliveryModeButNoMenu) {
      return { place: {}, cache: {}, status };
    }

    const { place, categories, toppingsGroups, toppings, products, services, events } = delivery
      ? deliveryData
      : menuData;

    const cache = generateCaching({
      place,
      categories,
      toppingsGroups,
      toppings,
      products,
      services,
      events,
    });

    return { place, cache, status };
  } catch (error) {
    console.error('error', error);
    if (error?.response?.status === 401) {
      return { place: {}, cache: {}, status: 401 };
    }

    return { place: {}, cache: {}, status: 500 };
  }
};

export { initializeMenu };
