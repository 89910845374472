import React, { useReducer, useEffect, useContext } from 'react';
import NotificationReducer from './NotificationReducer';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

import { getWithExpiry } from '../../../core/functions/localStorage';

const initialState = {
  notifications: {},
};

export const NotificationStoreContext = React.createContext(initialState);
export const NotificationStore = ({ children }) => {
  const [state, dispatch] = useReducer(NotificationReducer, initialState);
  const placeContext = useContext(PlaceContext);
  const {
    data: {
      place: { placeId, pointId },
    },
  } = placeContext;

  useEffect(() => {
    try {
      const notificationsFromStorage = getWithExpiry(`notifications-${pointId}-${placeId}`);

      if (notificationsFromStorage !== null) {
        dispatch({
          type: 'LOCALSTORAGE_NOTIFICACTIONS',
          payload: {
            notifications: JSON.parse(notificationsFromStorage),
            placeId,
          },
        });
      }
    } catch (error) {
      console.error('ERROR ON SYNC FROM LOCALSTORAGE');
    }
  }, [placeId, pointId]);

  return (
    <NotificationStoreContext.Provider value={[state, dispatch]}>
      {children}
    </NotificationStoreContext.Provider>
  );
};

export default NotificationStore;
