import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@mui/material/FormHelperText';

import OrderConfirmProduct from './OrderConfirmProduct';
import ReactModal from '../../../lib/reusable/ReactModal';

import { sendPendingOrder } from '../api/sendPendingOrder';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { WSContext } from '../../../core/websockets/WSContext';
import { OrderStoreContext } from '../../orders/contexts/OrderStoreContext';

let scrollLockX = 0;
let scrollLockY = 0;

function scrollBlock() {
  window.scrollTo(scrollLockX, scrollLockY);
}

const phoneRegex = new RegExp(/^\d{10}$/);
const addressRegex = new RegExp(/^[a-zA-Z0-9.&@#_ ]*$/);

export default function OrderModal(props) {
  const { isVisible, closeModal } = props;
  const [state, orderDispatch] = useContext(OrderStoreContext);
  const {
    data: {
      place: { placeId, pointId },
      cache: {
        products: { productId__product },
      },
      userDevice,
      userId,
    },
  } = useContext(PlaceContext);

  const { WSConnection } = useContext(WSContext);

  const [deliveryType, setDeliveryType] = React.useState('pickup');
  const [paymentType, setPaymentType] = React.useState('cash');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [focused, setFocused] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const { t } = useTranslation();

  const handlePhoneChange = (event) => {
    if (errors?.phone) {
      const newErrors = { ...errors };
      delete newErrors.phone;
      setErrors(newErrors);
    }
    if (event.target?.value?.length < 128) {
      setPhone(event.target.value);
    }
  };

  const handleAddressChange = (event) => {
    if (errors?.address) {
      const newErrors = { ...errors };
      delete newErrors.address;
      setErrors(newErrors);
    }
    if (event.target?.value?.length < 128) {
      setAddress(event.target.value);
    }
  };

  const handleNameChange = (event) => {
    if (errors?.address) {
      const newErrors = { ...errors };
      delete newErrors.address;
      setErrors(newErrors);
    }
    if (event.target?.value?.length < 128) {
      setName(event.target.value);
    }
  };

  const handleCloseModal = () => {
    window.removeEventListener('scroll', scrollBlock, true);
    closeModal();
  };

  useEffect(() => {
    scrollLockX = window.scrollX;
    scrollLockY = window.scrollY;
    isVisible && window.addEventListener('scroll', scrollBlock, true);

    return () => {
      window.removeEventListener('scroll', scrollBlock, true);
    };
  });

  if (!isVisible) return null;

  const orderProducts = Object.keys(state.orders.active.products);
  const products = [];
  const orderFormatProducts = [];
  let orderCost = 0;
  for (const productId in productId__product) {
    if (orderProducts.includes(productId)) {
      products.push(productId__product[productId]);
      for (const product of state.orders.active.products[productId]) {
        orderCost += productId__product[productId].cost;
        const toppingsFromProduct =
          product.toppings && typeof product.toppings === 'object'
            ? Object.keys(product.toppings)
            : [];
        const orderFormatProduct = {
          id: productId,
          service_id: productId__product[productId].service_id,
          toppings_ids: Array.isArray(toppingsFromProduct) ? toppingsFromProduct : [],
          discount: 0,
        };
        orderFormatProducts.push(orderFormatProduct);
      }
    }
  }

  const sendOrderToPlace = async () => {
    let details = {};
    if (pointId === 'delivery') {
      const newErrors = {};

      if (phone.length < 10 || phone[0] !== '0' || !phoneRegex.test(phone)) {
        newErrors.phone = 'Invalid Phone Number';
      }

      if (name.length < 2) {
        newErrors.phone = 'Invalid Name';
      }

      if (
        deliveryType === 'delivery' &&
        (address.length < 3 || address.length > 256 || addressRegex.test(addressRegex))
      ) {
        newErrors.address = 'Invalid Address';
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }

      details = {
        delivery: {
          type: deliveryType,
          payment: deliveryType === 'delivery' ? paymentType : null,
          phone: `${phone}-C${String(new Date().getTime()).slice(-4)}`,
          name: name,
          address: deliveryType === 'delivery' ? address : null,
        },
      };
    }

    const { orderId } = state.orders.active;
    const data = {
      orderId,
      placeId,
      pointId: details?.delivery?.phone ? details?.delivery?.phone : pointId,
      userId,
      userDevice,
      products: orderFormatProducts,
      details,
    };
    const reducers = { orderDispatch };
    await sendPendingOrder(data, reducers, WSConnection);
    handleCloseModal();
  };

  const cancelOrder = () => {
    handleCloseModal();
  };

  return (
    <ReactModal modalIsOpen={isVisible} closeModal={closeModal} scrollable>
      <div className="p-rel H100">
        <div
          className="p-abs p-top f-c f-jcc f-aic bg-info-light txt-white l2"
          style={{ height: 60 }}
        >
          <h3 className="f2b f08 f-1 ta-c">{t('interface.orders.confirm_order')}</h3>
        </div>

        <div
          className="p-abs p-full pl-16 pr-16 f-c f-dirc oflows l1 bg-info-light txt-white"
          style={{ top: 60, bottom: 60 }}
        >
          {products.map((product) => {
            return (
              <div key={product.id} className="p-rel W100 bb-app">
                <OrderConfirmProduct product={product} active confirm />
              </div>
            );
          })}
          <div className="f-c f-jcsb W100">
            <h3 className="f2 f10 f-1 pt-16 pb-16 pl-4 txt-app">
              {t('interface.orders.confirm_order_total')}
            </h3>
            <h3 className="f2b f10 f-1 ta-c pt-16 pb-16 pl-16 mr-2 ta-e txt-app">{`${orderCost} lei`}</h3>
          </div>
          <div className="minH300" />
        </div>

        <div className="p-abs p-bottom l2">
          <div className="f-c f-jcsb f-aic ta-c" style={{ height: 60 }}>
            <div
              className="p-rel m0 p0 ta-c f-c f-1 f-jcsb f-aic WH100 bg-info-light txt-app-dim"
              onClick={cancelOrder}
            >
              <h3 className="f2b f08 f-1 ta-c pt-16 pb-16 pl-16 mr-2 ta-c">
                {t('interface.basic.back').toUpperCase()}
              </h3>
            </div>

            <div
              className="p-rel m0 p0 ta-c f-c f-1 f-jcsb f-aic WH100 bg-info-light txt-app"
              onClick={sendOrderToPlace}
            >
              <h3
                className={`f2b f08 f-1 ta-c pt-16 pb-16 pl-16 mr-2 ta-c${
                  Object.keys(errors).length > 0 ? ' txt-danger' : 'txt-white'
                }`}
              >
                {t('interface.basic.confirm').toUpperCase()}
              </h3>
            </div>
          </div>
        </div>
        <div
          className="p-abs p-bottom l3"
          style={{ marginBottom: focused ? Math.floor(window.screen.height / 3) + 60 : 60 }}
        >
          {pointId === 'delivery' && (
            <div className="txt-white f-c f-dirc f-jcc l2 m-16 p-16 br-8 bdf-2 bg-overlay-2">
              <div className="order-confirm-type f-c">
                <div className="f-c f-aic f-1">
                  <input
                    type="radio"
                    id="order-confirm-type-pickup"
                    className="mr-8"
                    name="delivery-type"
                    value="pickup"
                    onChange={(e) => setDeliveryType(e.target.value)}
                    checked={deliveryType === 'pickup'}
                  />
                  <label className="f2b f10" htmlFor="order-confirm-type-pickup">
                    Pickup
                  </label>
                </div>
                <div className="f-c f-aic f-1 ml-16">
                  <input
                    type="radio"
                    className="mr-8"
                    id="order-confirm-type-delivery"
                    name="delivery-type"
                    value="delivery"
                    onChange={(e) => setDeliveryType(e.target.value)}
                    checked={deliveryType === 'delivery'}
                  />
                  <label className="f2b f10" htmlFor="order-confirm-type-delivery">
                    Delivery
                  </label>
                </div>
              </div>

              {deliveryType === 'delivery' && (
                <div className="order-confirm-type f-c mt-32">
                  <div className="f-c f-aic f-1">
                    <input
                      type="radio"
                      id="order-confirm-payment-type-cash"
                      className="mr-8"
                      name="payment-type-cash"
                      value="cash"
                      onChange={(e) => setPaymentType(e.target.value)}
                      checked={paymentType === 'cash'}
                    />
                    <label className="f2b f10" htmlFor="order-confirm-payment-type-cash">
                      Cash
                    </label>
                  </div>
                  <div className="f-c f-aic f-1 ml-16">
                    <input
                      type="radio"
                      className="mr-8"
                      id="order-confirm-payment-type-card"
                      name="payment-type-card"
                      value="card"
                      onChange={(e) => setPaymentType(e.target.value)}
                      checked={paymentType === 'card'}
                    />
                    <label className="f2b f10" htmlFor="order-confirm-payment-type-card">
                      Card
                    </label>
                  </div>
                </div>
              )}

              <div className="mt-16 mb-8">
                <div className="f-c f-dirc mt-16">
                  <label className="f2 f08 mb-8" htmlFor="order-confirm-name">
                    <b className="mr-4 txt-danger">*</b>Name
                  </label>
                  <input
                    required
                    type="text"
                    id="order-confirm-name"
                    className="f10 f1 txt-app bb-app pb-4"
                    value={name}
                    onChange={handleNameChange}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                  />
                </div>
                <div className="f-c f-dirc mt-16">
                  <label className="f2 f08 mb-8" htmlFor="order-confirm-phone">
                    <b className="mr-4 txt-danger">*</b>Mobile
                  </label>
                  <input
                    required
                    type="tel"
                    id="order-confirm-phone"
                    className="f10 f1 txt-app bb-app pb-4"
                    value={phone}
                    onChange={handlePhoneChange}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                  />
                </div>
                {errors.phone && <div className="f10 f2b txt-danger mt-4">{errors.phone}</div>}
                {deliveryType !== 'pickup' && (
                  <>
                    <div className="f-c f-dirc mt-16 pt-4">
                      <label className="f2 f08 mb-8" htmlFor="order-confirm-address">
                        <b className="mr-4 txt-danger">*</b>Address
                      </label>
                      <input
                        required
                        type="text"
                        id="order-confirm-address"
                        className="f10 f1 txt-app bb-app pb-4"
                        value={address}
                        onChange={handleAddressChange}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                      />
                    </div>
                    {errors.address && (
                      <div className="f10 f2b txt-danger mt-4">{errors.address}</div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
