import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../../core/functions/utilities';

import Product from '../../features/menu/components/Product';

const getProductIdFromPathname = (pathname) => {
  try {
    const params = pathname.split('/');
    return params[params.length - 1];
  } catch (error) {
    return null;
  }
};

const ProductPage = () => {
  const { pathname } = useLocation();
  const [productId, setProductId] = useState(getProductIdFromPathname(pathname));

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    setProductId(getProductIdFromPathname(pathname));
  }, [pathname]);

  return <Product productId={productId} />;
};
export default ProductPage;
