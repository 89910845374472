import React, { useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PlaceContext } from '../../../core/contexts/PlaceContext';

// import GradientHolder from '../../../lib/reusable/GradientHolder';

const NutritionCard = (props) => {
  // const history = useHistory();
  const { t } = useTranslation();
  const placeContext = useContext(PlaceContext);
  const {
    data: {
      place: { placeId },
    },
  } = placeContext;

  const goToNutrition = () => {
    const bucketUrl =
      process.env.REACT_APP_URI === 'production'
        ? 'unitpay-photobucket'
        : 'unitpay-staging-photobucket';

    const uri = `https://drive.google.com/viewerng/viewer?embedded=true&url=https://storage.googleapis.com/${bucketUrl}/${placeId}/nutrition/nutrition.pdf?time=${new Date().valueOf()}`;
    window.open(uri, '_self');
  };

  return (
    <div className="p-rel mp0 pl-8 pr-8 pb-32" onClick={goToNutrition}>
      {/* <div className="p-rel f-c H100 bds-1">
        <GradientHolder top>
          <div className="p-abs p-top f-c f-1 pl-16 f-aife">
            <h3 className="f1b f10 ib-c fds-1 mb-8 t-UC">Nutrition Values</h3>
          </div>
        </GradientHolder>
      </div> */}
      <div className="p-16 bg-info fds-2 br-4" style={{ maxWidth: 500 }}>
        <div className="f1b f09 txt-app-dim ta-c">{t('interface.basic.nutritionClick')}</div>
      </div>
    </div>
  );
};

export default NutritionCard;
