import React, { useContext } from 'react';
import Tag from './components/Tag';

import { PlaceContext } from '../../core/contexts/PlaceContext';

const SearchTagList = () => {
  const {
    data: {
      cache: { index },
    },
  } = useContext(PlaceContext);

  return (
    <div className="f-c f-w">
      {index.tags.sort().map((tag) => (
        <Tag key={tag} tag={tag} />
      ))}
    </div>
  );
};

export default SearchTagList;
