import React from 'react';

const Popup = (props) => {
  const { style, children } = props;

  return (
    <div className="p-fix l10" style={{ ...style }}>
      <div className="p-rel pe-all br-8 box-bb">
        <div className="f-c f-dirc">{children}</div>
      </div>
    </div>
  );
};

export { Popup };
