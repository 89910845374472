import React, { useContext } from 'react';
import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { iframeExternalLink } from '../../../core/functions/utilities';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Google = () => {
  const {
    data: {
      place: { google, inIframe },
    },
  } = useContext(PlaceContext);

  const goToGoogle = () => {
    iframeExternalLink(google, inIframe);
  };

  if (!google) return null;
  return (
    <div onClick={goToGoogle} className="ml-8 mr-4">
      <LocationOnIcon />
    </div>
  );
};

export default Google;
