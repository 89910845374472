import React, { useState, useEffect } from 'react';

let timeout = null;

const eventTriggerResizeFrame = new Event('custom-scroll-af-event');
// Scroll context
export const SAFContext = React.createContext();
export const SAFProvider = ({ children }) => {
  const [data, setData] = useState({ scrollY: window.scrollY });

  useEffect(() => {
    const handler = function (event) {
      // If there's a timer, cancel it
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }

      // Setup the new requestAnimationFrame()
      timeout = window.requestAnimationFrame(() => {
        // Run our scroll functions
        setData({ scrollY: window.scrollY });
        window.dispatchEvent(eventTriggerResizeFrame);
      }, 300);
    };
    window.addEventListener('scroll', handler, false);

    return () => {
      window.removeEventListener('scroll', handler, false);
    };
  }, []);

  return <SAFContext.Provider value={{ ...data }}>{children}</SAFContext.Provider>;
};
