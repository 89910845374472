/* eslint-disable react/no-children-prop */
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../../navigation/routes';

import Sponsor from '../sponsor/Sponsor';

import { NotificationsOverlay } from './components/NotificationsOverlay';
import { ScrollToTop } from './components/ScrollToTop';
import { GdprOverlay } from './components/GdprOverlay';

import { PlaceContext } from '../../core/contexts/PlaceContext';
import { CurrenciesContext } from '../../core/contexts/CurrenciesContext';
import { OrderStoreContext } from '../orders/contexts/OrderStoreContext';
import { CallStoreContext } from '../calls/contexts/CallStoreContext';
import { WSContext } from '../../core/websockets/WSContext';
import { InterfaceContext } from '../../core/contexts/InterfaceContext';

import { syncCallsAndOrders } from '../../core/api/sync-activity';
import BottomNavigation from './components/BottomNavigation';

const getActiveRoute = (pathname) => {
  return '/' + pathname.split('/')[1];
};

const InterfaceSwitcher = ({ ageValid }) => {
  const placeContext = useContext(PlaceContext);
  const {
    data: {
      place: { features },
    },
  } = placeContext;

  const hasAgeValidation = features?.place?.ageValidation?.validAge !== undefined;

  if (hasAgeValidation && (ageValid === undefined || ageValid === false)) {
    return null;
  }

  return <Interface />;
};

const Interface = () => {
  const location = useLocation();
  const [, orderDispatch] = useContext(OrderStoreContext);
  const [, callDispatch] = useContext(CallStoreContext);
  const [interfaceUI] = useContext(InterfaceContext);
  const [currencies, setCurrencies] = useContext(CurrenciesContext);

  const [loading, setLoading] = useState(true);

  const placeContext = useContext(PlaceContext);
  const {
    data: {
      place: { placeId, pointId, active, features },
      userId,
    },
    loading: isLoading,
  } = placeContext;
  const { WSConnection } = useContext(WSContext);

  const hasSponsor = Boolean(features?.sponsor?.active);
  const hasTheming = Boolean(features?.theming?.active);
  const hasCalls =
    Boolean(features?.calls?.active) && pointId !== 'default' && pointId !== 'delivery';
  const hasOrders = Boolean(features?.orders?.active) && pointId !== 'default';
  const noPlaceId = !Boolean(placeId) || placeId === '';

  const activeRoute = getActiveRoute(location.pathname);
  const orderPage = activeRoute === ROUTES.ORDER;
  const productPage = activeRoute === ROUTES.PRODUCT;

  const handleCalls = () => {
    if (hasCalls || hasOrders) {
      syncCallsAndOrders(
        { placeId, userId, pointId },
        { callDispatch, orderDispatch },
        hasCalls,
        hasOrders,
      );
    }
  };

  const handleSponsor = useCallback(() => {
    if ((hasSponsor || hasTheming) && !isLoading) {
      !loading && setLoading(true);
    } else {
      loading && setLoading(false);
    }
  }, [hasSponsor, hasTheming, isLoading, loading]);

  useEffect(() => {
    const informativeCurrencyAvailable = features?.place?.otherCurrencies?.informativeCurrency
      ? features?.place?.otherCurrencies?.informativeCurrency
      : null;

    if (informativeCurrencyAvailable) {
      setCurrencies({ ...currencies, active: informativeCurrencyAvailable });
    }
  }, [features]);

  useEffect(() => {
    if (noPlaceId) return;
    handleSponsor();
    if (isLoading) {
      handleCalls();
    }
  }, [isLoading]);

  useEffect(() => {
    WSConnection && handleCalls();
  }, [WSConnection]);

  if (!active || noPlaceId) {
    return null;
  }

  if (!hasSponsor && !hasTheming) {
    loading && setLoading(false);
  } else {
    setTimeout(() => {
      loading && setLoading(false);
    }, 2000);
  }

  return (
    <>
      {loading && <Sponsor loading={loading} />}
      {!loading && !productPage && (
        <div
          className="pe-none p-fix p-bottom mc bg-interface-fader-bottom l1"
          style={{ height: 128, opacity: 0.8 }}
        />
      )}
      {!loading && (
        <div className="bg-transparent p-fix p-bottom l3">
          {interfaceUI.bottomNavigation && <BottomNavigation />}
        </div>
      )}
      {!loading && (
        <div className="bg-transparent p-abs p-full pe-none oflowh maxW100v l2">
          <NotificationsOverlay />
          {!orderPage && <ScrollToTop />}
          <GdprOverlay />
          {interfaceUI.bottomNavigation && <BottomNavigation />}
        </div>
      )}
    </>
  );
};

export { InterfaceSwitcher };
