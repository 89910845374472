import { URI } from '../config';

class WebSockets {
  static getInstance(userId, placeId, onMessage, onDisconnectCallback, onReconnectCallback) {
    if (!WebSockets.instance) {
      WebSockets.instance = new WebSockets(
        userId,
        placeId,
        onMessage,
        onDisconnectCallback,
        onReconnectCallback,
      );
    }
    return WebSockets.instance;
  }

  constructor(userId, placeId, onMessage, onDisconnectCallback, onReconnectCallback) {
    this.userId = userId;
    this.placeId = placeId;
    this.connection = null;
    this.onMessage = onMessage;
    this.reconnectInteraval = null;
    this.onDisconnectCallback = onDisconnectCallback;
    this.onReconnectCallback = onReconnectCallback;
    this.send = null;
    this.forcedDisconnect = false;
    this.isConnecting = false;

    document.addEventListener(
      'visibilitychange',
      () => {
        if (document.hidden) {
          if (!this.connection) return;
          this.onDisconnectCallback();
          this.forcedDisconnect = true;
          this.connection.close();
          this.connection = null;

          return;
        } else {
          if (this.connection !== null || this.isConnecting) return;
          this.forcedDisconnect = false;
          this.connect();
        }
      },
      true,
    );
  }

  tryToReconnect5times() {
    let tries = 0;
    if (this.forcedDisconnect) return;
    this.reconnectInteraval = setInterval(() => {
      if (tries < 5) {
        this.connect();
        tries += 1;
      } else {
        clearInterval(this.reconnectInteraval);
        this.reconnectInteraval = null;
        setTimeout(() => {
          this.connect();
        }, 10000);
      }
    }, 5000);
  }

  cancelTryToReconnectOnConnected() {
    clearInterval(this.reconnectInteraval);
    this.reconnectInteraval = null;
  }

  async connect() {
    return new Promise((resolve, reject) => {
      try {
        if (this.connection !== null || this.isConnecting) return;
        this.isConnecting = true;
        const socket = new WebSocket(URI.socket);
        socket.onmessage = async (message) => {
          await this.onMessage(message);
        };
        socket.onopen = () => {
          const isConnecting = this.reconnectInteraval !== null;
          if (isConnecting) {
            this.cancelTryToReconnectOnConnected();
          }
          this.connection = socket;
          this.onReconnectCallback(this.connection);
          const connectionData = JSON.stringify({
            connectionId: this.userId,
            placeId: this.placeId,
          });
          socket.send(connectionData);
          console.log('WEBSOKETS SOCKETS CONNECTION OPEN');

          this.isConnecting = false;
          resolve();
        };

        socket.onerror = (error) => {
          this.isConnecting = false;
          if (error.message) console.log('ERROR: ', error.message);
        };

        socket.onclose = () => {
          console.log('DISCONNECTED FROM SOCKETS');
          this.connection = null;
          this.isConnecting = false;
          const isConnecting = this.reconnectInteraval !== null;
          if (isConnecting || this.forcedDisconnect) return;
          this.onDisconnectCallback(this.connection);
          this.tryToReconnect5times();
        };
      } catch (error) {
        console.error('Error starting the websockets server', error);
        reject();
      }
    });
  }
}

export { WebSockets };
