import React, { useContext } from 'react';

import { Notification } from './components/Notification';

import { PlaceContext } from '../../core/contexts/PlaceContext';
import { NotificationStoreContext } from './contexts/NotificationStoreContext';

const Notifications = () => {
  const {
    data: {
      place: { placeId, pointId },
    },
  } = useContext(PlaceContext);

  const [notificationState, notificationDispatch] = useContext(NotificationStoreContext);

  const showNotifications = [];
  for (const notification in notificationState.notifications) {
    const availableNotification = {
      id: notification,
      message: notificationState.notifications[notification].message,
      type: notificationState.notifications[notification].type,
      date: notificationState.notifications[notification].date,
    };
    showNotifications.push(availableNotification);
  }
  const sortedNotifications = showNotifications.sort((a, b) => b.date - a.date);
  const removeNotification = (notificationId) => {
    notificationDispatch({
      type: 'REMOVE_NOTIFICATION',
      payload: {
        notificationId,
        placeId,
        pointId,
      },
    });
  };

  if (showNotifications && showNotifications.length === 0) {
    return (
      <div className="f-c f-jcc f-aic txt-white H100v ta-c">
        <span>Nu aveti nici o notificare</span>
      </div>
    );
  }

  return (
    <div className="f-c f-dirc f-aic H100v pt-16">
      <h3 className="f2b f10 ta-l pl-32 ml-4 pb-16 W100">NOTIFICATIONS</h3>
      {sortedNotifications.map((notification) => {
        return (
          <Notification
            key={notification.id}
            id={notification.id}
            message={notification.message}
            action={removeNotification}
            type={notification.type}
            date={notification.date}
          />
        );
      })}
    </div>
  );
};
export default Notifications;
