import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import OrderProduct from './OrderProduct';
import OrderConfirmProduct from './OrderConfirmProduct';

import { sendPendingOrder } from '../api/sendPendingOrder';
import { sendCancelPendingOrder } from '../api/sendCancelPendingOrder';

import { PlaceContext } from '../../../core/contexts/PlaceContext';
import { WSContext } from '../../../core/websockets/WSContext';
import { OrderStoreContext } from '../../orders/contexts/OrderStoreContext';

const OrderList = ({ order, productClick, active, index, lastConfirmedOrder }) => {
  const dateRaw = new Date(order.date);
  const date = dateRaw.toTimeString().split(' ')[0];

  const [expand, setExpand] = useState(active);
  const [cancelState, setCancelState] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    data: {
      place: { placeId, pointId },
      cache: {
        products: { productId__product },
      },
      userDevice,
      userId,
    },
  } = useContext(PlaceContext);

  const [state, dispatch] = useContext(OrderStoreContext);
  const { WSConnection } = useContext(WSContext);

  const { t } = useTranslation();

  useEffect(() => {
    setExpand(active);
  }, [active, order]);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const keepOrder = (e) => {
    e.stopPropagation();
    setCancelState(false);
  };

  const cancelOrder = async (e) => {
    setLoading(true);
    e.stopPropagation();
    const [cancelOrder] = state.orders.history.filter(
      (historyOrder) => order.orderId === historyOrder.orderId,
    );

    const { orderId } = cancelOrder;

    const data = { orderId, placeId, pointId, userId, userDevice };

    const reducers = { orderDispatch: dispatch };
    await sendCancelPendingOrder(data, reducers, WSConnection);
    setCancelState(false);
    setLoading(false);
  };

  const handleCancelOrder = (e) => {
    e.stopPropagation();
    setCancelState(true);
  };

  const handleRetryOrder = (e) => {
    e.stopPropagation();
    const [notSentOrder] = state.orders.history.filter(
      (ordersSent) => order.orderId === ordersSent.orderId,
    );
    const orderFormatProducts = [];
    for (const productId in notSentOrder.products) {
      for (const product of notSentOrder.products[productId]) {
        const toppingsFromProduct =
          product.toppings && typeof product.toppings === 'object'
            ? Object.keys(product.toppings)
            : [];
        const orderFormatProduct = {
          id: productId,
          service_id: productId__product[productId].service_id,
          toppings_ids: Array.isArray(toppingsFromProduct) ? toppingsFromProduct : [],
          discount: 0,
        };
        orderFormatProducts.push(orderFormatProduct);
      }
    }
    const data = {
      orderId: order.orderId,
      placeId,
      pointId,
      userId,
      userDevice,
      products: orderFormatProducts,
    };

    const reducers = { orderDispatch: dispatch };
    sendPendingOrder(data, reducers, WSConnection, true);
  };

  let waitingMessage = null;
  let waitingColor = null;
  let orderCostbackground = null;

  switch (order.status) {
    case 'sending':
      waitingMessage = t('orders.MESSAGES.sending');
      waitingColor = 'txt-primary-warning';
      orderCostbackground = 'bg-warning';
      break;
    case 'pending':
      waitingMessage = t('orders.MESSAGES.pending');
      waitingColor = 'txt-primary-warning';
      orderCostbackground = 'bg-warning';
      break;
    case 'notsent':
      waitingMessage = t('orders.MESSAGES.notsent');
      waitingColor = 'txt-primary-danger';
      orderCostbackground = 'bg-danger';
      break;
    case 'approved':
      waitingMessage = t('orders.MESSAGES.approved');
      waitingColor = 'txt-primary-success';
      orderCostbackground = 'bg-success';
      break;
    case 'approved-delivery':
      waitingMessage = t('orders.MESSAGES.approved_delivery');
      waitingColor = 'txt-primary-success';
      orderCostbackground = 'bg-success';
      break;
    case 'rejected':
      waitingMessage = t('orders.MESSAGES.rejected');
      waitingColor = 'txt-primary-danger';
      orderCostbackground = 'bg-danger';
      break;
    case 'active':
      break;

    default:
      console.log('DEFAULT REACHED IN ORDER STATUS', order.status);
      break;
  }

  const needsRetry = order.status === 'notsent';

  const hasApproved = order.status === 'approved' || order.status === 'approved-delivery';

  const pendingOrderDelimiter = (
    <div className="f-c">
      <div className="f-c f-dirc">
        <p className="f2 txt-app-dim t-UC mb-4">{`${t('interface.basic.order')} #${
          order.orderCode
        } - ${date}`}</p>
        <div className="f-c f-aic bg-overlay-3 p-4 br-4">
          <WatchLaterIcon className={`f10 pr-4 ${waitingColor}`} style={{ marginTop: '-1px' }} />
          <p className="f2 f08">{waitingMessage}</p>
        </div>
      </div>
      <div className={`f-c f-jcc f-aic p-4 ml-8 br-4 ${orderCostbackground}`}>
        <p className="f2b f07 txt-white pl-4 pr-4">{`${order.cost} ${order.currency || 'lei'}`}</p>
      </div>
    </div>
  );
  const pendingOrderDelimiterCancelConfirm = (
    <div className="f-c f-dirc">
      <span className={`f2b mb-4 ${waitingColor}`}>{t('interface.orders.confirm_cancel')}</span>
      <div className="f-c f-aic">
        <WatchLaterIcon className="f10 pr-4 txt-primary-warning" style={{ marginTop: '-1px' }} />
        <p className="f2 f08">{t('interface.orders.awaiting_approval')}</p>
      </div>
    </div>
  );

  const delimiter = (
    <div className={`m0 pt-8 pb-8 ${waitingColor}`}>
      {active ? (
        <span className="txt-app p-4 bg-info br-4">{t('interface.orders.active_order')}</span>
      ) : cancelState && !hasApproved ? (
        pendingOrderDelimiterCancelConfirm
      ) : (
        pendingOrderDelimiter
      )}
    </div>
  );
  const cancel =
    !hasApproved && order.status !== 'rejected' ? (
      <span className={`f1b f08 mr-8 ${waitingColor}`} onClick={handleCancelOrder}>
        {t('interface.orders.cancel')}
      </span>
    ) : null;

  const retry = (
    <span className="txt-primary-danger f2b f08 mr-8" onClick={handleRetryOrder}>
      {t('interface.orders.retry')}
    </span>
  );

  const cancelYesNo =
    !hasApproved && !loading ? (
      <div className="mr-8">
        <span className={`f2b f09 pr-32 ${waitingColor}`} onClick={keepOrder}>
          {t('interface.basic.no')}
        </span>
        <span className={`f2b f09 ${waitingColor}`} onClick={cancelOrder}>
          {t('interface.basic.yes')}
        </span>
      </div>
    ) : (
      <div className="mr-8">
        <span className="f2b f05 txt-primary-danger">{t('interface.orders.pending_cancel')}</span>
      </div>
    );

  const header = (isExpanded) => (
    <div className="f-c f-jcsb f-aic bb-app" onClick={toggleExpand}>
      <div className="f-c f-aic">
        {!isExpanded ? (
          <ArrowRightIcon className="f18 txt-app pr-4" />
        ) : (
          <ArrowDropDownIcon className="f18 txt-app pr-4" />
        )}
        {delimiter}
      </div>
      {active ? (
        <span className="mr-8">{`${order.cost} lei`}</span>
      ) : needsRetry ? (
        retry
      ) : cancelState ? (
        cancelYesNo
      ) : (
        cancel
      )}
    </div>
  );

  const headerComponent = header(expand);

  return (
    <div className="f-c f-dirc ml-8 mr-8">
      {!expand ? (
        <>{headerComponent}</>
      ) : (
        <>
          {headerComponent}
          <div className="ml-8 mr-8 p-8">
            {order.products.map((product) => {
              if (active)
                return (
                  <OrderProduct
                    key={product.id}
                    product={product}
                    action={productClick}
                    index={index}
                    active={active}
                  />
                );

              return (
                <OrderConfirmProduct
                  key={product.id}
                  product={product}
                  active={active}
                  index={index}
                  disabled={!hasApproved}
                />
              );
            })}
          </div>
        </>
      )}
      {lastConfirmedOrder && <div style={{ height: '30px' }} />}
    </div>
  );
};

export default OrderList;
