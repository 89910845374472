import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CallActions } from './components/CallActions';
import { CallProductLister } from './components/CallProductLister';
import { MenuButtonOk, MenuButtonYes } from '../../lib/reusable/MenuButton';
import { NotificationStoreContext } from '../notifications/contexts/NotificationStoreContext';
import { CallStoreContext } from './contexts/CallStoreContext';
import { callRequestCancel } from './api/callRequestCancel';
import { callRequestCompleted } from './api/callRequestCompleted';
import { callCancelRequestReceivedByPlace } from './api/callCancelRequestReceivedByPlace';

import { PlaceContext } from '../../core/contexts/PlaceContext';
import { WSContext } from '../../core/websockets/WSContext';

const CallModalInactive = () => {
  const {
    data: {
      place: { features },
    },
  } = useContext(PlaceContext);
  const { t } = useTranslation();

  if (!features) {
    return null;
  }

  const hasFastOrdering =
    (Boolean(features?.sponsor?.active) &&
      Object.keys(features?.sponsor?.calls?.products || {})?.length > 0) ||
    Object.keys(features?.calls?.products || {})?.length > 0;

  return (
    <div className="p-rel WH100v oflowh txt-calls">
      <div className="f-c f-dirc H100" style={{ maxWidth: 600, margin: '0 auto' }}>
        <CallActions />
        {false ? (
          <div className="f-1">
            <div className="p-rel f-c f-jcc f-aic oflowh W100 bg-app-call-product">
              <p className={`f1 f08 ta-c lh08 txt-app-call-product-name`}>
                {t('calls.product_call_message')}
              </p>
            </div>
            <CallProductLister />
          </div>
        ) : (
          <div className="f-1" />
        )}
      </div>
    </div>
  );
};

const CallModalPending = () => {
  const {
    data: {
      place: { placeId, pointId },
      userId,
      userDevice,
    },
  } = useContext(PlaceContext);

  const [, notificationDispatch] = useContext(NotificationStoreContext);
  const [callState, callDispatch] = useContext(CallStoreContext);
  const { WSConnection } = useContext(WSContext);

  const { t } = useTranslation();

  const reducers = { callState, callDispatch, notificationDispatch };

  const cancelCheckCall = async () => {
    window.__TIMEOUTS__.call !== undefined && clearTimeout(window.__TIMEOUTS__.call);
    const data = { placeId, userId, pointId, userDevice, details: {} };
    await callRequestCancel(data, reducers, WSConnection);
    await callCancelRequestReceivedByPlace(data, reducers);
  };

  return (
    <div className="p-rel maxH100v oflowh txt-calls-cancel bg-calls-overlay">
      <div className="f-c f-dirc f-jcc WH100v">
        <div className="p-32" style={{ marginTop: '-100%' }}>
          <h3 className="f2b f08 p-4 txt-calls-cancel fds-2 ta-c">{t('calls.CANCEL')}</h3>
          <div className="f-c f-jcsb mc pt-5 pb-5">
            <div className="f-1 pt-8 ml-8">
              <MenuButtonYes action={cancelCheckCall} name={t('interface.basic.yes')} fw />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CallModalConfirmed = () => {
  const {
    data: {
      place: { placeId, pointId },
      userId,
    },
  } = useContext(PlaceContext);
  const [, notificationDispatch] = useContext(NotificationStoreContext);
  const [callState, callDispatch] = useContext(CallStoreContext);

  const { t } = useTranslation();

  const reducers = { callState, callDispatch, notificationDispatch };

  const completeCall = async () => {
    const data = { placeId, userId, pointId };
    await callRequestCompleted(data, reducers);
  };

  return (
    <div className="p-rel maxH100v oflowh txt-calls bg-calls-overlay" onClick={completeCall}>
      <div className="f-c f-dirc f-jcc WH100v">
        <div className="p-32" style={{ marginTop: '-100%' }}>
          <h3 className="f2b f08 p-4 txt-calls-cancel fds-2 ta-c">{t('calls.OK')}</h3>
          <div className="f-c f-jcsb mc pt-5 pb-5">
            <div className="f-1 pt-8">
              <MenuButtonOk action={completeCall} name="OK" fw />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CallModalSwitcher = () => {
  const [callState] = useContext(CallStoreContext);
  const status = callState?.call?.status;

  if (status === 'pending') {
    return <CallModalPending />;
  }
  if (status === 'confirmed') {
    return <CallModalConfirmed />;
  }

  return <CallModalInactive />;
};

const Calls = () => {
  return (
    <div className="f-c f-dirc f-aic H100v pt-16">
      <h3 className="f2b f10 ta-l pl-32 pb-16 ml-4 W100">CALLS</h3>
      <CallModalSwitcher />
    </div>
  );
};

export default Calls;
