const URI = {
  menu: process.env.REACT_APP_GET_MENU,
  sync: process.env.REACT_APP_SYNC,
  exchange: process.env.REACT_APP_EXCHANGE,
  socket: process.env.REACT_APP_WS,
  bucket: process.env.REACT_APP_BUCKET,
  domain: process.env.REACT_APP_DOMAIN,
};

export { URI };
