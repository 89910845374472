import React from 'react';

const Unitpay = ({ message }) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      }}
    >
      {/* <iframe width="100%" height="100%" frameBorder="0" src="https://unitpay.ro" title="Unitpay" /> */}
      <div className="bg-white f-c f-dirc f-jcc f-aic WH100">
        <div className="f-c f-dirc f-jcc f-aic f1 f11" style={{ widht: 400, height: 400 }}>
          <p>No location selected or session expired</p>
          <p>Please scan a QR code</p>
          <a className="txt-info" href="https://unitpay.ro">
            <b>unitpay.ro</b>
          </a>
        </div>
      </div>
    </div>
  );
};

export { Unitpay };
