const setSearching = (state, payload) => {
  const newState = { ...state, searching: payload.searching };
  return newState;
};

const SearchReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SEARCHING':
      return setSearching(state, action.payload);

    default:
      return state;
  }
};

export default SearchReducer;
