import React from 'react';

const LongRoundButton = (props) => {
  const { action, name, danger } = props;
  const bgClasses = `p-rel f-c f-jcc f-aic p-4 W100 fds-1 t-UC pe-all no-select cursor-default ${
    !danger ? 'bg-buttons' : 'bg-danger'
  }`;
  const txtClasses = `f2b f10 p-8 ${!danger ? 'txt-buttons' : 'txt-white'}`;

  return (
    <button className={bgClasses} onClick={action} style={{ borderRadius: '40px' }}>
      <h3 className={txtClasses}>{name}</h3>
    </button>
  );
};

export { LongRoundButton };
