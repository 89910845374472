import React from 'react';
import Header from '../interface/components/Header';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';

const TermsAndConditions = () => {
  const history = useHistory();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  if (window.scrollY !== 0) {
    scrollToTop();
  }

  const closePage = () => {
    history.push('/');
  };

  return (
    <div className="bg-app">
      <Header />
      <div className="p-fix p-top-right pc-lr mt-32 l5">
        <CloseIcon
          size={24}
          className="br-f mt-8 p-8"
          onClick={closePage}
          style={{ marginRight: -8 }}
        />
      </div>
      <div className="f2 f09 minH100v pc-lr-2x" style={{ paddingTop: 84 }}>
        <h3>TERMENI DE UTILIZARE</h3>
        <p>Definitii si Termeni</p>
        <p>
          UNITPAY&nbsp;- este compania care ofera serviciile de intermediere intre VANZATOR si
          CLIENT
        </p>
        <p>
          Vanzator&nbsp;&ndash; prestatorul de servicii in care se desfasoara activitatile economice
        </p>
        <p>
          Cumparator&nbsp;&ndash; poate fi orice persoana fizica care are varsta peste 16 ani sau
          persoana juridica sau orice entitate juridica care acceseaza site-ul si efectueaza o
          Comanda.
        </p>
        <p>
          Client&nbsp;&ndash; poate fi orice persoana fizica care are varsta peste 16 ani sau
          persoana juridica care are sau obtine acces la CONTINUT, prin utilizarea mijlocului de
          comunicare pus la dispozitie de catre UNITPAY
        </p>
        <p>
          Utilizator&nbsp;&ndash; orice persoana fizica care are varsta peste 16 ani sau persoana
          juridica inregistrata pe Site, care, prin finalizarea procesului de creare a Contului,
          si-a dat acordul cu privire la clauzele specifice site-ului din sectiunea Termeni si
          Conditii Generale.
        </p>
        <p>
          Site&nbsp;&ndash; interfata online prin care clientul acceseaza serviciile oferite de
          UNITPAY
          <br />
          Comanda&nbsp;&ndash; un document electronic ce intervine ca forma de comunicare intre
          Vanzator si Cumparator prin care Cumparatorul transmite Vanzatorului, prin intermediul
          Site-ului intentia sa de a achizitiona Bunuri si Servicii de pe Site.
          <br />
          Bunuri si Servicii&nbsp;&ndash; orice produs sau serviciu, listat pe site, inclusiv
          Bunurile mentionate in Comanda, care urmeaza a fi furnizate de catre Vanzator,
          Cumparatorului ca urmare a comenzii trimise.
          <br />
          Campanie&nbsp;&ndash; actiunea de a expune in scop comercial, un numar finit de Bunuri
          si/sau Servicii avand un stoc limitat si predefinit, pentru o perioada limitata de timp
          stabilita de catre Vanzator.
          <br />
          Contract&nbsp;&ndash; reprezinta contractul la distanta incheiat intre Vanzator si
          Cumparator, fara prezenta fizica simultana a Vanzatorului si a Cumparatorului.
          <br />
          Continut
        </p>
        <p>
          &bull; toate informatiile de pe Site care pot fi vizitate, vizualizate sau altfel accesate
          prin utilizarea unui echipament electronic;
          <br />
          &bull; orice informatie comunicata prin orice mijloc de catre un angajat/colaborator al
          Vanzatorului, Cumparatorului, conform informatiilor de contactare, specificate sau nu de
          catre acesta;
          <br />
          &bull; informatii legate de Bunurile si/sau tarifele practicate de Vanzator intr-o anumita
          perioada;
          <br />
          &bull; informatii legate de Bunurile si/sau Serviciile si/sau tarifele practicate de catre
          un tert cu care Vanzatorul are incheiate contracte de parteneriat, intr-o anumita
          perioada;
          <br />
          &bull; date referitoare la Vanzator, sau alte date privilegiate ale acestuia.
        </p>
        <p>
          COMANDA&nbsp;&ndash; sectiune din Cont care permite Cumparatorului/Utilizatorului sa
          adauge Bunuri pe care doreste sa le achizitionezela momentul adaugarii; in cazul in care
          Bunurile sau Serviciile nu sunt achizitionate la momentul adaugarii prin efectuarea
          Comenzii, Cumparatorul/Utilizatorul va beneficia de serviciul oferit de catre Vanzator de
          urmarire a Bunurilor si Serviciilor prin primirea de Comunicari Comerciale din partea
          acestuia.Document &ndash; prezentele&nbsp;
        </p>
        <p>Documente&nbsp;contractuale</p>
        <p>
          2.1. Prin inregistrarea unei Comenzi pe Site, Cumparatorul este de acord cu forma de
          comunicare (online) prin care Vanzatorul isi deruleaza operatiunile comerciale.
          <br />
          2.2. Notificarea primita de catre Cumparator, dupa efectuarea Comenzii are rol de
          informare si nu reprezinta acceptarea Comenzii. Aceasta notificare se face electronic.
          <br />
          2.3. Pentru motive justificate, Vanzatorul isi rezerva dreptul de a modifica cantitatea
          Bunurilor si/sau Serviciilor din Comanda. Daca modifica cantitatea de Bunuri si/sau
          Servicii din Comanda va anunta Cumparatorul direct.
          <br />
          <br />
        </p>
        <p>Dreptul la proprietate intelectuala si industriala</p>
        <p>
          3.1. Continutul, astfel cum este definit in preambul, incluzand dar nelimitandu-se la
          logo-uri, reprezentari stilizate, simboluri comerciale, imagini statice, imagini dinamice,
          text si/sau continut multimedia prezentate pe Site, sunt proprietatea exclusiva a UNITPAY
          SYSTEMS S.R.L., acestuia fiindu-i rezervate toate drepturile obtinute in acest sens in mod
          direct sau indirect (prin licente de utilizare si/sau publicare).
          <br />
          3.2. Clientului/Cumparatorului nu ii este permisa copierea, distribuirea, publicarea,
          transferul catre terte parti, modificarea si/sau altfel alterarea, utilizarea, legarea la,
          expunerea, includerea oricarui Continut in orice alt context decat cel original
          intentionat de UNITPAY SYSTEMS S.R.L includerea oricarui Continut in afara Site-ului,
          asupra Continutului precum si participarea la transferul, vanzarea, distributia unor
          materiale realizate prin reproducerea, modificarea sau afisarea Continutului, decat cu
          acordul scris expres al UNITPAY SYSTEMS S.R.L.
          <br />
          3.3. Niciun Continut transmis catre Client sau Cumparator, prin orice mijloc de comunicare
          (electronic, telefonic, etc) sau dobandit de acesta prin accesare, vizitare si/sau
          vizualizare nu constituie o obligatie contractuala din partea UNITPAY SYSTEMS S.R.L si/sau
          al angajatului/prepusului UNITPAY SYSTEMS S.R.L care a mijlocit transferul de Continut, in
          cazul in care aceasta exista, fata de respectivul continut.
          <br />
          3.4. Este interzisa orice utilizare a Continutului in alte scopuri decat cele permise
          expres prin prezentul Document sau de acordul de utilizare care il insoteste, in cazul in
          care acesta exista.
        </p>
        <p>Comanda</p>
        <p>
          4.1. Clientul/Cumparatorul poate efectua Comenzi pe Site, prin adaugarea Bunurilor si/sau
          Serviciilor dorite in cosul de cumparaturi, urmand a finaliza Comanda efectuand plata prin
          una dintre modalitatile indicate expres. Odata adaugat in cosul de cumparaturi, un Bun
          si/sau un Serviciu este disponibil pentru achizitie in masura in care exista stoc
          disponibil pentru aceasta. Adaugarea unui Bun/Serviciu in cosul de cumparaturi, in lipsa
          finalizarii Comenzii, nu atrage dupa sine inregistrarea unei comenzi, implicit nici
          rezervarea automata a Bunului/Serviciului.
          <br />
          4.2. Prin finalizarea Comenzii Cumparatorul consimte ca toate datele furnizate de acesta,
          necesare procesului de cumparare, sunt corecte, complete si adevarate la data plasarii
          Comenzii.
          <br />
          4.3. Prin finalizarea Comenzii, Cumparatorul consimte ca Vanzatorul poate sa il
          contacteze, prin orice mijloc disponibil / agreat de Vanzator, in orice situatie in care
          este necesara contactarea Cumparatorului.
          <br />
          4.4. Vanzatorul poate anula Comanda efectuata de catre Cumparator, in urma unei notificari
          prealabile adresate Cumparatorului, fara nicio obligatie ulterioara a vreunei parti fata
          de cealalta sau fara ca vreo parte sa poata sa pretinda celeilalte daune-interese in
          urmatoarele cazuri:
          <br />
          <br />
        </p>
        <p>Confidentialitate</p>
        <p>
          5.1. UNITPAY SYSTEMS S.R.L va pastra confidentialitatea informatiilor de orice natura pe
          care le furnizati. Dezvaluirea informatiilor furnizate se va putea face doar in conditiile
          mentionate in prezentul Document.
          <br />
          5.2. Nicio declaratie publica, promovare, comunicat de presa sau orice alt mod de
          dezvaluire catre terte parti nu va fi facuta de Cumparator/Client cu privire la
          Comanda/Contract fara consimtamantul prealabil scris al Vanzatorului.
          <br />
          5.3. Prin transmiterea de informatii sau materiale prin intermediul acestui site, oferiti
          Vanzatorului acces nerestrictionat si irevocabil la acestea, dreptul de a utiliza,
          reproduce, afisa, modifica, transmite si distribui aceste materiale sau informatii.
          Sunteti, de asemenea, de acord ca Vanzatorul sa poata utiliza liber, in interes propriu,
          aceste informatii, idei, concepte, know-how-uri sau tehnici pe care ni le-ati trimis prin
          intermediul Site-ului. UNITPAY SYSTEMS S.R.L nu va constitui subiect de obligatii
          referitoare la confidentialitatea informatiilor trimise, daca legislatia in vigoare nu
          prevede alte specificari in acest sens.
        </p>
        <p>Facturare - Plata</p>
        <p>
          6.1. Preturile Bunurilor si Serviciilor afisate prin cadrul site-ului www.digital-menu.ro
          includ T.V.A. conform legislatiei in vigoare.
          <br />
          6.2. Pretul, modalitatea de plata si termenul de plata sunt specificate in fiecare
          Comanda. Vanzatorul va emite catre Cumparator o factura pentru Bunurile si Serviciile
          livrate, obligatia Cumparatorului fiind sa furnizeze toate informatiile necesare emiterii
          facturii conform cu legislatia in vigoare.
          <br />
          6.3. Pentru o corecta comunicare a facturii aferente Comenzii, Cumparatorului ii revin
          obligatiile de a-si actualiza ori de cate ori este cazul datele din Contul sau si de a
          accesa informatiile si documentele aferente fiecarei Comenzi, existente in Cont.
          <br />
          <br />
        </p>
        <p>Livrarea bunurilor</p>
        <p>
          11.1. Vanzatorul se obliga sa livreze Bunurile in sistem de servire catre Cumparator.
          <br />
          <br />
        </p>
        <p>Prelucrarea datelor cu caracter personal</p>
        <p>
          Te rog sa parcurgi&nbsp;Politica de Confidențialitate cu privire la prelucrarea datelor cu
          caracter personal, care face parte din prezentul Document.
        </p>
        <p>Fololsirea Cookie-Urilor</p>
        <p>Te rog sa parcurgi&nbsp;Politica de Cookies, care face parte din prezentul Document.</p>
        <p>Legea aplicabila &ndash; Jurisdictia</p>
        <p>Prezentul Contract este supus legii romane.&nbsp;</p>
        <h4>UNITPAY</h4>
      </div>
      <div style={{ height: 256 }} />
    </div>
  );
};

export default TermsAndConditions;
