import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import disableScroll from 'disable-scroll';

ReactModal.setAppElement('#root');

const ReactModalWrapper = (props) => {
  const { modalIsOpen, closeModal, scrollable } = props;

  useEffect(() => {
    if (scrollable) return;
    disableScroll.on();
    return () => {
      disableScroll.off();
    };
  }, []);

  const customStyles = {
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 11,
    },
    content: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 11,
    },
  };

  if (!modalIsOpen) return null;

  return (
    <ReactModal
      className="WH100"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldFocusAfterRender={true}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
      preventScroll={true}
    >
      {props.children}
    </ReactModal>
  );
};

export default ReactModalWrapper;
