import React, { useState } from 'react';

const initialState = {
  products: {
    // <id>: {
    //   previous: 'EXPANDED',
    //   active: 'DEFAULT'
    // },
  },
};

export const PRODUCT_STATES = {
  DEFAULT: 'DEFAULT',
  COMPACT: 'COMPACT',
  EXPANDED: 'EXPANDED',
};

export const UIMemoryContext = React.createContext();
export const UIMemoryProvider = ({ children }) => {
  const [data, setData] = useState(initialState);

  const changeProductState = (productId, state) => {
    const newProductState = { ...data.products };
    newProductState[productId] = state;
    setData({ products: newProductState });
  };

  return (
    <UIMemoryContext.Provider value={[data, changeProductState]}>
      {children}
    </UIMemoryContext.Provider>
  );
};
