import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SubCategoryTags } from './SubCategoryTags';
import ProductsList from './ProductsList';
import Loading from '../../basic-loading/Loading';
import EventsPlacer from '../../visuals/EventsPlacer';

import BreadCrumbTrail from '../../bread-crumb-trail/BreadCrumbTrail';
import PhoneCalls from '../../phone-calls/PhoneCalls';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

const Category = ({ categoryId, page, noCalls }) => {
  const history = useHistory();
  const [isOnHomeScreen, setIsOnHomeScreen] = useState(window.location.pathname === '/');
  const {
    data: {
      place: { features, currency = 'RON' },
      cache: {
        events,
        categories: { categoryId__products, categoryId__category, categoryId__extraProducts },
      },
    },
    loading: isLoading,
  } = useContext(PlaceContext);

  if (!(categoryId__products && categoryId__products[categoryId])) {
    return null;
  }

  let products = categoryId__products[categoryId];
  const isNestedCategory = Boolean(categoryId__category[categoryId]?.nested);
  const parentCategoryId = isNestedCategory ? categoryId__category[categoryId].nested : null;
  const nestedCategoryHasExtraProducts =
    Array.isArray(categoryId__extraProducts[categoryId]) &&
    categoryId__extraProducts[categoryId].length > 0;

  if (isNestedCategory && nestedCategoryHasExtraProducts) {
    products = [...products, ...categoryId__extraProducts[categoryId]];
  }

  if (products === undefined) {
    return null;
  }

  let delimiters = [];
  for (const product of products) {
    if (!product) continue;
    if (product.delimiter && !product.main) {
      delimiters.push(product);
    }
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const productClick = ({ productId }) => {
    const uri = `/product/${productId}`;
    history.push(uri);
  };

  const categoryClick = ({ categoryId }) => {
    const uri = `/category/${categoryId}`;
    scrollToTop();
    history.push(uri);
  };

  const renderAsPage = !(page !== undefined && page === false);
  const wrapperClasses = renderAsPage ? 'pb-16 minH100v WH100' : '';

  const hasCategoriesEvents = Object.values(events?.categories).length > 0;

  const informativeCurrency = features?.place?.otherCurrencies?.informativeCurrency;

  if (parentCategoryId && delimiters.length === 0) {
    const productsOfParentCategory = categoryId__products[parentCategoryId];
    if (productsOfParentCategory) {
      for (const product of productsOfParentCategory) {
        if (!product) continue;
        if (product.delimiter && !product.main) {
          delimiters.push(product);
        }
      }
    }
  }

  if (delimiters.length === 1) {
    delimiters = [];
  }

  return (
    <>
      {renderAsPage && isLoading && <Loading />}
      {!noCalls && <PhoneCalls serviceId={categoryId__category?.[categoryId]?.service_id} />}
      <div className={wrapperClasses}>
        {hasCategoriesEvents && (
          <>
            <div style={{ height: 58 }} />
            <div style={{ maxWidth: 600, marginBottom: -32 }}>
              <EventsPlacer menuPosition="categories" enforce="top" />
            </div>
          </>
        )}
        {renderAsPage && (
          <div
            className="p-fix p-top m0 pl-4 bg-app-nav fds-3 l3"
            style={{ backdropFilter: 'blur(2px)' }}
          >
            <BreadCrumbTrail />
          </div>
        )}
        <div className="pt-8" style={{ height: 32 }} />
        {delimiters.length !== 0 && (
          <>
            <div
              className="p-fix l3 bg-app-dim bg-blur"
              style={{
                top: isOnHomeScreen ? 50 : 40,
                marginTop: -8,
                marginLeft: -8,
                width: 'calc(100vw + 15px)',
              }}
            >
              <SubCategoryTags
                delimiters={delimiters}
                action={categoryClick}
                setIsOnHomeScreen={setIsOnHomeScreen}
              />
            </div>
            <div className="mt-64 pt-16" />
          </>
        )}
        {informativeCurrency && (
          <>
            <div className="pt-8" style={{ height: 8 }} />
            <span
              style={{ borderRadius: '0 4px 4px 0' }}
              className="f07 f1b p-8 bg-info txt-white"
            >{`* Prices in ${informativeCurrency} are informative only, you can only pay with ${currency}`}</span>
            <div className="pt-8" style={{ height: 8 }} />
          </>
        )}
        <ProductsList products={products} productClick={productClick} categoryId={categoryId} />
        {hasCategoriesEvents && (
          <div style={{ maxWidth: 600 }}>
            <EventsPlacer menuPosition="categories" enforce="bottom" />
          </div>
        )}
        {renderAsPage && <div className="minH100" />}
      </div>
    </>
  );
};

export default Category;
