import React, { useContext } from 'react';

import ToppingsModal from '../../toppings/components/ToppingsModal';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

const ToppingsAvailable = (props) => {
  const { product, toppingsModalIsOpen, setToppingsModalIsOpen } = props;
  const {
    data: {
      cache: {
        toppings: { productId__toppingsGroupId },
      },
    },
  } = useContext(PlaceContext);

  const productHasToppings =
    product && product.id && productId__toppingsGroupId[product.id] !== undefined;

  if (!productHasToppings) {
    return null;
  }

  const openToppingsModal = () => {
    setToppingsModalIsOpen(true);
  };

  const closeToppingsModal = () => {
    setToppingsModalIsOpen(false);
  };

  return (
    <>
      <div className="p-abs p-bottom-right l2">
        <button
          className="pe-all p-8 f07 no-outline no-border bg-toppings-button-view txt-toppings-button-view no-select cursor-default"
          style={{ borderRadius: '2px 0 0 0' }}
          onClick={openToppingsModal}
        >
          <p className="f2 f07">VIEW EXTRAS</p>
        </button>
      </div>
      <ToppingsModal
        isVisible={toppingsModalIsOpen}
        closeModal={closeToppingsModal}
        productId={product.id}
      />
    </>
  );
};

export default ToppingsAvailable;
