import React, { useEffect } from 'react';
import Cookies from '../../features/cookies/Cookies';
import { scrollToTop } from '../../core/functions/utilities';

const CookiesPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return <Cookies />;
};

export default CookiesPage;
