import React, { useEffect } from 'react';
import { scrollToTop } from '../../core/functions/utilities';
import Nutrition from '../../features/nutrition/Nutrition';

const NutritionPage = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return <Nutrition />;
};

export default NutritionPage;
