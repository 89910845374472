import React, { useState, useEffect, useContext } from 'react';

import PlaceHolder from './PlaceHolder';
import { PlaceContext } from '../../core/contexts/PlaceContext';

const Moviez = (props) => {
  const {
    movie,
    rounded,
    dropShadow,
    text,
    subText,
    handleNoMovie,
    top,
    limitHeight,
    limitWidth,
    fullscreen,
    maxvh,
  } = props;

  const {
    data: {
      place: { features },
    },
  } = useContext(PlaceContext);

  const [isLoading, setIsLoading] = useState(true);
  const [errorMovie, setErrorMovie] = useState(false);

  useEffect(() => {
    const handleOnFocus = () => {
      try {
        const videoPlayer = document.getElementById('up-video-player');
        if (document.hidden) {
          videoPlayer.pause();
        } else {
          videoPlayer.play();
        }
      } catch (error) {
        console.error('ERROR ON VIDEO PLAYER', error);
      }
    };

    document.addEventListener('visibilitychange', handleOnFocus, true);
    return () => {
      document.removeEventListener('visibilitychange', handleOnFocus, true);
    };
  }, []);

  const loadingDone = () => {
    setIsLoading(false);
  };

  const onError = () => {
    setErrorMovie(true);
    handleNoMovie && handleNoMovie();
  };

  const limitedConfigHeight = features?.theming?.['max-image-height'];
  const sameImageHeightConfig = features?.theming?.['same-image-height'];
  const hasLimitedConfigHeight = !sameImageHeightConfig && Boolean(limitedConfigHeight);

  let wrapperClasses = 'p-rel W100 oflowh';
  rounded && (wrapperClasses += ' br-2');
  (limitHeight || top || sameImageHeightConfig) && (wrapperClasses += ' H160');
  fullscreen && (wrapperClasses += ' W600 mc');

  let wrapperClassesText = 'f1b f10 lh08 ib-c mb-8 no-select W100 wb-break pr-16 pl-16';
  dropShadow && (wrapperClassesText += ' fds-2');

  const maxHeight = hasLimitedConfigHeight ? limitedConfigHeight : maxvh ? maxvh : '80vh';

  return (
    <div className={wrapperClasses}>
      {movie && !errorMovie ? (
        <div className="l2">
          <PlaceHolder isLoading={isLoading} rounded={rounded && !top} />
        </div>
      ) : null}

      {!isLoading && text && (
        <div className="p-abs f-c f-dirc f-1 W100 l2 pt-8 pb-4" style={{ left: -1, bottom: -1 }}>
          <h1 className={wrapperClassesText}>{text}</h1>
          {subText && <h1 className={wrapperClassesText}>{subText}</h1>}
        </div>
      )}

      {movie && !errorMovie ? (
        <>
          {limitHeight ? (
            <div
              className={`f-c f-jcsb of-cover p-rel WH100 ${rounded ? 'br-2' : ''}`}
              style={{ maxHeight }}
            >
              <div className="video-container">
                <video
                  id="up-video-player"
                  playsInline
                  className="video-centered"
                  width={!limitWidth ? '100%' : limitWidth}
                  autoPlay
                  loop
                  muted
                  onLoadedData={loadingDone}
                  onError={onError}
                  style={{ margin: '0 auto', objectFit: 'cover' }}
                >
                  <source src={movie} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          ) : (
            <div
              className={`f-c f-jcsb of-cover WH100 ${rounded ? 'br-2' : ''}`}
              style={{ maxHeight }}
            >
              <video
                id="up-video-player"
                playsInline
                width={!limitWidth ? '100%' : limitWidth}
                autoPlay
                loop
                muted
                onLoadedData={loadingDone}
                onError={onError}
                style={{ margin: '0 auto', objectFit: 'cover' }}
              >
                <source src={movie} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </>
      ) : null}

      {text && !isLoading ? (
        <div
          className={`p-abs l1 ${rounded ? 'br-2' : ''}`}
          style={{
            background: `linear-gradient(#00000000, #000000${top ? 'A0' : '80'})`,
            top: top ? '25%' : 0,
            left: 0,
            right: 0,
            bottom: -1,
          }}
        />
      ) : null}
    </div>
  );
};

export default Moviez;
